import React from "react";
import PropTypes from "prop-types";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DesktopDatePicker, DateTimePicker, TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import "./index.css";

const DateTimePickerInput = ({
  inputType,
  className,
  labelText,
  dateValue,
  placeholder,
  handleChange,
  adornmentPosition,
}) => {
  const datePickerProps = {
    className: className,
    value: dateValue,
    onChange: handleChange,
    renderInput: (props) => (
      <TextField
        className={className}
        {...props}
        inputProps={{ ...props.inputProps, placeholder }}
      />
    ),
    InputAdornmentProps: { position: adornmentPosition || "start" },
  };

  const DatePickerComponent = () => {
    if (inputType === "Date") {
      return <DesktopDatePicker {...datePickerProps} />;
    } else if (inputType === "Time") {
      return <TimePicker {...datePickerProps} />;
    } else {
      return <DateTimePicker {...datePickerProps} />;
    }
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <div className="div__dateRangePicker-container">
        {labelText && <p className="lblInput">{labelText}</p>}
        <DatePickerComponent />
      </div>
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;

DateTimePickerInput.propTypes = {
  inputType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  dateValue: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  adornmentPosition: PropTypes.string,
};

DateTimePickerInput.defaultProps = {
  inputType: "Date",
  className: "",
  labelText: "",
  placeholder: "",
  dateValue: new Date(),
  handleChange: null,
  adornmentPosition: "",
};
