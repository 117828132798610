import React from "react";
import { Button } from "@mui/material";
import "./index.css";

const ChoiceQuestion = ({
  questionText,
  optionsList,
  selectedOption,
  onSelectOption,
}) => {
  return (
    <div className="choiceQuestion__container">
      <p className="choiceQuestion__question">{questionText}</p>
      <div className="choiceQuestion__optionsList">
        {optionsList?.map((value) => (
          <Button
            disableFocusRipple
            disableRipple
            className={`choiceQuestion__option ${
              selectedOption === value ? "choiceQuestion__optionSelected" : ""
            }`}
            onClick={() => onSelectOption(value)}
          >
            {value}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ChoiceQuestion;
