export const SIZE_CHART_INCH_DATA = [
  {
    sizeSymbol: "S",
    size_inch: 38,
    chest_inch: 38,
    frontLength_inch: 27.5,
    acrossShoulder_inch: 16.8,
    sleeveLength_inch: 7.25,
  },
  {
    sizeSymbol: "M",
    size_inch: 39,
    chest_inch: 40,
    frontLength_inch: 28,
    acrossShoulder_inch: 17.5,
    sleeveLength_inch: 7.5,
  },
  {
    sizeSymbol: "L",
    size_inch: 40,
    chest_inch: 42,
    frontLength_inch: 28.5,
    acrossShoulder_inch: 18.3,
    sleeveLength_inch: 7.75,
  },
  {
    sizeSymbol: "XL",
    size_inch: 41,
    chest_inch: 44.5,
    frontLength_inch: 29,
    acrossShoulder_inch: 19,
    sleeveLength_inch: 8,
  },
  {
    sizeSymbol: "XXL",
    size_inch: 42,
    chest_inch: 47.5,
    frontLength_inch: 29.5,
    acrossShoulder_inch: 20,
    sleeveLength_inch: 8.25,
  },
];

export const SIZE_CHART_CM_DATA = [
  {
    sizeSymbol: "S",
    size_cm: 38,
    chest_cm: 96.3,
    frontLength_cm: 69.9,
    acrossShoulder_cm: 42.7,
    sleeveLength_cm: 18.4,
  },
  {
    sizeSymbol: "M",
    size_cm: 39,
    chest_cm: 101.6,
    frontLength_cm: 71.1,
    acrossShoulder_cm: 44.5,
    sleeveLength_cm: 19.1,
  },
  {
    sizeSymbol: "L",
    size_cm: 40,
    chest_cm: 106.7,
    frontLength_cm: 72.4,
    acrossShoulder_cm: 46.5,
    sleeveLength_cm: 19.7,
  },
  {
    sizeSymbol: "XL",
    size_cm: 41,
    chest_cm: 113.0,
    frontLength_cm: 73.7,
    acrossShoulder_cm: 48.3,
    sleeveLength_cm: 20.3,
  },
  {
    sizeSymbol: "XXL",
    size_cm: 42,
    chest_cm: 120.7,
    frontLength_cm: 74.9,
    acrossShoulder_cm: 50.8,
    sleeveLength_cm: 21.0,
  },
];
