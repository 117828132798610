import React, { useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  CustomDrawer,
  Table,
  Loading,
  Snackbar,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { useSnackbar } from "hooks";
import { InventoryServices } from "services";
import { ProductDetailPage, AddInventoryPage } from "pages";
import "./index.css";

const ProductInventory = ({ inventoryItems, selectedProduct }) => {
  const snackbar = useSnackbar();
  const [inventoryList, setInventoryList] = useState(inventoryItems);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState();
  const [loading, setLoading] = useState();

  const inventoryConfig = getTableDisplayConfig("Inventory", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() =>
            setSelectedInventoryItem({ item: rowVal, mode: "View" })
          }
        />
        <ActionIcon
          type="edit"
          iconClassName="listIcon"
          onClick={() =>
            setSelectedInventoryItem({ item: rowVal, mode: "Edit" })
          }
        />
        <ActionIcon
          type="delete"
          iconClassName="listIcon"
          onClick={async () => {
            try {
              setLoading(true);
              const msg = await InventoryServices.deleteProductInventory(
                rowVal?._id
              );
              setLoading(false);
              setInventoryList((prevList) =>
                prevList.filter((invItem) => invItem?._id !== rowVal?._id)
              );
              snackbar.showMessage({
                content: msg,
              });
            } catch (err) {
              setLoading(false);
              snackbar.showMessage({
                content: err,
              });
            }
          }}
        />
      </>
    ),
  });

  const updateInventoryList = (modifiedInventoryItem) => {
    setSelectedInventoryItem(undefined);
    setInventoryList(
      inventoryList.map((invItem) =>
        invItem?._id === modifiedInventoryItem?._id
          ? modifiedInventoryItem
          : invItem
      )
    );
  }

  const { item, mode } = selectedInventoryItem || {};

  return (
    <div className="product__inventoryList">
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <p className="product__inventoryList-header">Inventory</p>
      <Table config={inventoryConfig} dataList={inventoryList} />
      <CustomDrawer
        direction="bottom"
        className="productDetailDrawer"
        isOpen={!_.isUndefined(selectedInventoryItem)}
        onCloseDrawer={() => setSelectedInventoryItem(undefined)}
      >
        {mode === "View" && (
          <ProductDetailPage
            isPreview={true}
            previewProduct={selectedProduct}
            previewInventory={[item]}
            onCloseDrawer={() => setSelectedInventoryItem(undefined)}
          />
        )}
        {mode === "Edit" && (
          <AddInventoryPage
            inventoryItem={item}
            onCloseDrawer={updateInventoryList}
          />
        )}
      </CustomDrawer>
    </div>
  );
};

export default ProductInventory;
