import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  GridDisplay,
  IconDisplay,
  Loading,
  Snackbar,
  CustomDrawer,
  ConfirmationPopup,
  TextField,
  Button,
} from "components";
import { useSnackbar } from "hooks";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import { MemeSuggestionServices } from "services";
import { generateQueryParamsFromObject } from "utils";
import { AddMemeSuggestionForm } from "../index";
import MemeSuggestionDetail from "./MemeSuggestionDetail";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const MyMemeSuggestionsPage = () => {
  const { currentUser } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [memeSuggestions, setMemeSuggestions] = useState({
    list: [],
    loading: false,
  });
  const [memeSuggestionPopup, setMemeSuggestionPopup] = useState({
    selectedMemeSuggestionId: ``,
    isPopupOpen: false,
  });
  const [memeSuggestionDrawer, setMemeSuggestionDrawer] = useState({
    isDrawerOpen: false,
    content: null,
    contentClass: ``,
  });
  const snackbar = useSnackbar();

  useEffect(() => {
    delayedFetchMemeSuggestionsList();
  }, [searchTerm]);

  const openMemeSuggestionDrawer = (contentType, record) =>
    setMemeSuggestionDrawer({
      isDrawerOpen: true,
      content:
        contentType === "ADD" ? (
          <AddMemeSuggestionForm
            onCloseDrawer={() => {
              setMemeSuggestionDrawer({ isDrawerOpen: false });
              fetchMemeSuggestionsList();
            }}
          />
        ) : (
          <MemeSuggestionDetail
            onCloseDrawer={() =>
              setMemeSuggestionDrawer({ isDrawerOpen: false })
            }
            onWithdrawMeme={() =>
              setMemeSuggestionPopup({
                isPopupOpen: true,
                selectedMemeSuggestionId: record?._id,
              })
            }
            memeSuggestionDetail={record}
          />
        ),
      contentClass:
        contentType === "ADD"
          ? `myMemeSuggestions-memeSuggestionDrawer--add`
          : `myMemeSuggestions-memeSuggestionDrawer--view`,
    });

  const fetchMemeSuggestionsList = async () => {
    try {
      setMemeSuggestions({
        ...memeSuggestions,
        loading: true,
      });
      const query = generateQueryParamsFromObject({
        customerId: currentUser?._id,
        searchTerm,
      });
      const { memeSuggestionsList } =
        await MemeSuggestionServices.getMemeSuggestionsList(query);
      setMemeSuggestions({
        list: memeSuggestionsList,
        loading: false,
      });
    } catch (err) {
      console.error(`Error while fetching suggestions`, err);
      setMemeSuggestions({
        ...memeSuggestions,
        loading: false,
      });
    }
  };

  const myMemeSuggestionsConfig = getGridDisplayConfig("MemeSuggestion", {
    onViewDetail: (record) => openMemeSuggestionDrawer("VIEW", record),
    onWithdrawMeme: (record) =>
      setMemeSuggestionPopup({
        isPopupOpen: true,
        selectedMemeSuggestionId: record?._id,
      }),
    onEmptyListButtonClick: () => openMemeSuggestionDrawer("ADD"),
  });

  const handleWithdrawMeme = async (deleteId) => {
    try {
      setMemeSuggestions({
        ...memeSuggestions,
        loading: true,
      });
      const { msg } = await MemeSuggestionServices.deleteMemeSuggestion(
        deleteId
      );
      setMemeSuggestions({
        list: memeSuggestions?.list.filter(({ _id }) => _id !== deleteId),
        loading: false,
      });
      setMemeSuggestionDrawer({ isDrawerOpen: false });
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      snackbar.showMessage({
        content: `Error occurred while deleting suggestion `,
      });
      setMemeSuggestions({
        ...memeSuggestions,
        loading: false,
      });
    }
  };

  const handleConfirmAction = async () => {
    setMemeSuggestionPopup({
      isPopupOpen: false,
    });
    await handleWithdrawMeme(memeSuggestionPopup?.selectedMemeSuggestionId);
  };

  const handleSearchTermChange = ({ target }) => {
    delayedFetchMemeSuggestionsList.cancel();
    setSearchTerm(target.value);
    delayedFetchMemeSuggestionsList();
  };

  const delayedFetchMemeSuggestionsList = _.debounce(
    fetchMemeSuggestionsList,
    500
  );

  const confirmationMessage = (
    <div className="myMemeSuggestions-popupContent">
      <p className="myMemeSuggestions-popupContent-confirmQuestion">
        Are you sure you want to withdraw your Meme?
      </p>
      <p className="myMemeSuggestions-popupContent-confirmMessage">
        Your meme is under review.
      </p>
    </div>
  );

  const { list, loading } = memeSuggestions;
  const { isDrawerOpen, content } = memeSuggestionDrawer;
  const { isPopupOpen } = memeSuggestionPopup;

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        handleOk={handleConfirmAction}
        handleClose={() =>
          setMemeSuggestionPopup({
            isPopupOpen: false,
            selectedMemeSuggestionId: ``,
          })
        }
      >
        {confirmationMessage}
      </ConfirmationPopup>
      <div className="myMemeSuggestions">
        <div className="myMemeSuggestions-container">
          <div className="myMemeSuggestions-header">
            <p className="myMemeSuggestions-headerText">
              <IconDisplay type="ticket" id="iconMemeSuggestion" /> My Meme
              Suggestions
            </p>
            <Button
              id="btnInput"
              className="memeSuggestion_detail-addMemeSuggestion"
              onClick={() => openMemeSuggestionDrawer("ADD")}
            >
              SUGGEST MEME
            </Button>
          </div>
          <TextField
            className="myMemeSuggestions-searchInput"
            type="text"
            value={searchTerm}
            placeholder="Search using title/description"
            onChange={handleSearchTermChange}
          />
          <GridDisplay
            className="myMemeSuggestions-overview"
            config={myMemeSuggestionsConfig}
            dataList={list}
          />
          <CustomDrawer
            direction="bottom"
            className={memeSuggestionDrawer?.contentClass}
            isOpen={isDrawerOpen}
            onCloseDrawer={() =>
              setMemeSuggestionDrawer({ isDrawerOpen: false })
            }
          >
            {content}
          </CustomDrawer>
        </div>
      </div>
    </>
  );
};

export default MyMemeSuggestionsPage;
