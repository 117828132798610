import { Link } from "react-router-dom";
import { Image, Button, VideoPreview } from "components";
import LanguageGraph from "assets/LanguageGraph.png";
import PlatformUSP from "assets/PlatformUSP.png";
import Designer from "assets/DesignerIllustration.png";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const AboutUs = () => {
  return (
    <div className="div__aboutUs-container">
      <div className="aboutUs_HeaderText">About Us</div>

      <VideoPreview
        videoSource={`https://www.youtube.com/embed/-W9xZxvzSQg`}
        className="div__aboutUs-introVideo"
        frameClassname="div__aboutUs-introVideoFrame"
      />

      <div className="div__aboutUs-languages">
        <div className="div__aboutUs-languages-description">
          <p className="div__aboutUs-languages-description-header">
            Promoting Regional Languages
          </p>
          <p className="div__aboutUs-languages-description-text">
            ... in a nation that is filled with diversity, that develops a
            plural society both in letter and spirit, we come forward to bring
            regional languages and fashion tech together...
          </p>
        </div>
        <Image
          imageURL={LanguageGraph}
          className="div__aboutUs-languages-languageGraph"
        />
      </div>
      <div className="div__aboutUs-USP">
        <div className="div__aboutUs-USP-description">
          <p className="div__aboutUs-USP-description-header">
            Helping Sellers Sell Better
          </p>
          <p className="div__aboutUs-USP-description-text">
            ... not only does your product but also you get recognized at Meme -
            T. Your work makes the difference. Meme - T just eases the process
            of bringing you closer to your consumers.
          </p>
        </div>
        <Image
          imageURL={PlatformUSP}
          className="div__aboutUs-USP-imgPlatformUSP"
        />
        <div className="aboutUs-USP-vendor--onboarding">
          <p className="aboutUs-USP-vendor--onboarding-text">
            Already a seller and want to make your product a top brand? Look no
            further.
          </p>
          <Link
            to={`/vendor/onboarding`}
            className="aboutUs-USP-vendor--onboarding-link"
          >
            <Button
              id="btnInput"
              className="aboutUs-USP-vendor--onboarding-button"
            >
              Start your Meme-T Onboarding now
            </Button>
          </Link>
        </div>
      </div>
      <div className="div__aboutUs-Designer">
        <div className="div__aboutUs-Designer-description">
          <p className="div__aboutUs-Designer-description-header">
            {" "}
            Consumer based platform.
          </p>
          <p className="div__aboutUs-Designer-description-text">
            ... be a part of the bigger community of designers to design their
            own fun experiences. Meme - T provides a platform for designers to
            directly design and upload.
          </p>
        </div>
        <Image
          imageURL={Designer}
          className="div__aboutUs-Designer-imgDesigner"
        />
      </div>
    </div>
  );
};

export default AboutUs;
