export const CART_PRODUCTS = [
  {
    _id: "1520",
    productId: {
      _id: "120",
      title: "Casual TShirt",
      contentLanguage: "Bengali",
      rating: 4,
      ratingsCount: 50,
      inWishlist: true,
    },
    inventoryId: {
      _id: "620",
      color: "Black",
      discountedPrice: 400,
      price: 850,
      discount: 50,
      sizeSymbol: "S",
      images: [require("./assets/nonresident-dwJw6KB_MjA-unsplash.png")],
    },
    quantity: 1,
    price: 400,
    estimatedDeliveryDate: new Date("01/25/2022"),
  },
  {
    _id: "1530",
    productId: {
      _id: "121",
      title: "Casual TShirt",
      contentLanguage: "Bengali",
      images: [require("./assets/ajo-jose-D9HRdD46K0o-unsplash.png")],
      rating: 3,
      ratingsCount: 30,
      inWishlist: true,
    },
    inventoryId: {
      _id: "622",
      color: "Black",
      discountedPrice: 400,
      price: 850,
      sizeSymbol: "XL",
      images: [require("./assets/nonresident-dwJw6KB_MjA-unsplash.png")],
    },
    quantity: 1,
    price: 400,
    estimatedDeliveryDate: new Date("01/25/2022"),
  },
];
