import { useState } from "react";

const useSnackbar = () => {
  const [snackbarObj, setSnackbarObj] = useState({
    open: false,
    message: "",
    autoHideDuration: 2000,
    vertical: "top",
    horizontal: "center",
    action: null,
  });

  const showMessage = ({
    content,
    timeout = 2000,
    handleClose,
    ...otherProps
  }) => {
    setTimeout(handleClose, timeout);
    setSnackbarObj((prevSnackbar) => ({
      ...prevSnackbar,
      open: true,
      message: content,
      autoHideDuration: timeout,
      ...otherProps,
    }));
  };

  const onClose = (event, reason) =>
    setSnackbarObj((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
      message: "",
    }));

  const { open, message, autoHideDuration, vertical, horizontal, action } =
    snackbarObj;

  return {
    open,
    message,
    showMessage,
    onClose,
    autoHideDuration,
    anchorOrigin: {
      vertical,
      horizontal,
    },
    action,
  };
};

export default useSnackbar;
