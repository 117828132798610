export const RATINGS_IMAGES = [
  require("./assets/louisse-lemuel-enad-Ba8JB_A7www-unsplash.png"),
  require("./assets/the-graphic-space-FTrGeAy0RW4-unsplash.png"),
  require("./assets/nelson-ndongala-alRAEyW1yFg-unsplash.png"),
  require("./assets/dimitry-zub-0nkzBDzLXTI-unsplash.png"),
  require("./assets/don-delfin-almonte-ebTNU_YTWgc-unsplash.png"),
];

export const FEEDBACKS_LIST = [
  {
    _id: "720",
    rating: 5,
    title: "Nice Color and Fit",
    description: "Nice Color and Fit",
    customerName: "Soumyadeep Pal",
    images: [
      require("./assets/louisse-lemuel-enad-Ba8JB_A7www-unsplash.png"),
      require("./assets/the-graphic-space-FTrGeAy0RW4-unsplash.png"),
    ],
    createdAt: new Date("09/07/2021"),
  },
  {
    _id: "721",
    rating: 5,
    title: "Fabric and Fit",
    description: `Lorem ipsum dolor sit amet, consectetur 
      adipiscing elit, sed do eius mod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim adminim veniam, quis no
      strud exercit.Lorem ipsum dolor sit amet, consectetur 
      adipiscing elit, sed do eius mod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim adminim veniam, quis no
      strud exercit.`,
    customerName: "Deepanshu Dixit",
    images: [],
    createdAt: new Date("09/07/2021"),
  },
  {
    _id: "722",
    rating: 4,
    title: "Excellent Product",
    images: [],
    description: "Excellent fit and good looking",
    customerName: "Swastik Singh",
    createdAt: new Date("09/07/2021"),
  },
];
