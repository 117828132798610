import React, { useState, useEffect, createContext } from "react";
import { UserServices } from "services";

export const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (!currentUser?._id) {
      fetchUserData();
    }
  }, [currentUser?._id]);

  const fetchUserData = async () => {
    const token = window.localStorage.getItem(`_t`);
    if (token) {
      try {
        const resData = await UserServices.authenticateUser();
        setCurrentUser({ ...resData });
      } catch (err) {
        console.error(err);
        setCurrentUser({
          _id: "GuestId",
        });
      }
    } else {
      setCurrentUser({
        _id: "GuestId",
      });
    }
  };

  const modifyCurrentUser = (userData) => setCurrentUser({ ...userData });

  const modifyCurrentUserWishlist = async (productId, inWishlist) => {
    try {
      const { success, wishlist } = await UserServices.modifyUserWishlist({
        productId,
        inWishlist,
      });
      setCurrentUser((prevUser) => {
        return { ...prevUser, wishlist };
      });
      return success;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const modifyCurrentUserCart = async (payload) => {
    try {
      const { success, msg, cart } = await UserServices.modifyUserCart(payload);
      setCurrentUser((prevUser) => {
        return { ...prevUser, cart };
      });
      return { success, msg };
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const logoutCurrentUser = async () => {
    try {
      await UserServices.logoutUser(currentUser?._id);
      window.localStorage.removeItem(`_t`);
      setCurrentUser({});
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        fetchUserData,
        modifyCurrentUser,
        modifyCurrentUserWishlist,
        modifyCurrentUserCart,
        logoutCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
