import React from "react";
import { SwipeableDrawer } from "@mui/material";
import { ActionIcon } from "components";
import "./index.css";

const CustomDrawer = ({
  direction,
  className,
  isOpen = false,
  onCloseDrawer,
  children,
}) => {
  return (
    <SwipeableDrawer
      className={`drawerMenu ${className}`}
      anchor={direction}
      open={isOpen}
      onClose={() => onCloseDrawer(false)}
    >
      <ActionIcon
        type="clear"
        className="btnCloseDrawer"
        onClick={onCloseDrawer}
      />
      {children}
    </SwipeableDrawer>
  );
};

export default CustomDrawer;
