import React from "react";
import "./index.css";

const BankAccountCard = ({
  bankAccountDetail,
  onUpdateAccount,
  onRemoveAccount,
  onSetPrimaryAccount,
}) => {
  const {
    _id,
    // bankName,
    isPrimaryAccount,
    bankAccountBranch,
    bankAccountHolderName,
    lastFourDigits,
    bankAccountIFSC,
    bankAccountLength,
  } = bankAccountDetail || {};
  return (
    <div className="bankAccount__card">
      <div className="bankAccount__card-details">
        <div className="bankAccount__card-details--left">
          <p className="bankAccount__card-details--left-accountNumber">
            {`${Array((bankAccountLength || 16) - 4)
              .fill("*")
              .join("")}${lastFourDigits}`}
          </p>
          <p className="bankAccount__card-details--left-accountNumber">
            {bankAccountIFSC}
          </p>
          <p className="bankAccount__card-details--left-holderName">
            {bankAccountHolderName}
          </p>
          <p className="bankAccount__card-details--left-branch">
            {bankAccountBranch}
          </p>
        </div>
      </div>
      <div className="bankAccount__card-actions">
        <p
          className="bankAccount__card-actions-update"
          onClick={() => onUpdateAccount(bankAccountDetail)}
        >
          Update
        </p>
        <p
          className="bankAccount__card-actions-remove"
          onClick={() => onRemoveAccount(_id)}
        >
          Remove
        </p>
        {!isPrimaryAccount && (
          <p
            className="bankAccount__card-actions-setPrimary"
            onClick={() => onSetPrimaryAccount(_id)}
          >
            Set Primary
          </p>
        )}
      </div>
    </div>
  );
};

export default BankAccountCard;
