import { ProductCard } from "components";
import { NoSearchResults } from "pages";
import { ProductServices } from "services";

const SEARCH_PRODUCT_GRID_CONFIG = {
  containerSpacing: 2,
  rowDivisions: 3,
  renderComponent: (value, { onAddToWishlist }) => (
    <ProductCard
      product={value}
      handleAddToWishlist={(ev) => {
        ev.stopPropagation();
        onAddToWishlist(value);
      }}
    />
  ),
  fetchListData: ProductServices.getProductsList,
  emptyDataContent: () => <NoSearchResults />,
};

export default SEARCH_PRODUCT_GRID_CONFIG;
