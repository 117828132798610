import React, { useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import {
  TextInputField,
  CheckboxInput,
  Button,
  Image,
  Loading,
  Snackbar,
} from "components";
import { useSnackbar } from "hooks";
import { UserContext } from "contexts";
import { UserServices } from "services";
import MemeTLogo from "assets/Meme-T_Login.png";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import "./laptop.css";

const VendorLoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const rememberMeChecked = localStorage.getItem("rememberMe");
  const { fetchUserData } = useContext(UserContext);
  const [rememberMe, setRememberMe] = useState(_.isEmpty(rememberMeChecked));
  const fromRoute = location.state?.from?.pathname || "/";

  const initialEmail = localStorage.getItem("rememberMe") || "";

  return (
    <Formik
      initialValues={{
        email: initialEmail,
        password: "",
      }}
      validationSchema={yup.object({
        email: yup
          .string()
          .nullable()
          .email("Email format is invalid")
          .required("Email is required"),
        password: yup.string().required("Password is required"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values) => {
        const payload = {
          email: values.email,
          password: values.password,
        };

        setLoading(true);

        try {
          const { token, tokenExp } = await UserServices.loginUser(payload);
          setLoading(false);
          localStorage.setItem("_t", token);
          localStorage.setItem("_tokenExp", tokenExp);
          if (rememberMe) {
            localStorage.setItem("rememberMe", values.email);
          } else {
            localStorage.removeItem("rememberMe");
          }
          await fetchUserData();
          navigate("/vendor/dashboard");
        } catch (err) {
          setLoading(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {(formikProps) => {
        return (
          <>
            <Snackbar {...snackbar} />
            {loading && <Loading />}
            <div className="div__vendorLogin-container">
              <form
                onSubmit={formikProps.handleSubmit}
                className="div__vendorLogin-formContainer"
              >
                <Link to="/" className="lnkLogo">
                  <Image className="imgLogo" imageURL={MemeTLogo} />
                </Link>
                <div className="div__vendorLogin-form">
                  <span className="lblSignIn">Vendor Sign In</span>
                  <TextInputField
                    name="email"
                    inputType="email"
                    labelText="Email"
                    inputValue={formikProps.values.email}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.email && formikProps.errors.email
                    }
                  />
                  <TextInputField
                    name="password"
                    inputType="password"
                    labelText="Password"
                    obscureText
                    forgotURL={`/forgotPassword`}
                    inputValue={formikProps.values.password}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.password &&
                      formikProps.errors.password
                    }
                  />
                  <CheckboxInput
                    labelText="Remember Me"
                    isChecked={rememberMe}
                    handleChange={() => setRememberMe(!rememberMe)}
                  />
                  <Button
                    buttonText="Sign In"
                    id="btnInput"
                    className="btnSignIn"
                    disableRipple
                    onClick={formikProps.handleSubmit}
                  >
                    Sign In
                  </Button>
                  <div className="div__vendorLogin-accountRegister">
                    <div className="dividerLine" />
                    <span className="div__vendorLogin-accountRegister-label">
                      New to Meme-T?
                    </span>
                    <div className="dividerLine" />
                  </div>
                  <Button
                    id="btnInput"
                    size="large"
                    disabled={formikProps.isSubmitting}
                    className="btnRegister"
                    onClick={() => navigate(`/vendor/onboarding`)}
                  >
                    Create New Account
                  </Button>
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default VendorLoginPage;
