import MaleGenderImage from "assets/MaleOption.png";
import FemaleGenderImage from "assets/FemaleOption.png";

export const GENDER_OPTIONS = [
  { label: "Male", value: "Male", image: MaleGenderImage },
  { label: "Female", value: "Female", image: FemaleGenderImage },
];

export const LANGUAGE_OPTIONS = [
  "Hindi",
  "Kashmiri",
  "Bengali",
  "Gondi",
  "Nepali",
  "Telugu",
  "Sindhi",
  "Tamil",
  "Dogri",
  "Konkani",
  "Urdu",
  "Kurukh",
  "Kannada",
  "Khandeshi",
  "Odia",
  "Tulu",
  "Malayalam",
  "Manipuri",
  "Punjabi",
  "Bodo",
  "Assamese",
  "Khasi",
  "Maithili",
  "Marwadi",
  "Gujarati",
  "Marathi",
];

export const SIZE_OPTIONS = ["S", "M", "L", "XL", "XXL"];

export const PRICE_RANGE_OPTIONS = [0, 5000];

export const DISCOUNT_RANGE_OPTIONS = [0, 100];

export const SORT_OPTIONS = [
  { label: "Price", subLabel: "High To Low", value: "price;desc", order: "desc" },
  { label: "Price", subLabel: "Low To High", value: "price;asc", order: "asc", hasDivider: true },
  { label: "Discount", subLabel: "High To Low", value: "discount;desc", order: "desc" },
  { label: "Discount", subLabel: "Low To High", value: "discount;asc", order: "asc", hasDivider: true },
  { label: "Rating", value: "rating;desc", order: "desc" },
  { label: "Relevance", value: "_id;desc", order: "asc" },
];
