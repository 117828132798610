import INVENTORY_CONFIG from "./InventoryConfig";
import PRODUCT_CONFIG from "./ProductConfig";
import TICKET_CONFIG from "./TicketConfig";
import ORDER_CONFIG from "./OrderConfig";
import SIZE_CHART_INCH_CONFIG from "./SizeChartInchConfig";
import SIZE_CHART_CM_CONFIG from "./SizeChartCmConfig";
import MEME_SUGGESTION_CONFIG from "./MemeSuggestionConfig";
import VENDOR_TRANSACTION_CONFIG from "./TransactionConfig";

const TABLE_DISPLAY_CONFIG = {
  Inventory: INVENTORY_CONFIG,
  Product: PRODUCT_CONFIG,
  Ticket: TICKET_CONFIG,
  Order: ORDER_CONFIG,
  MemeSuggestion: MEME_SUGGESTION_CONFIG,
  SizeChartInch: SIZE_CHART_INCH_CONFIG,
  SizeChartCm: SIZE_CHART_CM_CONFIG,
  Transaction: VENDOR_TRANSACTION_CONFIG,
};

export default TABLE_DISPLAY_CONFIG;
