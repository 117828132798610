import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

const Timer = ({ timeout, className, timeoutMessage, onTimerClear }) => {
  const [timerVal, setTimerVal] = useState(timeout);

  useEffect(() => {
    setInterval(timer, 1000);
    return () => {
      clearInterval(timer);
      onTimerClear && onTimerClear();
    };
  }, []);

  const timer = () =>
    setTimerVal((prevVal) => {
      if (prevVal === 0) {
        clearInterval(timer);
        onTimerClear && onTimerClear();
        return;
      }
      return prevVal - 1;
    });

  const displayTimerInFormat = useCallback(() => {
    const hour = Math.floor(timerVal / 3600);
    const minutes = Math.floor(timerVal / 60) - hour * 60;
    const seconds = Math.floor(timerVal % 60);
    const formattedHour =
      hour === 0 ? "" : hour < 10 ? `0${hour}:` : `${hour}:`;
    const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedHour}${formattedMinute}:${formattedSeconds}`;
  }, [timerVal]);

  return (
    <span className={className}>
      {timerVal > 0 ? displayTimerInFormat() : timeoutMessage}
    </span>
  );
};

export default Timer;

Timer.propTypes = {
  timeout: PropTypes.number.isRequired,
  className: PropTypes.string,
  timeoutMessage: PropTypes.string,
  onTimerClear: PropTypes.func,
};

Timer.defaultProps = {
  timeout: 60,
  className: ``,
  timeoutMessage: ``,
  onTimerClear: null,
};
