import BankOfBaroda from "assets/Payments/BOB.png";
import HDFCBank from "assets/Payments/HDFCBank.png";
import ICICIBank from "assets/Payments/ICICI.png";
import StandardCharteredBank from "assets/Payments/STCBank.png";
import UBIBank from "assets/Payments/UBIBank.png";
import YesBank from "assets/Payments/YesBank.png";

export const WALLET_PAYMENTS = [
  { name: "Amazon", value: "amazonpay" },
  { name: "Phone Pe", value: "phonepe" },
  { name: "Ola Money", value: "olamoney" },
  { name: "Airtel Money", value: "airtel" },
];

export const NET_BANKING_PAYMENTS = [
  { value: "YESB", label: "Yes Bank", image: YesBank },
  { value: "HDFC", label: "HDFC Bank", image: HDFCBank },
  {
    value: "SCBL",
    label: "Standard Chartered Bank",
    image: StandardCharteredBank,
  },
  { value: "ICIC", label: "ICICI Bank", image: ICICIBank },
  { value: "UBIN", label: "Union Bank of India", image: UBIBank },
  {
    value: "BARB_R",
    label: "Bank of Baroda - Retail Banking",
    image: BankOfBaroda,
  },
];
