import { UserContext } from "contexts/UserContext";
import React, { useContext } from "react";
import _ from "lodash";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RouteAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const token = localStorage.getItem("_t");
  const { currentUser } = useContext(UserContext);

  const authRoute = token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );

  return _.includes(allowedRoles, currentUser?.role) ? <Outlet /> : authRoute;
};

export default RouteAuth;
