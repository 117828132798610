import TAB_DISPLAY_CONFIG from "./TabDisplayConfig";

const getTabDisplayConfig = (screenName, dataList = [], onDataChange = null) => {
  const configFound = TAB_DISPLAY_CONFIG[screenName];
  const { tabsHeaderList } = configFound;

  return {
    ...configFound,
    renderTabsList: tabsHeaderList(dataList, onDataChange),
  };
};

export default getTabDisplayConfig;
