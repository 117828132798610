const SIZE_CHART_CM_CONFIG = {
  screenName: "SizeChart_CM",
  columnHeaders: [
    { label: "", key: "actions", type: "action" },
    { label: "Size", key: "size_cm", type: "decimal" },
    { label: "Brand Size", key: "sizeSymbol", type: "string" },
    { label: "Chest (cm)", key: "chest_cm", type: "decimal" },
    { label: "Front Length (cm)", key: "frontLength_cm", type: "decimal" },
    {
      label: "Across Shoulder (cm)",
      key: "acrossShoulder_cm",
      type: "decimal",
    },
    { label: "Sleeve Length (cm)", key: "sleeveLength_cm", type: "decimal" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
//   fetchListData: [],
  dataUnavailableMessage: `No data to display`,
};

export default SIZE_CHART_CM_CONFIG;
