/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import classnames from "classnames";
import "./index.css";

const MultiRangeSlider = ({ dataRange, onChange, displayFormat }, ref) => {
  const [min, max] = dataRange;
  const [minVal, setMinVal] = useState(min || 0);
  const [maxVal, setMaxVal] = useState(max || 100);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useImperativeHandle(ref, () => ({
    resetSliderRange () {
      setMinVal(min);
      setMaxVal(max);
    }
  }), [])

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const handleMaxValChange = useCallback(
    ({ target }) => {
      const value = Math.max(+target.value, minVal + 1);
      setMaxVal(value);
      onChange({ min: minVal, max: value });
      target.value = value.toString();
    },
    [maxVal]
  );

  const handleMinValueChange = useCallback(
    ({ target }) => {
      const value = Math.min(+target.value, maxVal - 1);
      setMinVal(value);
      onChange({ min: value, max: maxVal });
      target.value = value.toString();
    },
    [minVal]
  );

  return (
    <div className="container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(ev) => handleMinValueChange(ev)}
        className={classnames("thumb thumb--zindex-3", {
          "thumb--zindex-5": minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(ev) => handleMaxValChange(ev)}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{displayFormat(minVal)}</div>
        <div className="slider__right-value">{displayFormat(maxVal)}</div>
      </div>
    </div>
  );
};

export default forwardRef(MultiRangeSlider);
