import React from "react";

const ErrorMessage = ({ errorText }) => {
  return (
    <span
      className="errorMessage"
      style={{
        visibility: errorText ? "visible" : "hidden",
        color: errorText ? "bisque" : "transparent",
        fontSize: 14,
        margin: "5px",
      }}
    >
      {errorText || "No Errors"}
    </span>
  );
};

export default ErrorMessage;
