import React, { useState } from "react";
import {
  DetailCard,
  RowDetail,
  Button,
  Loading,
  Snackbar,
  IconDisplay,
} from "components";
import { displayCurrencyAmount, displayDateInFormat } from "utils";
import { useSnackbar } from "hooks";
import { OrderServices } from "services";
import OrderItems from "./OrderItems";
import "./index.css";

const OrderDetail = ({ orderDetail, isAdmin, onCloseDrawer }) => {
  const {
    _id,
    products,
    activeOrderStatus,
    activeDeliveryStatus,
    deliveryStatusIndex,
    receipt,
    createdAt,
    shippingAddress,
    estimatedDeliveryDate,
    lastReturnDate,
    amount,
    customerName,
    paymentMethod,
  } = orderDetail;
  const {
    recipientName,
    contact,
    firstLine,
    secondLine,
    city,
    state,
    zipCode,
  } = shippingAddress || {};

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const displayDetailRows = (detailsList) =>
    detailsList?.map(({ label, detail, className }) => (
      <RowDetail
        label={label}
        description={detail}
        className={className || ``}
      />
    ));

  const orderDetailsList = [
    {
      label: "Order ID:",
      detail: receipt,
      className: "orderDetail__container-content--right-item-orderId",
    },
    {
      label: "Ordered On:",
      detail: displayDateInFormat(createdAt, "Do MMMM YYYY"),
    },
    { label: "Ordered By:", detail: customerName },
  ];

  const deliveryDetailsList = [
    {
      label: "Est. Delivery Date:",
      detail: displayDateInFormat(estimatedDeliveryDate, "Do MMMM YYYY"),
    },
    {
      label: "Last Return Date:",
      detail: displayDateInFormat(lastReturnDate, "Do MMMM YYYY"),
    },
  ];

  const recipientDetailsList = [
    { label: "Name:", detail: recipientName || `` },
    {
      label: "Address:",
      detail: `${firstLine}, ${secondLine}, ${city}, ${state}, ${zipCode}`,
    },
    { label: "Phone Number:", detail: contact },
  ];

  const paymentDetailsList = [
    { label: "Payment Method:", detail: paymentMethod?.method || "" },
    { label: "Subtotal:", detail: displayCurrencyAmount(amount - 40) },
    { label: "Delivery:", detail: displayCurrencyAmount(40) },
    { label: "GST:", detail: displayCurrencyAmount(0) },
    { label: "Total:", detail: displayCurrencyAmount(amount) },
  ];

  const downloadInvoice = () =>
    (window.location.href = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ORDERS_API}/invoice/${_id}`);

  const markDeliveryDispatched = async () => {
    try {
      setLoading(true);
      const payload = {
        _id,
        updateType: "DELIVERY",
        updateData: {
          description: "DISPATCHED",
          timestamp: new Date(),
        },
      };
      const { msg } = await OrderServices.updateOrderStatus(payload);
      setLoading(false);
      snackbar.showMessage({
        content: msg,
        handleClose: onCloseDrawer,
      });
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  return (
    <>
      <Snackbar {...snackbar} />
      {loading && <Loading />}
      <div className="orderDetail__container">
        <p className="orderDetail__container-header">ORDER DETAILS</p>
        <div className="orderDetail__container-content">
          <div className="orderDetail__container-content--left">
            <OrderItems products={products} />
          </div>
          <div className="orderDetail__container-content--right">
            <div className="orderDetail__container-content--right-top">
              <DetailCard
                className="orderDetail__container-content--right-orderStatus"
                headerClass="orderDetail__container-content--right-item-header"
                headerContent={
                  <>
                    <IconDisplay
                      type="shoppingBag"
                      className="orderDetail__container-content--right-item-header-icon"
                    />
                    Order Status: {activeOrderStatus}
                  </>
                }
                contentClass="orderDetail__container-content--right-item"
                cardContent={displayDetailRows(orderDetailsList)}
              />
              <DetailCard
                className="orderDetail__container-content--right-deliveryStatus"
                headerClass="orderDetail__container-content--right-item-header"
                headerContent={
                  <>
                    <IconDisplay
                      type="localShipping"
                      className="orderDetail__container-content--right-item-header-icon"
                    />
                    Delivery Status:{" "}
                    {activeDeliveryStatus || "Yet to be Dispatched"}
                  </>
                }
                contentClass="orderDetail__container-content--right-item"
                cardContent={displayDetailRows(deliveryDetailsList)}
              />
            </div>
            <div className="orderDetail__container-content--right-bottom">
              <DetailCard
                className="orderDetail__container-content--right-recipientDetails"
                headerClass="orderDetail__container-content--right-item-header"
                headerContent={
                  <>
                    <IconDisplay
                      type="person"
                      className="orderDetail__container-content--right-item-header-icon"
                    />
                    Recipient Details
                  </>
                }
                contentClass="orderDetail__container-content--right-item"
                cardContent={displayDetailRows(recipientDetailsList)}
              />
              {!isAdmin && (
                <DetailCard
                  className="orderDetail__container-content--right-paymentDetails"
                  headerClass="orderDetail__container-content--right-item-header"
                  headerContent={`Payment Details`}
                  contentClass="orderDetail__container-content--right-item"
                  cardContent={displayDetailRows(paymentDetailsList)}
                />
              )}
              {isAdmin && (
                <div className="orderDetail__container-content--right-vendorActions">
                  <Button
                    className="orderDetail__container-content--right-vendorActions-invoice"
                    onClick={downloadInvoice}
                  >
                    Download Invoice
                  </Button>
                  {activeOrderStatus !== "CANCELLED" &&
                    deliveryStatusIndex > 0 && (
                      <Button
                        className="orderDetail__container-content--right-vendorActions-dispatch"
                        onClick={markDeliveryDispatched}
                      >
                        Mark As Dispatched
                      </Button>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
