import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Snackbar,
  Loading,
  TextInputField,
  Button,
  CheckboxInput,
} from "components";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { VendorServices } from "services";
import { encodeDecodeData } from "utils";
import "./index.css";

const BankAccountForm = ({ bankAccountDetail, onFormSubmit }) => {
  const snackbar = useSnackbar();
  const [isPrimaryAccount, setIsDefaultAccount] = useState(false);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    setIsDefaultAccount(_.get(bankAccountDetail, "isPrimaryAccount", false));
  }, [bankAccountDetail]);

  return (
    <Formik
      initialValues={{
        bankAccountNumber: encodeDecodeData(
          _.get(bankAccountDetail, "bankAccountNumber", ""),
          "decode"
        ),
        bankAccountIFSC: _.get(bankAccountDetail, "bankAccountIFSC", ""),
        bankAccountBranch: _.get(bankAccountDetail, "bankAccountBranch", ""),
        bankAccountHolderName: _.get(
          bankAccountDetail,
          "bankAccountHolderName",
          ""
        ),
      }}
      enableReinitialize
      validationSchema={yup.object({
        bankAccountNumber: yup
          .string()
          .required("Bank Account No. is mandatory")
          .max(16, "Should contain atmost 16 digits"),
        bankAccountIFSC: yup
          .string()
          .required("Bank Account IFSC is mandatory")
          .min(11, "Should contain exactly 11 digits")
          .max(11, "Should contain exactly 11 digits"),
        bankAccountBranch: yup.string(),
        bankAccountHolderName: yup.string(),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        let payload = {
          ...values,
          bankAccountNumber: encodeDecodeData(
            values.bankAccountNumber,
            "encode"
          ),
          isPrimaryAccount,
          vendorId: currentUser?._id,
        };
        if (!_.isEmpty(bankAccountDetail?._id)) {
          payload = {
            ...payload,
            _id: bankAccountDetail?._id,
          };
        }
        try {
          const { msg, bankAccountId } =
            await VendorServices.createUpdateBankAccount(payload);
          setSubmitting(false);
          snackbar.showMessage({
            content: msg,
          });
          const bankAccountLength = values.bankAccountNumber.length;
          onFormSubmit &&
            onFormSubmit({
              ...payload,
              _id: bankAccountId,
              lastFourDigits: values.bankAccountNumber.substring(
                bankAccountLength - 4,
                bankAccountLength
              ),
              bankAccountLength,
            });
        } catch (err) {
          setSubmitting(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
      }) => (
        <>
          <Snackbar {...snackbar} />
          {isSubmitting && <Loading />}
          <form onSubmit={handleSubmit} className="bankAccount__form-container">
            <div className="bankAccount__form">
              <p className="bankAccount__form-header--title">
                Add Bank Account
              </p>
              <p className="bankAccount__form-header--subTitle">
                This bank account will receive the revenue
              </p>
              <TextInputField
                name="bankAccountNumber"
                inputType="number"
                labelText="Bank Account Number"
                inputValue={values.bankAccountNumber}
                obscureText
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={
                  touched.bankAccountNumber && errors.bankAccountNumber
                }
              />
              <TextInputField
                name="bankAccountIFSC"
                inputType="text"
                labelText="Bank Accounts IFSC"
                inputValue={values.bankAccountIFSC}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={touched.bankAccountIFSC && errors.bankAccountIFSC}
              />
              <TextInputField
                name="bankAccountBranch"
                inputType="text"
                labelText="Account Branch"
                inputValue={values.bankAccountBranch}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={
                  touched.bankAccountBranch && errors.bankAccountBranch
                }
              />
              <TextInputField
                name="bankAccountHolderName"
                inputType="text"
                labelText="Account Holder Name"
                inputValue={values.bankAccountHolderName}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={
                  touched.bankAccountHolderName && errors.bankAccountHolderName
                }
              />
              <CheckboxInput
                isChecked={isPrimaryAccount}
                handleChange={() => setIsDefaultAccount(!isPrimaryAccount)}
                labelText="Select this as primary account?"
              />
              <Button
                id="btnInput"
                className="bankAccount__form-submit"
                disableRipple
                onClick={handleSubmit}
              >
                {_.isEmpty(bankAccountDetail?._id) ? "Add" : "Update"} Bank
                Account
              </Button>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BankAccountForm;
