import React from "react";
import { DetailCard, Image, RowDetail, Button } from "components";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";

const MemeSuggestionDetail = ({ memeSuggestionDetail, onWithdrawMeme }) => {
  const { title, description, language, images, createdAt } =
    memeSuggestionDetail;

  return (
    <div className="memeSuggestion__detail-container">
      <p className="memeSuggestion__detail-container-header">
        MEME SUGGESTION DETAILS
      </p>
      <div className="memeSuggestion__detail-container-content">
        <DetailCard
          className="memeSuggestion__detail--left"
          headerContent="Meme - Images"
          cardContent={
            <div className="memeSuggestion__detail-imagesList">
              {images?.map((imageURL, index) => (
                <Image
                  key={imageURL}
                  imageURL={displayDriveImage(imageURL)}
                  className={`memeSuggestion__detail-imagesList_image`}
                />
              ))}
            </div>
          }
        />
        <div className="memeSuggestion_detail--right">
          <DetailCard
            className="memeSuggestion_detail-content"
            headerContent="Meme Description"
            cardContent={
              <div className="memeSuggestion__detail-content-list">
                <RowDetail
                  label="Date Suggested"
                  description={displayDateInFormat(createdAt, "Do MMMM YYYY")}
                />
                <RowDetail label="Language" description={language} />
                <RowDetail label="Meme Title" description={title} />
                <RowDetail label="Meme Description" description={description} />
              </div>
            }
          />
          <Button
            id="btnInput"
            className="memeSuggestion_detail-withdrawMeme"
            onClick={onWithdrawMeme}
          >
            WITHDRAW MEME
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MemeSuggestionDetail;
