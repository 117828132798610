import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  Loading,
  PaginatedTable,
  TextField,
  DateTimePickerInput,
  DropdownInput,
  Snackbar,
  CustomDrawer
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import {
  TICKET_CATEGORY_OPTIONS,
  TICKET_STATUS_OPTIONS,
} from "constants";
import { generateQueryParamsFromObject, generateDropdownOptions } from "utils";
import { useSnackbar } from "hooks";
import ReplyTicket from "./ReplyTicket";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const TicketsListPage = () => {
  const snackbar = useSnackbar();
  const [adminTicketsList, setAdminTicketsList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [loading, setLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState();

  useEffect(() => {
    fetchTicketsList();
  }, [searchFilters]);

  const fetchTicketsList = async () => {
    try {
      setLoading(true);
      const query = generateQueryParamsFromObject({
        ...searchFilters,
        statusIndex:
          _.indexOf(TICKET_STATUS_OPTIONS, status) < 0
            ? null
            : _.indexOf(TICKET_STATUS_OPTIONS, status),
      });
      const { ticketsList } = await ticketConfig.fetchListData(query);
      setLoading(false);
      setAdminTicketsList(ticketsList);
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
      setAdminTicketsList([]);
    }
  };

  const ticketConfig = getTableDisplayConfig("Ticket", {
    actions: (rowVal) => (
      <div className="div_actionIcons">
        <ActionIcon
          type="chat"
          iconClassName="listIcon"
          onClick={() => console.log(`Open Chat`, rowVal)}
        />
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedTicket(rowVal)}
        />
      </div>
    ),
  });

  const handleSearchFiltersChange = (key, value) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const { header } = ticketConfig;
  const { searchTerm, fromDate, toDate, category, status } =
    searchFilters || {};

  return (
    <>
      <Snackbar {...snackbar} />
      {loading && <Loading />}
      <div className="admin__ticketsList">
        {header}
        <div className="admin__ticketsList__filters">
          <div className="div__searchInput">
          <TextField
            className="admin__ticketsList__searchInput"
            placeholder="Search by TicketId or consumer name"
            value={searchTerm}
            onChange={({ target }) =>
              handleSearchFiltersChange("searchTerm", target.value)
            }
          />
          </div>
          <div className="div__Filters">
          <DateTimePickerInput
            inputType="Date"
            className="admin__ticketsList__fromDate"
            placeholder="From"
            dateValue={fromDate || null}
            handleChange={(dateVal) =>
              handleSearchFiltersChange("fromDate", dateVal?._d.toISOString())
            }
          />
          <DateTimePickerInput
            inputType="Date"
            className="admin__ticketsList__toDate"
            dateValue={toDate || null}
            placeholder="To"
            handleChange={(dateVal) =>
              handleSearchFiltersChange("toDate", dateVal?._d.toISOString())
            }
          />
          <DropdownInput
            className="admin__ticketsList__status"
            placeholder="Ticket Status"
            optionsList={generateDropdownOptions(TICKET_STATUS_OPTIONS)}
            selectedOption={status || "Ticket Status"}
            onSelect={({ target }) =>
              handleSearchFiltersChange("status", target.value)
            }
          />
          <DropdownInput
            className="admin__ticketsList__category"
            optionsList={generateDropdownOptions(TICKET_CATEGORY_OPTIONS)}
            placeholder="Ticket Category"
            selectedOption={category || "Ticket Category"}
            onSelect={({ target }) =>
              handleSearchFiltersChange("category", target.value)
            }
          />
          </div>
          
        </div>
        <PaginatedTable config={ticketConfig} dataList={adminTicketsList} />
        <CustomDrawer
          direction="bottom"
          className="admin__ticketsList__ticketDrawer--view"
          isOpen={!_.isUndefined(selectedTicket)}
          onCloseDrawer={() => setSelectedTicket(undefined)}
        >
          {selectedTicket && (
            <ReplyTicket
              ticketDetail={selectedTicket}
              onCloseDrawer={() => setSelectedTicket(undefined)}
            />
          )}
        </CustomDrawer>
      </div>
    </>
  );
};

export default TicketsListPage;
