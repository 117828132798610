import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  DetailCard,
  IconDisplay,
  CircularProgress,
  EmptyListPlaceholder,
} from "components";
import { VendorServices } from "services";
import { generateQueryParamsFromObject } from "utils";
import EmptyLatestTransactionsListImage from "assets/Vendor/EmptyTransactionsList.png";
import TransactionCard from "./TransactionCard";
import "./index.css";

const LatestTransactions = ({ vendorId }) => {
  const [loading, setLoading] = useState(false);
  const [latestTransactionsList, setLatestTransactionsList] = useState([]);

  useEffect(() => {
    fetchLatestTransactions();
  }, []);

  const fetchLatestTransactions = async () => {
    try {
      setLoading(true);
      const query = generateQueryParamsFromObject({
        vendorId,
        pageLimit: 5,
      });
      const { transactionsList } = await VendorServices.fetchTransactionsList(
        query
      );
      setLatestTransactionsList(transactionsList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <DetailCard
        className="vendorDashboard__gridContainer--transactionsList"
        headerClass="vendorDashboard__gridContainer--transactionsList-header"
        headerContent={
          <>
            <span>
              <IconDisplay
                type="cash"
                className="vendorDashboard__gridContainer--transactionsList-icon"
              />{" "}
              Latest Transactions
            </span>
            {!_.isEmpty(latestTransactionsList) && (
              <Link
                to={`/vendor/wallet`}
                className="vendorDashboard__gridContainer--transactionsList-header-viewAllTransactions"
              >
                Go To Wallet
              </Link>
            )}
          </>
        }
        contentClass="vendorDashboard__gridContainer--transactionsList-content"
        cardContent={
          <>
            {loading && <CircularProgress className="loadingProgress" />}
            {!loading && _.isEmpty(latestTransactionsList) && (
              <EmptyListPlaceholder
                placeholderImage={EmptyLatestTransactionsListImage}
                imageClass="vendorDashboard__gridContainer--transactionsList-imgEmptyList"
                message={`All new transactions will be displayed here.`}
              />
            )}
            {!loading &&
              latestTransactionsList?.map((transactionDetail) => (
                <TransactionCard {...transactionDetail} />
              ))}
          </>
        }
      />
    </>
  );
};

export default LatestTransactions;
