import React, { useState, useEffect } from "react";
import { PaginatedTable, ActionIcon, Loading } from "components";
import { getTableDisplayConfig } from "displayConfigs";

const TicketsList = ({ ticketStatus }) => {
  const [ticketsList, setTicketsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDetailItem, setSelectedDetailItem] = useState({});

  useEffect(() => {
    fetchTicketsList();
  }, [ticketStatus]);

  const fetchTicketsList = async () => {
    try {
      setLoading(true);
      const dataList = await ticketConfig.fetchListData({
        query: `status=${ticketStatus}`,
      });
      setLoading(false);
      setTicketsList(dataList);
    } catch (err) {
      setLoading(false);
      console.error(`Error while fetching tickets`, err);
    }
  };

  const ticketConfig = getTableDisplayConfig("Ticket", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="chat"
          iconClassName="listIcon"
          onClick={() => console.log(`Open Chat`, rowVal)}
        />
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedDetailItem(rowVal)}
        />
      </>
    ),
  });

  const { viewDetailComponent } = ticketConfig || {};

  return (
    <>
      {selectedDetailItem && viewDetailComponent(selectedDetailItem)}
      {loading && <Loading />}
      <PaginatedTable
        config={ticketConfig}
        dataList={ticketsList}
        selectedDetailItem={selectedDetailItem}
      />
    </>
  );
};

export default TicketsList;
