import IconDisplay from "components/IconDisplay";

export const PAGE_ROUTES = [
  { link: "/", label: "Home" },
  { link: "/vendor/dashboard", label: "My Dashboard" },
  // { link: "/tickets", label: "My Tickets" },
  // { link: "/orders", label: "My Orders" },
  { link: "/aboutUs", label: "About Us" },
];

export const CONTACT_US_DETAILS = [
  {
    icon: <IconDisplay type="phone" />,
    label: "Helpline:",
    value: process.env.REACT_APP_HELPLINE_NUMBER,
  },
  {
    icon: <IconDisplay type="location" />,
    label: "Headquarters:",
    value: process.env.REACT_APP_CONTACT_ADDRESS,
  },
  {
    icon: <IconDisplay type="mail" />,
    label: "Email To:",
    value: process.env.REACT_APP_CONTACT_EMAIL,
  },
];

export const SOCIAL_MEDIA_LINKS = [
  {
    type: "twitter",
    link: process.env.REACT_APP_TWITTER_LINK,
  },
  {
    type: "facebook",
    link: process.env.REACT_APP_FACEBOOK_LINK,
  },
  {
    type: "instagram",
    link: process.env.REACT_APP_INSTAGRAM_LINK,
  },
];