import React, { useState } from "react";
import { Tabs, Tab, CircularProgress } from "@mui/material";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const TabDisplay = ({ config, loading }) => {
  const [tabSelected, setTabSelected] = useState(0);
  const handleChange = (_, newValue) => {
    setTabSelected(newValue);
  };

  const { renderTabsList, screenKey } = config || {};

  return (
    <>
      <Tabs value={tabSelected} onChange={handleChange}>
        {renderTabsList?.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>
      {loading ? (
        <CircularProgress className="loadingProgress" />
      ) : (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${screenKey}`}
          aria-labelledby={`simple-tab-${screenKey}`}
        >
          {renderTabsList?.[tabSelected]?.content}
        </div>
      )}
    </>
  );
};

export default TabDisplay;
