import { Axios } from "utils";

const MemeSuggestionServices = {};
const BASE_URL = process.env.REACT_APP_MEME_SUGGESTION_API;

MemeSuggestionServices.createUpdateMemeSuggestion = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

MemeSuggestionServices.getMemeSuggestionsList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

MemeSuggestionServices.deleteMemeSuggestion = async (deleteId) => {
  try {
    const { data } = await Axios.delete(`${BASE_URL}/${deleteId}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default MemeSuggestionServices;
