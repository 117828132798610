export const SIDEBAR_MENU_ITEMS = [
  { text: "My Profile", icon: "person", link: "/profile" },
  { text: "My Orders", icon: "shoppingCart", link: "/myOrders" },
  { text: "My Tickets", icon: "ticket", link: "/myTickets" },
  {
    text: "My Wishlist",
    icon: "favoriteBorder",
    link: "/wishlist",
    hasDivider: true,
  },
  {
    text: "Latest Additions",
    icon: "checkroom",
    link: `/products/search?latestAdditions=true`,
  },
  {
    text: "Language Categories",
    custom: true,
    hasDivider: true,
  },
  { text: "Sign Out", icon: "logout", link: "/login" },
];

export const AUTHENTICATION_ROUTES = [
  "/login",
  "/register",
  "/forgotPassword",
  "/unauthorized",
  "/vendor/onboarding",
  "*"
];
