import { ProductServices } from "services";

const PRODUCT_CONFIG = {
  screenName: "Product",
  collapsibleRow: true,
  columnHeaders: [
    { label: "Title", key: "title", type: "string" },
    { label: "Language", key: "contentLanguage", type: "string" },
    { label: "Price", key: "price", type: "currency" },
    { label: "Discount", key: "discount", type: "percent" },
    { label: "Designed By", key: "designedBy", type: "string" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  fetchListData: ProductServices.getProductsListForVendor,
  dataListKey: "productsList",
  dataUnavailableMessage: `No data to display`,
};

export default PRODUCT_CONFIG;
