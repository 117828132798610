import React from "react";
import "./index.css";
import "./mobile.css";
import { Stepper, Step, StepLabel } from "@mui/material";

const CustomStepper = ({ activeStep, steps, className, labelClass }) => {
  return (
    <Stepper activeStep={activeStep} className={className} alternativeLabel>
      {steps?.map(({ label, icon }, index) => (
        <Step key={index} completed={activeStep >= index}>
          <StepLabel icon={icon} className={labelClass}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
