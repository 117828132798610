import React, { useState } from "react";
import { IconButton, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const ExpandIcon = ({ expanded, handleExpandClick }) => (
  <ExpandMore
    expand={expanded}
    onClick={handleExpandClick}
    aria-expanded={expanded}
    aria-label="show more"
  >
    <ExpandMoreIcon />
  </ExpandMore>
);

const Collapsible = ({
  collapsibleClassname,
  headerClassname,
  expandedClassname,
  header,
  timeout = "auto",
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <div
        className={`${headerClassname} ${expanded ? expandedClassname : ""}`}
        onClick={() => setExpanded(!expanded)}
      >
        {header}
      </div>
      <Collapse
        className={collapsibleClassname}
        in={expanded}
        timeout={timeout}
        unmountOnExit
      >
        {children}
      </Collapse>
    </>
  );
};

export default Collapsible;
