import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "components";
import "./index.css";
import "./mobile.css";

const CardPayment = ({ paymentAmount, onPayment }) => {
  const handleCardPayment = () =>
    onPayment({
      method: "card",
      card: {
        number: cardNumber,
        name: cardHolderName,
        expiry_month: cardMonth,
        expiry_year: cardYear,
        cvv: cardCVV,
      },
    });

  const [cardNumber, setCardNumber] = useState();
  const [cardHolderName, setCardHolderName] = useState();
  const [cardCVV, setCardCVV] = useState();
  const [cardMonth, setCardMonth] = useState();
  const [cardYear, setCardYear] = useState();

  return (
    <>
      <div className="cardPayment__container">
        <form id="parent-form">
          <div className="cardPayment__CardNumber">
            <p className="label__cardNumber">Card Number</p>
            <p className="label__cardNumber-subheading">
              Enter 16-digit Card number on your card
            </p>
            <TextField
              id="card_number"
              value={cardNumber}
              inputProps={{ maxLength: 16 }}
              onChange={({ target: { value } }) => setCardNumber(value)}
              placeholder="0000-0000-0000-0000"
              variant="standard"
              color="#000000"
            ></TextField>
            <p id="card_type"></p>
          </div>

          <div className="cardPayment__HolderName">
            <p className="label__HolderName">Card Holder Name</p>
            <p className="label__HolderName-subheading">
              Enter the name of the card holder
            </p>
            <TextField
              onChange={({ target: { value } }) => setCardHolderName(value)}
              value={cardHolderName}
              placeholder="John Smith"
              variant="standard"
              color="#000000"
            ></TextField>
          </div>

          <div className="cardPayment__CVV-ExpiryDate__Container">
            <div className="cardPayment__CVV">
              <p className="label__CVV">CVV</p>
              <TextField
                id="card_cvv"
                onChange={({ target: { value } }) => setCardCVV(value)}
                inputProps={{ maxLength: 3 }}
                value={cardCVV}
                type="password"
                label="CVV"
                variant="standard"
                color="#000000"
              ></TextField>
            </div>
            <div className="cardPayment__ExpiryDate">
              <p className="label__ExpiryDate">Expiry Date</p>
              <div className="ExpiryDate__DateInput">
                <TextField
                  id="card_expiry"
                  onChange={({ target: { value } }) => setCardMonth(value)}
                  value={cardMonth}
                  inputProps={{ maxLength: 2 }}
                  label="MM"
                  variant="standard"
                  color="#000000"
                ></TextField>{" "}
                /
                <TextField
                  id="card_expiry"
                  onChange={({ target: { value } }) => setCardYear(value)}
                  value={cardYear}
                  inputProps={{ maxLength: 2 }}
                  label="YY"
                  variant="standard"
                  color="#000000"
                ></TextField>
              </div>
            </div>
          </div>

          <Button onClick={handleCardPayment} id="button_checkoutPayment">
            Pay {paymentAmount}
          </Button>
        </form>
      </div>
    </>
  );
};

export default CardPayment;
