import React from "react";
import _ from "lodash";
import { DetailCard, EmptyListPlaceholder } from "components";
import EmptyBankAccountsListImage from "assets/Vendor/EmptyBankAccountsList.png";
import BankAccountCard from "./BankAccountCard";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const BankAccountsList = ({
  bankAccountsList,
  removeBankAccount,
  updateBankAccount,
  onSetPrimaryAccount
}) => {
  return (
    <>
      <DetailCard
        className="vendorDashboard__gridContainer--bankAccountsList"
        headerClass="vendorDashboard__gridContainer--bankAccountsList-header"
        headerContent={<span>Active Bank Accounts</span>}
        contentClass="vendorDashboard__gridContainer--bankAccountsList-content"
        cardContent={
          _.isEmpty(bankAccountsList) ? (
            <EmptyListPlaceholder
              placeholderImage={EmptyBankAccountsListImage}
              imageClass="vendorDashboard__gridContainer--bankAccountsList-imgEmptyList"
              message={`Your active bank accounts will be displayed here. Currently there are no bank accounts added. `}
            />
          ) : (
            bankAccountsList?.map((bankAccount) => (
              <BankAccountCard
                key={bankAccount?._id}
                bankAccountDetail={bankAccount}
                onRemoveAccount={removeBankAccount}
                onUpdateAccount={updateBankAccount}
                onSetPrimaryAccount={onSetPrimaryAccount}
              />
            ))
          )
        }
      />
    </>
  );
};

export default BankAccountsList;
