const SIZE_CHART_INCH_CONFIG = {
  screenName: "SizeChart_Inch",
  columnHeaders: [
    { label: "", key: "actions", type: "action" },
    { label: "Size", key: "size_inch", type: "decimal" },
    { label: "Brand Size", key: "sizeSymbol", type: "string" },
    { label: "Chest (in)", key: "chest_inch", type: "decimal" },
    { label: "Front Length (in)", key: "frontLength_inch", type: "decimal" },
    {
      label: "Across Shoulder (in)",
      key: "acrossShoulder_inch",
      type: "decimal",
    },
    { label: "Sleeve Length (in)", key: "sleeveLength_inch", type: "decimal" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  // fetchListData: SIZE_CHART_INCH_DATA,
  dataUnavailableMessage: `No data to display`,
};

export default SIZE_CHART_INCH_CONFIG;
