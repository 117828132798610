import React from "react";
import PropTypes from "prop-types";
import { RowDetail } from "components";
import { displayDateInFormat, displayCurrencyAmount } from "utils";
import "./index.css";
import { IconDisplay } from "components/index";

const TransactionCard = ({
  _id,
  orderId,
  transactionAmount,
  createdAt,
  transactionType,
}) => {
  return (
    <div className="vendorTransactionCard">
      <div className="vendorTransactionCard__left">
        <IconDisplay
          type={
            transactionType === "CREDIT"
              ? "creditTransaction"
              : "debitTransaction"
          }
          className={` vendorTransactionCard__left-icon
            ${
              transactionType === "CREDIT"
                ? "vendorTransactionCard__left-icon--credit"
                : "vendorTransactionCard__left-icon--debit"
            }
          `}
        />
        <div className="vendorTransactionCard__left-detail">
          <RowDetail
            description={`Transaction Id : ${_id}`}
            className="vendorTransactionCard__detail-orderId"
          />
          {orderId && (
            <RowDetail
              description={`Order Id : ${orderId || ""}`}
              className="vendorTransactionCard__detail-orderId"
            />
          )}
          <RowDetail
            description={`${displayDateInFormat(
              createdAt,
              "Do MMMM YYYY"
            )} ${displayDateInFormat(createdAt, "HH:mm")}`}
            className="vendorTransactionCard__detail-orderedAt"
          />
        </div>
      </div>
      <span className="vendorTransactionCard__right-transactionAmount">
        {displayCurrencyAmount(Math.abs(transactionAmount))}
      </span>
    </div>
  );
};

export default TransactionCard;

TransactionCard.propTypes = {
  _id: PropTypes.string,
  transactionAmount: PropTypes.number,
  createdAt: PropTypes.string,
  transactionType: PropTypes.string,
};

TransactionCard.defaultProps = {
  _id: "",
  transactionAmount: null,
  createdAt: "",
  transactionType: "",
};
