export const PRODUCTS_LIST = [
  {
    _id: "120",
    title: "Nike TShirt",
    contentLanguage: "Bengali",
    price: 850,
    images: [
      "https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/eb0vi3orhh1y0lrohu40/sportswear-jdi-t-shirt-2Z8FSC.png",
      "https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/i1-1d3cbcae-208d-411c-9324-236823aaaf35/sportswear-portland-mens-t-shirt-GmCl2p.png",
    ],
    discount: 10,
    discountedPrice: 665,
    rating: 4,
    ratingsCount: 25,
  },
  {
    _id: "121",
    title: "Adidas Tshirt",
    contentLanguage: "Marathi",
    price: 1000,
    images: [
      "https://5.imimg.com/data5/SV/XT/MY-55472971/black-t-shirt-500x500.png",
    ],
    discount: 20,
    discountedPrice: 800,
    rating: 4,
    ratingsCount: 20,
  },
  {
    _id: "122",
    title: "Van Huesen Tshirt",
    contentLanguage: "Punjabi",
    price: 560,
    images: [
      "https://vanheusenindia.imgix.net/img/app/product/3/313891-1442639.jpg?auto=format&w=618",
      "https://vanheusenindia.imgix.net/img/app/product/6/622612-6012626.jpg?auto=format&w=618",
    ],
    discount: 50,
    discountedPrice: 280,
    rating: 3,
    ratingsCount: 50,
  },
  {
    _id: "123",
    title: "Park Avenue Tshirt",
    contentLanguage: "Sindhi",
    price: 360,
    images: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjaigcOE0eGXwsIEmt7mo8xxnmud6NnRU7Qw&usqp=CAU",
    ],
    discount: 60,
    discountedPrice: 144,
    rating: 3,
    ratingsCount: 25,
  },
  {
    _id: "124",
    title: "Polo Sport Tshirt",
    contentLanguage: "Gujarati",
    price: 1200,
    images: [
      "https://cdn.fashiola.in/L58982107/polo-ralph-lauren-retro-sport-capsule-logo-t-shirt-custom-regular-fit-in.jpg",
    ],
    discount: 25,
    discountedPrice: 900,
    rating: 2,
    ratingsCount: 1,
  },
  {
    _id: "125",
    title: "Reebok Tshirt",
    contentLanguage: "Kannada",
    price: 2000,
    images: [
      "https://www.kidinn.com/f/13762/137627495/reebok-t-shirt-short-sleeve-t-shirt.jpg",
    ],
    discount: 25,
    discountedPrice: 1500,
    rating: 4,
    ratingsCount: 10,
  },
];

export const RECOMMENDED_PRODUCTS_LIST = [
  {
    _id: "120",
    title: "Casual TShirt",
    contentLanguage: "Bengali",
    images: [require("./assets/nonresident-dwJw6KB_MjA-unsplash.png")],
    price: 1020,
    discount: 41,
    discountedPrice: 850,
    rating: 4,
    ratingsCount: 50,
  },
  {
    _id: "121",
    title: "Casual TShirt",
    contentLanguage: "Bengali",
    images: [require("./assets/taylor-dG4Eb_oC5iM-unsplash.png")],
    price: 1020,
    discount: 41,
    discountedPrice: 850,
    rating: 4,
    ratingsCount: 50,
  },
  {
    _id: "122",
    title: "Casual TShirt",
    contentLanguage: "Bengali",
    images: [require("./assets/jacinto-diego-XGtMs_0YqAY-unsplash.png")],
    price: 1020,
    discount: 41,
    discountedPrice: 850,
    rating: 4,
    ratingsCount: 50,
  },
  {
    _id: "123",
    title: "Casual TShirt",
    contentLanguage: "Bengali",
    images: [require("./assets/ajo-jose-D9HRdD46K0o-unsplash.png")],
    price: 1020,
    discount: 41,
    discountedPrice: 850,
    rating: 4,
    ratingsCount: 50,
  },
  {
    _id: "124",
    title: "Casual TShirt",
    contentLanguage: "Bengali",
    images: [require("./assets/gursimrat-ganda-IicDU7Wmc1E-unsplash.png")],
    price: 1020,
    discount: 41,
    discountedPrice: 850,
    rating: 4,
    ratingsCount: 50,
  },
];

export const PRODUCTS_LIST_ADMIN_VIEW = [
  {
    _id: "120",
    title: "Marathi Pun",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Marathi",
    images: [
      "https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/eb0vi3orhh1y0lrohu40/sportswear-jdi-t-shirt-2Z8FSC.png",
      "https://static.nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/i1-1d3cbcae-208d-411c-9324-236823aaaf35/sportswear-portland-mens-t-shirt-GmCl2p.png",
    ],
    price: 850,
    discount: 10,
    designedBy: "Soumyadeep Pal",
    fabricType: "Cotton",
    fabricDescription: "100% Super Combed Egyptian Cotton",
    washType: "Machine",
  },
  {
    _id: "121",
    title: "Bengali Tshirt",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Bengali",
    images: [
      "https://5.imimg.com/data5/SV/XT/MY-55472971/black-t-shirt-500x500.png",
    ],
    price: 400,
    discount: 21,
    designedBy: "Nihal Sharma",
    fabricType: "Woolen",
    fabricDescription: "100% Super Combed German Woolen",
    washType: "Dry",
  },
  {
    _id: "122",
    title: "Assamese Click",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Assamese",
    price: 350,
    images: [
      "https://vanheusenindia.imgix.net/img/app/product/3/313891-1442639.jpg?auto=format&w=618",
      "https://vanheusenindia.imgix.net/img/app/product/6/622612-6012626.jpg?auto=format&w=618",
    ],
    discount: 10,
    designedBy: "Rohan Rahaman",
    fabricType: "Silk",
    fabricDescription: "100% Super Combed French Silk",
    washType: "Machine",
  },
  {
    _id: "123",
    title: "Marathi Pun",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Marathi",
    images: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjaigcOE0eGXwsIEmt7mo8xxnmud6NnRU7Qw&usqp=CAU",
    ],
    price: 850,
    discount: 10,
    designedBy: "Saikat Datta",
    fabricType: "Nylon",
    fabricDescription: "100% Super Combed Indonesian Nylon",
    washType: "Dry",
  },
  {
    _id: "124",
    title: "Bengali Tshirt",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Bengali",
    images: [
      "https://cdn.fashiola.in/L58982107/polo-ralph-lauren-retro-sport-capsule-logo-t-shirt-custom-regular-fit-in.jpg",
    ],
    price: 400,
    discount: 21,
    designedBy: "Nihal Sharma",
    fabricType: "Cotton",
    fabricDescription: "100% Super Combed German Cotton",
    washType: "Dry",
  },
  {
    _id: "125",
    title: "Assamese Click",
    description:
      "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
    punDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
    contentLanguage: "Assamese",
    images: [
      "https://www.kidinn.com/f/13762/137627495/reebok-t-shirt-short-sleeve-t-shirt.jpg",
    ],
    price: 350,
    discount: 10,
    designedBy: "Rohan Rahaman",
    fabricType: "Woolen",
    fabricDescription: "100% Super Combed Indian Woolen",
    washType: "Dry",
  },
];

export const PRODUCT_DETAIL_DATA = {
  title: "Marathi Pun",
  description:
    "Blue Denim Casual T - Shirt; Blue solid opaque Casual shirt ,has a spread collar, button placket, 2 flap pocket, long regular sleeves, curved hem",
  punDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim adminim veniam, quis no strud exercit.",
  rating: 4,
  ratingsCount: 50,
  images: [
    require("./assets/3.png"),
    require("./assets/1.png"),
    require("./assets/5.png"),
  ],
  price: 850,
  discountedPrice: 800,
  discount: 41,
  fabricType: "Cotton",
  fabricDescription: "100% Super Combed Egyptian Cotton",
  washType: "Machine Wash",
  designedBy: "Soumyadeep Pal",
  size: "Regular Fit",
};
