import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./index.css";
import {
  AutoCompleteInput,
  TextInputField,
  TextAreaInputField,
  AddImagesWithPreview,
  Button,
  Loading,
  Snackbar,
} from "components";
import { LANGUAGE_OPTIONS } from "constants";
import { UserContext } from "contexts";
import { MemeSuggestionServices } from "services";
import { useSnackbar } from "hooks";

const MemeSuggestionForm = ({ onCloseDrawer }) => {
  const [images, setImages] = useState([]);
  const { currentUser } = useContext(UserContext);
  const snackbar = useSnackbar();
  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        language: "",
      }}
      enableReinitialize
      isInitialValid={true}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Meme Suggestion title is mandatory"),
        description: Yup.string().required(
          "Meme Suggestion description is mandatory"
        ),
        language: Yup.string().required("Language is Mandatory"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        const payload = {
          ...values,
          images,
          customerId: currentUser?._id,
        };
        setSubmitting(true);
        const { msg } = await MemeSuggestionServices.createUpdateMemeSuggestion(
          payload
        );
        setSubmitting(false);
        snackbar.showMessage({
          content: msg,
        });
        setTimeout(() => onCloseDrawer && onCloseDrawer(), 2000);
      }}
    >
      {(formikProps) => {
        return (
          <>
            {formikProps.isSubmitting && <Loading />}
            <Snackbar {...snackbar} />
            <div className="div__memeSuggestionForm-container">
              <p className="formHeader">Meme Suggestion Form</p>
              <div className="div__memeSuggestionForm-formContainer">
                <div className="div__memeSuggestionForm-formContainer-details">
                  <div className="div__memeSuggestionForm-formContainer-primary">
                    <TextInputField
                      name="title"
                      inputType="text"
                      labelText="Name your meme"
                      inputValue={formikProps.values.title}
                      handleChange={formikProps.handleChange}
                      handleBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.title && formikProps.errors.title
                      }
                    />
                    <AutoCompleteInput
                      name="language"
                      className="autoCompleteLanguage"
                      optionsList={LANGUAGE_OPTIONS}
                      labelText="Language"
                      placeholder="Type language here"
                      value={formikProps.values.language}
                      handleChange={(_, val) =>
                        formikProps.setFieldValue("language", val || "")
                      }
                      handleBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.language &&
                        formikProps.errors.language
                      }
                    />
                  </div>
                  <TextAreaInputField
                    name="description"
                    className="txtMemeDescription"
                    inputType="text"
                    minRows={6}
                    labelText="A short description will help:"
                    inputValue={formikProps.values.description}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.description &&
                      formikProps.errors.description
                    }
                  />
                  <Button
                    id="btnInput"
                    size="large"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                  >
                    Suggest Meme
                  </Button>
                </div>
                <div className="div__memeSuggestionForm-formContainer-imagesUpload">
                  <AddImagesWithPreview
                    htmlFor="addMemeSuggestionImage"
                    labelInput="Add image references"
                    imagesList={images}
                    onImageUpload={(imgList) => setImages(imgList)}
                    handleRemoveImage={(imageURL) =>
                      setImages((prevList) =>
                        prevList.filter((imgURL) => imgURL !== imageURL)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default MemeSuggestionForm;
