export const TICKET_PRIORITY_OPTIONS = [
    'Critical',
    'High',
    'Medium',
    'Low',
];

export const TICKET_STATUS_OPTIONS = [
    'Created',
    'InProgress',
    'Resolved',
];

export const TICKET_CATEGORY_OPTIONS = [
    'Technical',
    'Product',
    'Refund',
    'Order'
];

export const TICKET_TABS_LIST = [
    { label: "Active Tickets", ticketStatus: "Created" },
    { label: "In Progress Tickets", ticketStatus: "InProgress" },
    { label: "Resolved Tickets", ticketStatus: "Resolved" },
];