import React from "react";
import { CircularProgress } from "@mui/material";
import "./index.css";

const Table = ({ config, dataList, loading }) => {
  const {
    columnHeaders,
    DisplayDataRow,
    onColumnSort,
    dataUnavailableMessage,
  } = config || {};

  const tableBody = dataList.length ? (
    dataList?.map((record, index) => DisplayDataRow({ record, index }))
  ) : (
    <tr>
      <td className="noDataRow" colSpan={columnHeaders.length}>
        {dataUnavailableMessage || `No data to display`}
      </td>
    </tr>
  );

  return (
    <div className="div__staticTableList-container">
      <table className="listStaticTable">
        <thead>
          <tr className="headerRow">
            {columnHeaders.map(({ label, value }) => (
              <th
                key={label}
                className="headerColumn"
                onClick={() => onColumnSort(value)}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr className="dataRow">
              <td colSpan={columnHeaders.length}>
                <CircularProgress className="loadingProgress" />
              </td>
            </tr>
          ) : (
            tableBody
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
