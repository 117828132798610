import React from "react";
import { IconButton } from "@mui/material";
import { Image } from "components";
import { GENDER_OPTIONS } from "constants";
import "./index.css";

const GenderSelector = ({ availableGenders, selectedGender, onSelect }) => {
  return (
    <div className="div__selectGender-container">
      <p className="lblSelectGender">Select Gender</p>
      <div className="div__selectGender-options">
        {GENDER_OPTIONS.map(({ value, image }) => (
          <IconButton
            disableRipple
            disabled={!availableGenders.includes(value)}
            onClick={() => onSelect(value)}
          >
            <div className="div__selectGender-option">
              <Image
                imageURL={image}
                className={`genderOption ${
                  selectedGender === value ? "genderOptionSelected" : ""
                }`}
              />
              <p
                className={`lblGender ${
                  selectedGender === value ? "lblGenderSelected" : ""
                } `}
              >
                {value}
              </p>
            </div>
          </IconButton>
        ))}
      </div>
    </div>
  );
};

export default GenderSelector;
