import React from "react";
import { CircularProgress, Button } from "@mui/material";

const LoadingButton = ({ loading, buttonText, className, handleClick }) => {
  return loading ? (
    <CircularProgress className="loadingProgress" />
  ) : (
    <Button
      id="btnInput"
      size="large"
      className={className}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};

export default LoadingButton;
