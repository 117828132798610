import { useState } from "react";
import { Collapse, TableCell, TableRow } from "@mui/material";
import { IconDisplay } from "components";
import { displayDateInFormat, displayCurrencyAmount } from "utils";
import TABLE_DISPLAY_CONFIG from "./TableDisplayConfig";

const displayColumnValue = ({ type, value, actionIcon }) => {
  switch (type) {
    case "string":
    case "number":
      return value;
    case "decimal":
      return +(Math.round(value + "e+2") + "e-2");
    case "date":
      return value ? displayDateInFormat(new Date(value), "Do MMM YYYY") : "";
    case "dateTime":
      return value
        ? displayDateInFormat(new Date(value), "Do MMM YYYY HH:mm")
        : "";
    case "currency":
      return displayCurrencyAmount(value);
    case "percent":
      return `${value}%`;
    case "downloadLink":
      return (
        value && (
          <a href={value} target="_blank" rel="noreferrer" download>
            <IconDisplay type="download" className="listIcon" />
          </a>
        )
      );
    case "action":
    case "subAction":
      return actionIcon;
    default:
      return ``;
  }
};

const getTableDisplayConfig = (
  screenName,
  actions,
  collapsibleContentJSX = () => <></>
) => {
  const configFound = TABLE_DISPLAY_CONFIG[screenName];
  return {
    ...configFound,
    DisplayDataRow: ({ record, index }) => {
      const [expanded, setExpanded] = useState(false);
      const recordFetched = configFound.columnHeaders.map(({ key, type }) => {
        if (key === "index") {
          return {
            value: index + 1,
            type,
          };
        } else if (key === "actions" || key === "subActions") {
          return {
            actionIcon: actions[key](record),
            value: record,
            type,
          };
        } else {
          return {
            value: record[key],
            type,
          };
        }
      });
      return (
        <>
          <TableRow
            onClick={() => setExpanded(!expanded)}
            className="dataRow"
            key={record?._id}
          >
            {recordFetched.map((dataValue, colIndex) => (
              <TableCell key={colIndex}>
                {displayColumnValue(dataValue)}
              </TableCell>
            ))}
          </TableRow>
          {configFound?.collapsibleRow && (
            <TableRow key={record?._id + "-detail"}>
              <TableCell
                className="dataRowExpanded"
                colSpan={configFound.columnHeaders.length}
              >
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {collapsibleContentJSX(record)}
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </>
      );
    },
  };
};

export default getTableDisplayConfig;
