import React from "react";
import { Rating } from "@mui/material";

const CustomRating = ({ rating, customIcon, size, onRatingChange }) => (
  <Rating
    value={rating}
    onChange={(ev, newValue) => onRatingChange(newValue)}
    IconContainerComponent={customIcon}
    size={size}
  />
);

export default CustomRating;
