import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Snackbar,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "hooks";
import { generateQueryParamsFromObject } from "utils";
import "./index.css";

const PaginatedTable = ({ config, loading }) => {
  const snackbar = useSnackbar;
  const [pageNum, setPageNumber] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [pageLimit, setPageSize] = useState(10);

  const {
    columnHeaders,
    DisplayDataRow,
    onColumnSort,
    fetchListData,
    dataListKey,
    dataUnavailableMessage,
  } = config || {};

  const handlePageChange = (_ev, newPage) => setPageNumber(newPage);

  const handlePageSizeChange = ({ target: { value } }) =>
    setPageSize(parseInt(value));

  const fetchPageData = async () => {
    try {
      const query = generateQueryParamsFromObject({ pageNum, pageLimit });
      const data = await fetchListData(query);
      setPageData(data[dataListKey]);
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
    }
  };

  useEffect(() => {
    fetchPageData();
  }, [pageLimit, pageNum]);

  const tableBody = !_.isEmpty(pageData) ? (
    pageData?.map((data, index) => (
      <DisplayDataRow record={data} index={index} />
    ))
  ) : (
    <TableRow>
      <TableCell className="noDataRow" colSpan={columnHeaders.length}>
        {dataUnavailableMessage || `No data to display`}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Snackbar {...snackbar} />
      <TableContainer className="div__tableList-container">
        <Table className="listTable">
          <TableHead>
            <TableRow className="headerRow">
              {_.map(columnHeaders, ({ label, value }) => (
                <TableCell
                  key={label}
                  className="headerColumn"
                  onClick={() => onColumnSort(value)}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="dataRow">
                <TableCell colSpan={columnHeaders?.length || 0}>
                  <CircularProgress className="loadingProgress" />
                </TableCell>
              </TableRow>
            ) : (
              tableBody
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10, 25]}
        component="div"
        count={pageData?.length || 0}
        rowsPerPage={pageLimit}
        page={pageNum}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </>
  );
};

export default PaginatedTable;
