import React, { useContext, useEffect, useState } from "react";
import {
  TabDisplay,
  Button,
  CustomDrawer,
  Loading,
} from "components";
import { getTabDisplayConfig } from "displayConfigs";
import { TicketServices } from "services";
import { UserContext } from "contexts";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import { AddTicketForm } from "../index";

const MyTicketsPage = () => {
  const { currentUser } = useContext(UserContext);
  const [tabConfig, setTabConfig] = useState({
    renderTabsList: [],
  });
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    fetchTicketsList();
  }, []);

  const fetchTicketsList = async () => {
    try {
      setLoading(true)
      const { ticketsList } = await TicketServices.getTicketsList(
        `customerId=${currentUser?._id}`
      );
      setTabConfig(getTabDisplayConfig("Tickets", ticketsList));
      setLoading(false);
    } catch (err) {
      console.error(err)
    }
  };

  const onCloseDrawer = async () => {
    setIsDrawerOpen(false);
    await fetchTicketsList();
  };

  return (
    <>
      {loading && <Loading />}
      <div className="myTickets__container">
        <div className="myTickets__header">
          {tabConfig.screenName}
          <Button
            id="btnInput"
            className="ticket_detail-addTicket"
            onClick={() => setIsDrawerOpen(true)}
          >
            RAISE TICKET
          </Button>
        </div>
        <TabDisplay config={tabConfig} />
        <CustomDrawer
          direction="bottom"
          className="myTickets__ticketDrawer--add"
          isOpen={isDrawerOpen}
          onCloseDrawer={() => setIsDrawerOpen(false)}
        >
          <AddTicketForm onCloseDrawer={onCloseDrawer} />
        </CustomDrawer>
      </div>
    </>
  );
};

export default MyTicketsPage;
