import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import "./index.css";

const CheckboxInput = ({ isChecked, handleChange, labelText }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={handleChange} />}
        label={labelText}
      />
    </FormGroup>
  );
};

export default CheckboxInput;
