import React from "react";
import { Menu, MenuItem, Divider } from "@mui/material";

const CustomMenu = ({
  anchor,
  id,
  anchorId,
  header,
  optionsList,
  renderOption,
  handleClose,
}) => (
  <Menu
    id={id}
    anchorEl={anchor}
    open={Boolean(anchor)}
    onClose={handleClose}
    MenuListProps={{
      "aria-labelledby": anchorId,
    }}
  >
    {header}
    {optionsList?.map(({ value, hasDivider, ...otherProps }) => (
      <div key={value}>
        <MenuItem onClick={() => handleClose(value)}>
          {renderOption({...otherProps})}
        </MenuItem>
        {hasDivider && <Divider />}
      </div>
    ))}
  </Menu>
);

export default CustomMenu;
