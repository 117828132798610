import React from "react";
import { Button, DetailCard, Radio } from "components";
import "./index.css";

const AddressItem = ({
  address,
  isSelected,
  onSelectAddress,
  onEditAddress,
  onDeleteAddress,
}) => {
  const {
    _id,
    isLastUsed,
    recipientName,
    contact,
    firstLine,
    secondLine,
    city,
    state,
    zipCode,
  } = address;
  return (
    <DetailCard
      key={_id}
      className="manageAddress__list-item"
      headerContent={isLastUsed && "LAST USED"}
      cardContent={
        <div className="manageAddress__list-item-content">
          <Radio
            checked={isSelected}
            onChange={() => onSelectAddress(_id)}
            value={_id}
            className="manageAddress__list-item--radio"
          />
          <div className="manageAddress__list-item-content-details">
            <p className="manageAddress__list-item-recipientName">
              {recipientName}
            </p>
            <p className="manageAddress__list-item-recipientAddress">
              {`${firstLine || ""}, ${secondLine || ""}, ${
                city?.toUpperCase() || ""
              }, ${state?.toUpperCase() || ""}, ${zipCode || ""}`}
            </p>
            <p className="manageAddress__list-item-recipientContact">
              Phone Number: {contact}
            </p>
          </div>
          <div className="manageAddress__list-item-content-actions">
            <Button
              id="btnInput"
              className="manageAddress__list-item-editAddress"
              disableRipple
              onClick={() => onEditAddress(address)}
            >
              EDIT
            </Button>
            <Button
              id="btnInput"
              className="manageAddress__list-item-removeAddress"
              disableRipple
              onClick={() => onDeleteAddress(address?._id)}
            >
              REMOVE
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default AddressItem;
