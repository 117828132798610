import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  DetailCard,
  IconDisplay,
  CustomDrawer,
  CircularProgress,
  EmptyListPlaceholder,
} from "components";
import { VendorServices } from "services";
import { generateQueryParamsFromObject } from "utils";
import EmptyDispatchOrdersImage from "assets/Vendor/EmptyDispatchOrders.png";
import VendorOrderCard from "./VendorOrderCard";
import OrderDetail from "pages/Orders/MyOrders/MyOrdersList/OrderDetail";
import "./index.css";

const DispatchedOrders = ({ vendorId }) => {
  const [loading, setLoading] = useState(false);
  const [dispatchOrdersList, setDispatchOrdersList] = useState([]);

  useEffect(() => {
    fetchDispatchOrders();
  }, []);

  const fetchDispatchOrders = async () => {
    try {
      setLoading(true);
      const query = generateQueryParamsFromObject({
        vendorId,
        pageNum: 0,
        pageLimit: 5,
      });
      const { ordersList } = await VendorServices.fetchDispatchOrdersList(
        query
      );
      setDispatchOrdersList(ordersList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDispatchOrdersList([]);
    }
  };

  const [selectedOrder, setSelectedOrder] = useState();
  return (
    <>
      <DetailCard
        className="vendorDashboard__gridContainer--dispatchOrders"
        headerClass="vendorDashboard__gridContainer--dispatchOrders-header"
        headerContent={
          <>
            <span>
              <IconDisplay
                type="inventory"
                className="vendorDashboard__gridContainer--dispatchOrders-icon"
              />{" "}
              Dispatch Orders
            </span>
            {!_.isEmpty(dispatchOrdersList) && (
              <Link
                to={`/orders`}
                className="vendorDashboard__gridContainer--dispatchOrders-header-viewAllOrders"
              >
                View All Orders
              </Link>
            )}
          </>
        }
        contentClass="vendorDashboard__gridContainer--dispatchOrders-content"
        cardContent={
          <>
            {loading && <CircularProgress className="loadingProgress" />}
            {!loading && _.isEmpty(dispatchOrdersList) && (
              <EmptyListPlaceholder
                placeholderImage={EmptyDispatchOrdersImage}
                imageClass="vendorDashboard__gridContainer--dispatchOrders-imgEmptyList"
                message={`You are viewed by our consumers, you will receive a new order soon.`}
              />
            )}
            {!loading &&
              dispatchOrdersList?.map((orderDetail) => (
                <VendorOrderCard
                  orderDetail={orderDetail}
                  onViewDetail={() => setSelectedOrder(orderDetail)}
                />
              ))}
          </>
        }
      />
      <CustomDrawer
        direction="bottom"
        className="vendorDashboard__gridContainer--dispatchOrders-drawer"
        isOpen={!_.isUndefined(selectedOrder)}
        onCloseDrawer={() => setSelectedOrder(undefined)}
      >
        {selectedOrder && (
          <OrderDetail
            orderDetail={selectedOrder}
            isAdmin
            onCloseDrawer={() => setSelectedOrder(undefined)}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default DispatchedOrders;
