import React, { useState } from "react";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import { Button, Image, CustomDrawer } from "components";
import { AddMemeSuggestionForm } from "pages";
import NoSearchImage from "assets/undraw_not_found_-60-pq.png";

const NoSearchResults = () => {
  const [showMemeSuggestionForm, setShowMemeSuggestionForm] = useState(false);
  return (
    <div className="div__noSearchResults">
      <Image
        className="imgNoSearchResults"
        imageURL={NoSearchImage}
        alt="No Search Results"
      />
      <p className="lblSorry">Sorry!</p>
      <p className="lblSorryMessage">
        It seems that we do not have the content of the language you are looking
        for.
      </p>
      <p className="lblRecommendUs">Help us by suggesting memes.</p>
      <Button
        id="btnInput"
        size="large"
        className="btnSuggestMemes"
        onClick={() => setShowMemeSuggestionForm(!showMemeSuggestionForm)}
      >
        {showMemeSuggestionForm ? "Maybe Another Time!" : "Suggest Memes"}
      </Button>
      <CustomDrawer
        direction="bottom"
        className="addMemeSuggestionDrawer"
        isOpen={showMemeSuggestionForm}
        onCloseDrawer={() => setShowMemeSuggestionForm(false)}
      >
        <AddMemeSuggestionForm
          onCloseDrawer={() => setShowMemeSuggestionForm(false)}
        />
      </CustomDrawer>
    </div>
  );
};

export default NoSearchResults;
