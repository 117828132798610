import React, { useState } from "react";
import freechargeLogo from "assets/freecharge-logo.png";
import payzappLogo from "assets/payzapp.png";
import amazonpayLogo from "assets/amazonpay.png";
import phonepeLogo from "assets/ppay.png";
import { Image, Button, DropdownInput } from "components";
import "./index.css";

const OtherWallet = ({ paymentAmount, onPayment }) => {
  const [selectedWallet, setSelectedWallet] = useState(
    `Select from other Wallet`
  );

  const handleWalletPayment = () =>
    onPayment({
      method: "wallet",
      wallet: selectedWallet,
    });

  const WALLET_OPTIONS = [
    { label: "Free Charge", value: "freecharge", image: freechargeLogo },
    { label: "Amazon Pay", value: "amazonpay", image: amazonpayLogo },
    { label: "Phone Pe", value: "phonepe", image: phonepeLogo },
    { label: "Payzapp", value: "payzapp", image: payzappLogo },
    { label: "Ola Money", value: "olamoney" },
    { label: "Airtel Money", value: "airtelmoney" },
    { label: "Mobikwik", value: "mobikwik" },
    { label: "Jio Money", value: "jiomoney" },
  ];

  return (
    <div className="OtherWallet__form">
      <div className="netBanking__form--topWallets">
        {WALLET_OPTIONS?.slice(0, 4).map(({ label, value, image }) => (
          <div key={value} className="walletCard">
            <div onClick={() => setSelectedWallet(value)} className={label}>
              <Image imageURL={image} className={`${value}Logo`} />
            </div>
          </div>
        ))}
      </div>

      <div className="otherWallet__dropdown">
        <DropdownInput
          className="otherWallet__dropdown_input"
          placeholder="Select from other Wallet"
          optionsList={WALLET_OPTIONS.slice(4, WALLET_OPTIONS.length)}
          selectedOption={selectedWallet}
          onSelect={({ target: { value } }) => setSelectedWallet(value)}
        />
      </div>

      <Button onClick={handleWalletPayment} id="button_checkoutPayment">
        Pay {paymentAmount}
      </Button>
    </div>
  );
};

export default OtherWallet;
