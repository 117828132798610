import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, Snackbar } from "components";
import { OrderServices } from "services";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { generateOrderDataFromCart, displayCurrencyAmount } from "utils";
import MainLayout from "./MainLayout";
import "./index.css";

const razorpay = new window.Razorpay({
  key: process.env.REACT_APP_RAZORPAY_KEY,
});

const CheckOut = () => {
  const { currentUser, modifyCurrentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { _id, name, email, phoneNumber: contact, cart } = currentUser;

  const { products, totalPrice, totalDiscount } =
    generateOrderDataFromCart(cart);
  const paymentAmount = totalPrice - totalDiscount + 40;

  const onPaymentComplete = (_payResp) => {
    snackbar.showMessage({
      content: `Your payment has been processed successfully`,
      timeout: 5000,
      handleClose: () => {
        modifyCurrentUser({ ...currentUser, cart: [] });
        navigate(`/myOrders`);
      },
    });
  };

  const onPaymentFailure = (_resp) => {
    snackbar.showMessage({
      content: `Payment failed as ${_resp?.error?.description}`,
    });
    console.error(`Payment Failure`, _resp?.error?.description);
  };

  const makeRazorpayPayment = async (paymentData) => {
    setLoading(true);
    try {
      const payload = {
        amount: paymentAmount,
        currency: "INR",
      };

      const {
        orderData: { receipt, razorpayOrderId, amount },
      } = await OrderServices.createUpdateOrder(payload);
      setLoading(false);

      const data = {
        amount: (amount * 100).toString(),
        currency: "INR",
        email,
        contact,
        description: `${name}'s Meme-T Order ${receipt}`,
        notes: {
          products: JSON.stringify(products),
          customerId: _id,
          receipt,
        },
        order_id: razorpayOrderId,
        ...paymentData,
      };

      razorpay.createPayment(data);
      razorpay.on("payment.success", onPaymentComplete); // will pass payment ID, order ID, and Razorpay signature to success handler.

      razorpay.on("payment.error", onPaymentFailure); // will pass error object to error handler
    } catch (err) {
      setLoading(false);
      console.log(err);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="checkOut">
        <MainLayout
          customerName={name}
          paymentAmount={`${displayCurrencyAmount(paymentAmount)}/-` || ""}
          onPayment={makeRazorpayPayment}
        />
      </div>
    </>
  );
};

export default CheckOut;
