import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

Axios.interceptors.request.use(({ headers, ...otherVals }) => {
  headers.authorization = localStorage.getItem("_t");

  return { headers, ...otherVals };
});

export default Axios;
