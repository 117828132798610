import React from "react";
import "./index.css";

const SizeChartSwitch = ({ isChecked, onToggle, activeLabel, inActiveLabel }) => {
  return (
    <div className="toggle-switch" onClick={onToggle}>
      <input
        type="checkbox"
        className="checkbox"
        checked={isChecked}
        onChange={onToggle}
      />
      <label className="label">
        <span
          className="inner"
          inactivelabel={inActiveLabel}
          activelabel={activeLabel}
        />
        <span className="switch">
          {isChecked ? activeLabel : inActiveLabel}
        </span>
      </label>
    </div>
  );
};

export default SizeChartSwitch;
