import React from "react";
import { Avatar } from "@mui/material";
import { RowDetail, Button } from "components";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";

const VendorOrderCard = ({ orderDetail, onViewDetail }) => {
  const { customerName, customerProfilePic, createdAt } = orderDetail;

  return (
    <div className="vendorOrderCard">
      <div className="vendorOrderCard__left">
        <Avatar
          alt={customerName}
          src={displayDriveImage(customerProfilePic)}
          className="vendorOrderCard__left-image"
        />
        <div className="vendorOrderCard__left-detail">
          <RowDetail
            description={`Order By : ${customerName}`}
            className="vendorOrderCard__detail-orderedBy"
          />
          <RowDetail
            description={`${displayDateInFormat(
              createdAt,
              "Do MMMM YYYY"
            )} ${displayDateInFormat(createdAt, "HH:mm")}`}
            className="vendorOrderCard__detail-orderedAt"
          />
        </div>
      </div>
      <Button
        id="btnInput"
        className="vendorOrderCard__detail-viewOrderDetail"
        onClick={onViewDetail}
      >
        View Details
      </Button>
    </div>
  );
};

export default VendorOrderCard;
