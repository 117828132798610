import React from "react";
import {
  Add,
  LocationOn,
  Favorite,
  FavoriteBorder,
  Delete,
  PlayCircleFilledOutlined,
  ShoppingCart,
  ShoppingBag,
  Payments,
  Menu,
  Upload,
  Checkroom,
  ClearOutlined,
  Mail,
  LocalShipping,
  Inventory,
  Phone,
  Edit,
  Download,
  Visibility,
  Person,
  Lock,
  Email,
  ChatBubble,
  FilterAlt,
  Sort,
  Search,
  ConfirmationNumber,
  Logout,
  Translate,
  EmojiEmotions,
  CheckCircle,
  CameraAlt,
  Send,
  Facebook,
  Instagram,
  Twitter,
  Dashboard,
  AutoGraph,
  ArrowUpward,
  ArrowDownward,
  ArrowCircleUp,
  ArrowCircleDown,
  AccountBalanceWallet,
  DateRange,
  KeyboardArrowUp,
} from "@mui/icons-material";

const IconDisplay = ({ type, ...props }) => {
  switch (type) {
    case "add":
      return <Add {...props} />
    case "phone":
      return <Phone {...props} />;
    case "email":
      return <Email {...props} />;
    case "location":
      return <LocationOn {...props} />;
    case "person":
      return <Person {...props} />;
    case "favorite":
      return <Favorite {...props} />;
    case "lock":
      return <Lock {...props} />;
    case "favoriteBorder":
      return <FavoriteBorder {...props} />;
    case "view":
      return <Visibility {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "download":
      return <Download {...props} />;
    case "delete":
      return <Delete {...props} />;
    case "playCircle":
      return <PlayCircleFilledOutlined {...props} />;
    case "shoppingCart":
      return <ShoppingCart {...props} />;
    case "shoppingBag":
      return <ShoppingBag {...props} />;
    case "menu":
      return <Menu {...props} />;
    case "upload":
      return <Upload {...props} />;
    case "checkroom":
      return <Checkroom {...props} />;
    case "language":
      return <Translate {...props} />;
    case "inventory":
      return <Inventory {...props} />;
    case "clear":
      return <ClearOutlined {...props} />;
    case "mail":
      return <Mail {...props} />;
    case "localShipping":
      return <LocalShipping {...props} />;
    case "chat":
      return <ChatBubble {...props} />;
    case "filter":
      return <FilterAlt {...props} />;
    case "sort":
      return <Sort {...props} />;
    case "search":
      return <Search {...props} />;
    case "ticket":
      return <ConfirmationNumber {...props} />;
    case "meme":
      return <EmojiEmotions {...props} />;
    case "logout":
      return <Logout {...props} />;
    case "cash":
      return <Payments {...props} />;
    case "completed":
      return <CheckCircle {...props} />;
    case "camera":
      return <CameraAlt {...props} />;
    case "send":
      return <Send {...props} />;
    case "facebook":
      return <Facebook {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "dashboard":
      return <Dashboard {...props} />;
    case "infographic":
      return <AutoGraph {...props} />;
    case "creditTransaction":
      return <ArrowCircleDown {...props} />;
    case "debitTransaction":
      return <ArrowCircleUp {...props} />;
    case "increment":
      return <ArrowUpward {...props} />;
    case "decrement":
      return <ArrowDownward {...props} />;
    case "wallet":
      return <AccountBalanceWallet {...props} />;
    case "calendar":
      return <DateRange {...props} />;
    case "arrowUp":
      return <KeyboardArrowUp {...props} />;
    default:
      return null;
  }
};

export default IconDisplay;
