import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  FileUpload,
  Button,
  Image,
  Loading,
  Snackbar,
  AutoCompleteInput,
  AutoCompleteTagsInput,
} from "components";
import { generateInputFields, displayFileUrl } from "utils";
import {
  PRIMARY_INPUTS,
  SECONDARY_INPUTS,
  ADDRESS_LINE_INPUTS,
  ADDRESS_LOCATION_INPUTS,
  FILE_TYPES,
  LANGUAGE_OPTIONS,
  PASSWORD_VALIDATION_FORMATS,
  STATE_CODES,
} from "constants";
import { useSnackbar } from "hooks";
import { MemeTRegister, AddProfilePic } from "./images";
import { UserServices } from "services";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const { IMAGE_JPG, IMAGE_JPEG, IMAGE_PNG } = FILE_TYPES;

const RegisterPage = ({ selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [initialFormValues, setInitialFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    firstLine: "",
    secondLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [profilePic, setProfilePic] = useState();

  useEffect(() => {
    const { name, email, phoneNumber, motherTongue, knownLanguages } =
      selectedUser || {};
    const { firstLine, secondLine, city, state, zipCode } =
      selectedUser?.addresses?.find((address) => address?.isDefaultAddress) ||
      {};

    const intitialData = {
      name: name || "",
      email: email || "",
      phoneNumber: phoneNumber || "",
      password: "",
      firstLine: firstLine || "",
      secondLine: secondLine || "",
      city: city || "",
      state: state || "",
      zipCode: zipCode || "",
      motherTongue: motherTongue || "",
      knownLanguages: knownLanguages || [],
    };

    setInitialFormValues(intitialData);

    return () => {
      setInitialFormValues({});
    };
  }, [selectedUser]);

  const handleAutocompleteInputChange = (formikProps, key, val) => {
    formikProps.setFieldValue(key, val);
    if (_.isEmpty(formikProps.values[key])) {
      formikProps.setErrors({
        ...formikProps.errors,
        [key]: true,
      });
    }
  };

  const profileImage = !_.isUndefined(profilePic)
    ? displayFileUrl(profilePic)
    : AddProfilePic;

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is mandatory"),
        email: Yup.string()
          .email("Email is invalid")
          .required("Email is mandatory"),
        phoneNumber: Yup.string()
          .min(10, "Should contain exactly 10 digits")
          .max(10, "Should contain exactly 10 digits")
          .required("Phone No. is mandatory"),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is mandatory")
          .test(
            "password",
            "Password should contain atleast one capital letter",
            (val) => PASSWORD_VALIDATION_FORMATS.CAPITALS.test(val)
          )
          .test(
            "password",
            "Password should contain atleast one digit",
            (val) => PASSWORD_VALIDATION_FORMATS.NUMBERS.test(val)
          )
          .test(
            "password",
            `Password should contain atleast one of the special characters ${PASSWORD_VALIDATION_FORMATS.SPECIAL_CHARACTERS_TEXT}`,
            (val) => PASSWORD_VALIDATION_FORMATS.SPECIAL_CHARACTERS.test(val)
          ),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
        firstLine: Yup.string().required("Address Line 1 is mandatory"),
        secondLine: Yup.string(),
        state: Yup.string().required("State is mandatory"),
        city: Yup.string().required("City is mandatory"),
        zipCode: Yup.string()
          .min(6, "Should contain exactly 6 digits")
          .max(6, "Should contain exactly 6 digits")
          .required("PIN Code is mandatory"),
        motherTongue: Yup.string()
          .nullable()
          .required("Mother tongue is mandatory"),
        knownLanguages: Yup.array().required(
          "Atleast one known language should be submitted"
        ),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async ({
        name,
        email,
        phoneNumber,
        password,
        firstLine,
        secondLine,
        city,
        state,
        zipCode,
        motherTongue,
        knownLanguages,
      }) => {
        const payload = {
          name,
          email,
          phoneNumber,
          password,
          profilePic,
          address: {
            recipientName: name,
            contact: phoneNumber,
            firstLine,
            secondLine,
            city,
            state,
            stateCode: STATE_CODES[state],
            zipCode,
            isDefaultAddres: true,
          },
          motherTongue,
          knownLanguages,
        };
        const formData = new FormData();

        _.each(payload, (value, key) => {
          if (key === "address") {
            for (const addressKey in payload["address"]) {
              formData.append(
                `address[${addressKey}]`,
                payload["address"][addressKey]
              );
            }
          } else {
            formData.append(key, value);
          }
        });
        setLoading(true);
        try {
          const { msg } = await UserServices.createUpdateUser(
            `create`,
            formData
          );

          setLoading(false);
          snackbar.showMessage({
            content: msg,
            handleClose: () => navigate(`/login`),
          });
        } catch (err) {
          setLoading(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {(formikProps) => (
        <>
          {loading && <Loading />}
          <Snackbar {...snackbar} />
          <div className="div__register-container">
            <Link to="/" className="link__home">
              <Image
                className="img__registerLogo"
                imageURL={MemeTRegister}
                alt="Meme-T Logo"
              />
            </Link>
            <div className="div__register-formContainer">
              <div className="div__register-formContainer-details">
                <div className="div__register-formContainer-left">
                  <div className="div__register-registration">
                    <p className="lblSection">Create New Account</p>
                    <div className="div__register-signin">
                      <p>Already have an account?</p>
                      <a href="/login">Sign In</a>
                    </div>
                  </div>
                  {generateInputFields(formikProps, PRIMARY_INPUTS)}
                </div>
                <div className="div__register-formContainer-right">
                  <FileUpload
                    isSingle
                    htmlFor="uploadProfilePic"
                    acceptFileTypes={[IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG].join(
                      ", "
                    )}
                    onFileSelect={(resFile) => setProfilePic(resFile)}
                  >
                    <div className="div__uploadProfilePic">
                      <Image
                        id="uploadProfilePic"
                        className="div__uploadProfilePic-image"
                        imageURL={profileImage}
                        alt="profilePic"
                      />
                    </div>
                  </FileUpload>
                  {generateInputFields(formikProps, SECONDARY_INPUTS)}
                </div>
              </div>
              <p className="lblSection">Address</p>
              <div className="div__register-formContainer-address">
                <div className="div__register-formContainer-addressLine">
                  {generateInputFields(formikProps, ADDRESS_LINE_INPUTS)}
                </div>
                <div className="div__register-formContainer-addressLocation">
                  {generateInputFields(formikProps, ADDRESS_LOCATION_INPUTS)}
                  <AutoCompleteInput
                    name="state"
                    className="div__register-formContainer-addressLocation-state"
                    optionsList={_.keys(STATE_CODES)}
                    labelText="State"
                    placeholder="Type state here"
                    value={formikProps.values.state}
                    handleChange={(_ev, val) =>
                      handleAutocompleteInputChange(formikProps, "state", val)
                    }
                    handleBlur={() =>
                      formikProps.setTouched({
                        ...formikProps.touched,
                        state: true,
                      })
                    }
                    errorText={
                      formikProps.touched.state &&
                      formikProps.errors.state
                    }
                  />
                </div>
              </div>
              <div className="div__register-formContainer-userLanguage">
                <AutoCompleteInput
                  name="motherTongue"
                  className="autoCompleteMotherTongue"
                  optionsList={LANGUAGE_OPTIONS}
                  labelText="Mother Tongue"
                  placeholder="Type language here"
                  value={formikProps.values.motherTongue}
                  handleChange={(_ev, val) =>
                    handleAutocompleteInputChange(
                      formikProps,
                      "motherTongue",
                      val
                    )
                  }
                  handleBlur={() =>
                    formikProps.setTouched({
                      ...formikProps.touched,
                      motherTongue: true,
                    })
                  }
                  errorText={
                    formikProps.touched.motherTongue &&
                    formikProps.errors.motherTongue
                  }
                />
                <AutoCompleteTagsInput
                  name="knownLanguages"
                  dataList={LANGUAGE_OPTIONS}
                  className="txtAutoCompleteTags"
                  placeholder="Select known languages"
                  inputLabel="List Of Languages"
                  defaultValue={formikProps.values.knownLanguages}
                  handleChange={(_ev, val) =>
                    handleAutocompleteInputChange(
                      formikProps,
                      "knownLanguages",
                      val
                    )
                  }
                  handleBlur={() => {
                    formikProps.setTouched({
                      ...formikProps.touched,
                      knownLanguages: true,
                    });
                  }}
                  errorText={
                    formikProps.touched.knownLanguages &&
                    formikProps.errors.knownLanguages
                  }
                />
              </div>
              <Button
                id="btnInput"
                className="div__register-formContainer-submit"
                onClick={formikProps.handleSubmit}
              >
                Create New Account
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default RegisterPage;
