export const FABRIC_TYPE_OPTIONS = [
  "Select",
  "Cotton",
  "Silk",
  "Linen",
  "Rayon",
  "Woolen",
  "Polyester",
  "Blends"
];

export const WASH_OPTIONS = [
  "Select",
  "Normal",
  "Pigment",
  "Bleach",
  "Stone",
  "Acid",
  "Enzyme",
  "Sand Blasting",
  "Caustic",
];

export const COLOR_OPTIONS = [
  "Select",
  "Black",
  "Blue",
  "Red",
  "Yellow",
  "Green",
];
