import React, { useState, createContext } from "react";

export const VendorContext = createContext();

const VendorContextProvider = ({ children }) => {
  const [currentVendor, setCurrentVendor] = useState({
    onboardingStep: 0,
  });

  const setVendorOnboardingStep = (stepVal) =>
    setCurrentVendor({
      ...currentVendor,
      onboardingStep: stepVal,
    });

  return (
    <VendorContext.Provider
      value={{
        currentVendor,
        setCurrentVendor,
        setVendorOnboardingStep,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export default VendorContextProvider;
