import React from "react";
import _ from "lodash";
import { TextField, Autocomplete, Box, InputAdornment } from "@mui/material";
import { ErrorMessage } from "components";
import { COUNTRY_CODES_LIST } from "constants";
import "./index.css";

const displayCountryFlag = (countryCode) =>
  !_.isUndefined(countryCode) && (
    <img
      loading="lazy"
      src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
      alt="countryFlag"
      className="imgCountryFlag"
    />
  );

const AutocompleteCountryCode = ({
  inputLabel,
  inputValue,
  handleChange,
  errorText,
}) => (
  <div className="div__countryCodeAutoComplete">
    <p className="lblInput">{inputLabel}</p>
    <Autocomplete
      sx={{ width: 150, height: 40 }}
      options={COUNTRY_CODES_LIST}
      autoHighlight
      disableClearable
      value={inputValue.countryCodeVal}
      onChange={handleChange}
      getOptionLabel={({ countryCodeVal }) => `+${countryCodeVal}`}
      renderOption={(props, { countryCode, countryCodeVal }) => (
        <Box component="li" {...props}>
          <li>
            {displayCountryFlag(countryCode)} +{countryCodeVal}
          </li>
        </Box>
      )}
      renderInput={({ InputProps, inputProps, ...restProps }) => {
        const { startAdornment, ...restInputProps } = InputProps;
        const { value, autoComplete, ...restNativeInputProps } = inputProps;
        return (
          <TextField
            {...restProps}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {displayCountryFlag(inputValue?.countryCode)}
                </InputAdornment>
              ),
              ...restInputProps,
            }}
            inputProps={{
              ...restNativeInputProps,
              value: `+${inputValue?.countryCodeVal}` || `+91`,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
    <ErrorMessage errorText={errorText} />
  </div>
);

export default AutocompleteCountryCode;
