import React from "react";
import { Button, Image } from "components";
import PropTypes from "prop-types";
import "./index.css";

const EmptyListPlaceholder = ({
  placeholderImage,
  imageClass,
  message,
  subMessage,
  onButtonClick,
  buttonLabel,
}) => (
  <div className="gridContent__emptyList">
    <Image imageURL={placeholderImage} className={imageClass} />
    <p className="gridContent__emptyList-message">{message}</p>
    {subMessage && (
      <p className="gridContent__emptyList-subMessage">{subMessage}</p>
    )}
    {onButtonClick && (
      <Button
        id="btnInput"
        className="gridContent__emptyList-button"
        onClick={onButtonClick}
      >
        {buttonLabel}
      </Button>
    )}
  </div>
);

export default EmptyListPlaceholder;

EmptyListPlaceholder.propTypes = {
  placeholderImage: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonLabel: PropTypes.string,
};

EmptyListPlaceholder.defaultProps = {
  placeholderImage: "",
  imageClass: "",
  message: null,
  subMessage: null,
  onButtonClick: null,
  buttonLabel: null,
};
