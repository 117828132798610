import React, { useState, useEffect } from "react";
import { FILE_TYPES } from "constants";
import _ from "lodash";
import {
  FileUploader,
  ImagePreview,
  ShortImagePreview,
  CircularProgress,
  Snackbar,
} from "components";
import { useSnackbar } from "hooks";
import { displayDriveImage } from "utils";
import { ReactComponent as AddImageIcon } from "assets/add_photo_alternate_white_24dp.svg";
import "./index.css";

const { IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG } = FILE_TYPES;

const AddImagesWithPreview = ({
  imagesList,
  destinationFolderId,
  handleRemoveImage,
  onImageUpload,
  labelInput,
  htmlFor,
  previewDisabled,
}) => {
  const snackbar = useSnackbar();
  const [mainImage, setMainImage] = useState("");
  const [images, setImages] = useState(imagesList || []);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(imagesList)) {
      setImages(imagesList);
      setMainImage(imagesList[0]);
    }
  }, [imagesList]);

  const onFileUploadError = () =>
    snackbar.showMessage({
      content: `Error encountered during uploading images`,
    });

  const displayImagesGrid = () =>
    images.map((imageURL, index) => (
      <ShortImagePreview
        key={imageURL}
        imageURL={displayDriveImage(imageURL)}
        isMainImage={imageURL === mainImage}
        setAsMainImage={() => setMainImage(imageURL)}
        onRemoveImage={() =>
          setImages((prevList) => {
            const newList = prevList.filter((imgURL) => imgURL !== imageURL);
            if (imageURL === mainImage) {
              setMainImage(newList[index ? index - 1 : 0] || "");
            }
            handleRemoveImage(imageURL);
            return newList;
          })
        }
      />
    ));

  return (
    <>
      <Snackbar {...snackbar} />
      <div className="div__imagePreview-container">
        <p className="lblInput lblProductImages">{labelInput}</p>
        <div className="div__imagesUploaded">
          {imageLoading ? (
            <CircularProgress className="loadingProgress" />
          ) : (
            <>
              {displayImagesGrid()}
              <FileUploader
                htmlFor={htmlFor}
                acceptFileTypes={[IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG].join(", ")}
                onLoading={(loadVal) => {
                  setMainImage("");
                  setImageLoading(loadVal);
                }}
                destinationFolderId={destinationFolderId}
                onUploadSuccess={(resImages) => {
                  const newList = [...images, ...resImages];
                  onImageUpload(newList);
                  setImages(newList);
                  if (_.isEmpty(images)) {
                    setMainImage(newList[0]);
                  }
                }}
                onUploadError={onFileUploadError}
              >
                <div
                  id="addImageIcon"
                  className="div-uploadedImage div-uploadNew"
                >
                  <AddImageIcon />
                  <p>Add image</p>
                </div>
              </FileUploader>
            </>
          )}
        </div>

        {!previewDisabled && (
          <div className="div__imagePreview">
            <ImagePreview
              imageSource={displayDriveImage(mainImage)}
              className="imgDetailPreview"
              labelText="Product Detail Preview"
            />
            <ImagePreview
              imageSource={displayDriveImage(mainImage)}
              className="imgCardPreview"
              labelText="Product Card Preview"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AddImagesWithPreview;
