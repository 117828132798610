const TOP_LANGUAGES_DISPLAY_CONFIG = {
  screenName: "TopLanguages",
  containerSpacing: 0,
  rowDivisions: 2,
  renderComponent: (
    { imageURL, language, languageContent },
    { selectLanguageFilter }
  ) => (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(10, 5, 5, 0), rgba(6, 3, 3, 0.11) 42%, rgba(4, 2, 2, 0.4) 65%, #000), url(${imageURL})`,
      }}
      className="div__language-card imgOverlay"
      onClick={() => selectLanguageFilter(language)}
    >
      <p>{language}</p>
      <p>{languageContent}</p>
    </div>
  ),
  emptyDataContent: () => null,
};

export default TOP_LANGUAGES_DISPLAY_CONFIG;
