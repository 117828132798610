import CardPayment from "./CardPayment";
import NetBanking from "./NetBanking";
import OtherWallet from "./OtherWallet";
import PayUsingUPI from "./PayUsingUPI";
// import SavedPayments from "./SavedPayments";

const PAYMENT_METHODS = [
  // {
  //   label: "Saved Payments",
  //   component: (props) => <SavedPayments {...props} />,
  // },
  {
    label: "Credit/Debit Card",
    component: (props) => <CardPayment {...props} />,
  },
  {
    label: "Other Wallets",
    component: (props) => <OtherWallet {...props} />,
  },
  { label: "Net Banking", component: (props) => <NetBanking {...props} /> },
  {
    label: "Pay Using UPI",
    component: (props) => <PayUsingUPI {...props} />,
  },
];

export default PAYMENT_METHODS;
