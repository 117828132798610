import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const DetailCard = ({
  className,
  headerContent,
  headerClass,
  cardContent,
  contentClass,
  actionButtons,
}) => {
  return (
    <div className={`div__detail-item ${className}`}>
      {headerContent && (
        <div className={`div__detail-item-header ${headerClass || ""}`}>
          {headerContent}
        </div>
      )}
      <div className={`div__detail-item-content ${contentClass || ""}`}>
        {cardContent}
      </div>
      {actionButtons && (
        <>
          <div className="horizontalDivider" />
          <div className="div__detail-item-actions">{actionButtons}</div>
        </>
      )}
    </div>
  );
};

export default DetailCard;

DetailCard.propTypes = {
  headerContent: PropTypes.element,
  cardContent: PropTypes.element,
  actionButtons: PropTypes.element,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  contentClass: PropTypes.string,
};

DetailCard.defaultProps = {
  headerContent: null,
  cardContent: null,
  actionButtons: null,
  className: "",
  headerClass: "",
  contentClass: "",
};
