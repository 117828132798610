import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Button, Loading, Snackbar, TextInputField } from "components";
import { UserContext } from "contexts";
import { UserServices } from "services";
import { useSnackbar } from "hooks";
import "./index.css";

const AddressForm = ({ addressSelected, onModifySuccess }) => {
  const snackbar = useSnackbar();
  const { currentUser } = useContext(UserContext);

  return (
    <Formik
      initialValues={{ ...addressSelected }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        recipientName: Yup.string().required("Name is mandatory"),
        contact: Yup.string()
          .min(10, "Should contain exactly 10 digits")
          .max(10, "Should contain exactly 10 digits")
          .required("Phone No. is mandatory"),
        firstLine: Yup.string().required("Address is mandatory"),
        secondLine: Yup.string(),
        state: Yup.string().required("State is mandatory"),
        city: Yup.string().required("City is mandatory"),
        zipCode: Yup.string()
          .min(6, "Should contain exactly 6 digits")
          .max(6, "Should contain exactly 6 digits")
          .required("PIN Code is mandatory"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const payload = { ...values, customerId: currentUser?._id };
        if (addressSelected?._id) payload["_id"] = addressSelected?._id;
        setSubmitting(true);
        try {
          const { msg } = await UserServices.createUpdateAddress(payload);
          setSubmitting(false);
          snackbar.showMessage({
            content: msg,
          });
          resetForm({});
          onModifySuccess();
        } catch (err) {
          setSubmitting(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {(formikProps) => {
        const {
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          isSubmitting,
          resetForm
        } = formikProps;
        return (
          <>
            {isSubmitting && <Loading />}
            <Snackbar {...snackbar} />
            <div className="addressForm-container">
              <p className="addressForm-header">
                {_.isEmpty(addressSelected) ? "Add" : "Edit"} Address
              </p>
              <div className="addressForm-container-details">
                <TextInputField
                  name="recipientName"
                  inputType="text"
                  labelText="Recipient Name"
                  inputValue={values.recipientName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={touched.recipientName && errors.recipientName}
                />
                <TextInputField
                  name="firstLine"
                  inputType="text"
                  labelText="Address Line 1"
                  inputValue={values.firstLine}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={touched.firstLine && errors.firstLine}
                />
                <TextInputField
                  name="secondLine"
                  inputType="text"
                  labelText="Address Line 2"
                  inputValue={values.secondLine}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={touched.secondLine && errors.secondLine}
                />
                <div className="addressForm-container-detail-locality">
                  <TextInputField
                    name="state"
                    inputType="text"
                    labelText="State"
                    inputValue={values.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={touched.state && errors.state}
                  />
                  <TextInputField
                    name="city"
                    inputType="text"
                    labelText="City"
                    inputValue={values.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={touched.city && errors.city}
                  />
                </div>
                <div className="addressForm-container-detail-contact">
                  <TextInputField
                    name="zipCode"
                    inputType="number"
                    labelText="PIN"
                    inputValue={values.zipCode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={touched.zipCode && errors.zipCode}
                  />
                  <TextInputField
                    name="contact"
                    inputType="number"
                    labelText="Contact"
                    inputValue={values.contact}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={touched.contact && errors.contact}
                  />
                </div>
              </div>
              <div className="addressForm-container-buttons">
                <Button
                  id="btnInput"
                  className="addressForm-container-submitForm"
                  onClick={handleSubmit}
                >
                  {_.isEmpty(addressSelected) ? "ADD" : "UPDATE"}
                </Button>
                <Button
                  id="btnInput"
                  className="addressForm-container-cancelForm"
                  onClick={() => resetForm({})}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
