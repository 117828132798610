import React, { useEffect } from "react";
import stateDisplayJson from "./states.json";
import { select } from "d3";
import { geoMercator, geoPath } from "d3-geo";
import "./index.css";

const width = 1500;
const height = 2000;

const VendorSalesComposition = ({ salesData }) => {
  useEffect(() => {
    displayIndiaMap();
  }, [salesData]);

  const displayIndiaMap = () => {
    document.getElementById("map__India__Container").innerHTML = "";
    const salesPerStateData = {
      // TODO: Replace India map data including POK
      ...stateDisplayJson,
      features: stateDisplayJson.features.map((item) => ({
        ...item,
        salesPercent: item?.id === salesData?.state ? salesData?.percent : 0,
      })),
    };
    const projection = geoMercator().fitExtent(
      [
        [0, 0],
        [width, height],
      ],
      salesPerStateData
    );
    const path = geoPath().projection(projection);
    var map = select("#map__India__Container")
      .append("svg:svg")
      .attr("width", width)
      .attr("height", height);
    // .call(() => {
    //   projection.scale(9000);
    //   projection.translate([-1520, 1000]);
    // });
    var indianMap = map.append("svg:g").attr("id", "india");
    indianMap
      .selectAll("path")
      .data(salesPerStateData.features)
      .enter()
      .append("path")
      .attr("d", path)
      .attr("fill", (d) => d.fill || "#eee")
      .attr("stroke", "#0e1724")
      .attr("stroke-width", "1")
      .attr("stroke-opacity", "0.5");

    indianMap
      .selectAll("text")
      .data(salesPerStateData.features)
      .enter()
      .append("text")
      .attr("fill", "#585858")
      .attr("transform", (d) => {
        const [x, y] = path.centroid(d);
        return "translate(" + x + "," + y + ")";
      })
      .attr("text-anchor", "middle")
      .style("font-size", "24px")
      .style("font-family", "Impact")
      .attr("dy", ".35em")
      .text((d) => `${d.salesPercent}%`);
  };

  return (
    <div id="map__India__Container" className="map__India__Container"></div>
  );
};

export default VendorSalesComposition;
