import React, { Fragment, useContext } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
  Avatar,
} from "@mui/material";
import { IconDisplay, Collapsible } from "components";
import { UserContext } from "contexts";
import { SIDEBAR_MENU_ITEMS, LANGUAGE_OPTIONS } from "constants";
import { displayDriveImage } from "utils";
import "./index.css";

const MenuDrawer = ({ isMenuOpen, onToggleMenu }) => {
  const navigate = useNavigate();
  const { currentUser, logoutCurrentUser } = useContext(UserContext);

  const handleMenuOptionClick = async (link) => {
    onToggleMenu(false);
    if (link === "/login") {
      await logoutCurrentUser();
      navigate(link);
    }
    navigate(link);
  };

  const DrawerHeader = () => (
    <div className="div__menuDrawer-header">
      <Avatar src={displayDriveImage(currentUser?.profilePic)} />
      <p className="lblProfileName">{currentUser?.name}</p>
    </div>
  );

  const searchLanguageSelected = (contentLanguage) => {
    onToggleMenu(false);
    navigate(
      `/products/search?${createSearchParams({
        contentLanguage,
      })}`
    );
  };

  const LanguageCategories = ({ header }) => (
    <Collapsible
      header={header}
      collapsibleClassname="div__languagesCollapsible"
      headerClassname="div__languagesList"
      expandedClassname="expandedlanguagesList"
      timeout={500}
    >
      {LANGUAGE_OPTIONS.map((language, index) => (
        <Fragment key={language}>
          <p
            className="lblLanguageItem"
            onClick={() => searchLanguageSelected(language)}
          >
            {language}
          </p>
          {index !== LANGUAGE_OPTIONS.length - 1 && (
            <Divider className="div__languageDivider" />
          )}
        </Fragment>
      ))}
    </Collapsible>
  );

  const DrawerContent = () => (
    <div className={`div__menuDrawer-content`}>
      <List>
        {SIDEBAR_MENU_ITEMS.map((item) =>
          item.text === "Language Categories" ? (
            <Fragment key={item.text}>
              <ListItem className="listItemLanguage">
                <LanguageCategories
                  header={
                    <>
                      <IconDisplay type="language" className="menuIcon" />
                      <p className="lblLanguagesHeader">Language Categories</p>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </Fragment>
          ) : (
            renderMenuListItem(item)
          )
        )}
      </List>
    </div>
  );

  const renderMenuListItem = ({ text, icon, link, hasDivider }) => (
    <Fragment key={text}>
      <ListItem button onClick={() => handleMenuOptionClick(link)}>
        <ListItemIcon>
          <IconDisplay type={icon} className="menuIcon" />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
      {hasDivider && <Divider />}
    </Fragment>
  );

  return (
    <Drawer
      className="menuDrawer"
      anchor="left"
      open={isMenuOpen}
      onClose={() => onToggleMenu(false)}
    >
      <DrawerHeader />
      <DrawerContent />
    </Drawer>
  );
};

export default MenuDrawer;
