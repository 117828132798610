import React from "react";
import { TextareaAutosize } from "@mui/material";
import "./index.css";
import { ErrorMessage } from "components";

const TextAreaInputField = ({
  name,
  className,
  minRows,
  iconComponent,
  labelText,
  placeholder,
  inputValue,
  handleChange,
  handleBlur,
  errorText,
}) => {
  return (
    <div className="div__txtInputArea">
      <div className="div__txtInputArea_label">
        <div className="div__txtInputArea_label-icon">
          {iconComponent}
          <p className="lblInput">{labelText}</p>
        </div>
      </div>
      <TextareaAutosize
        name={name}
        className={`txtAreaInput ${className}`}
        minRows={minRows}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default TextAreaInputField;
