import React, { useState, useEffect, useContext, useMemo } from "react";
import _ from "lodash";
import {
  GridDisplay,
  Loading,
  Snackbar,
  CustomDrawer,
  ConfirmationPopup,
  TextField,
} from "components";
import { useSnackbar } from "hooks";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import { OrderServices } from "services";
import {
  generateQueryParamsFromObject,
  calculateOrderCancellationDeadlineInSeconds,
} from "utils";
import OrderDetail from "./OrderDetail";
import "./index.css";
import { AddTicketForm } from "pages/Tickets";
import TicketDetail from "pages/Tickets/MyTickets/MyTicketsList/TicketDetail";
import OrderFeedback from "./OrderFeedback";

const MyOrdersList = ({
  orderStatus,
  deliveryStatus,
  dataList,
  onDataChange,
}) => {
  const { currentUser } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [orders, setOrders] = useState({
    list: [],
    loading: false,
  });
  const [orderPopup, setOrderPopup] = useState({
    selectedOrder: {},
    isPopupOpen: false,
  });
  const [orderDrawer, setOrderDrawer] = useState({
    isDrawerOpen: false,
    content: null,
  });
  const snackbar = useSnackbar();

  useEffect(() => {
    setOrders({
      list: dataList?.filter(
        ({ activeOrderStatus, activeDeliveryStatus }) =>
          (!_.isEmpty(orderStatus) && activeOrderStatus === orderStatus) ||
          (!_.isEmpty(deliveryStatus) &&
            activeDeliveryStatus === deliveryStatus)
      ),
    });
  }, [orderStatus, deliveryStatus, dataList]);

  const updateOrdersListWithTicket = (ticketData) => {
    setOrders({
      ...orders,
      list: _.map(list, (orderItem) =>
        orderItem?._id === ticketData?.orderId
          ? { ...orderItem, ticketId: ticketData?._id }
          : orderItem
      ),
    });
    setOrderDrawer({ isDrawerOpen: false });
  };

  const openOrderDrawer = (record, contentType) => {
    let drawerContent = <></>;
    switch (contentType) {
      case "OrderDetail":
        drawerContent = (
          <OrderDetail
            onCloseDrawer={() => setOrderDrawer({ isDrawerOpen: false })}
            onCancelOrder={() => handleCancelOrder(record)}
            orderDetail={record}
          />
        );
        break;
      case "OrderRefund":
        drawerContent = (
          <AddTicketForm
            ticketCategory="Refund"
            orderDetail={record}
            onCloseDrawer={updateOrdersListWithTicket}
          />
        );
        break;
      case "ViewOrderRefund":
        drawerContent = <TicketDetail ticketDetail={record?.ticketDetail} />;
        break;
      case "OrderFeedback":
        drawerContent = (
          <OrderFeedback
            customerId={currentUser?._id}
            orderId={record?._id}
            orderItems={record?.products}
            onCloseDrawer={() => setOrderDrawer({ isDrawerOpen: false })}
          />
        );
        break;
      default:
        break;
    }
    setOrderDrawer({
      isDrawerOpen: true,
      content: drawerContent,
    });
  };

  const handleCancelOrder = (orderData) =>
    setOrderPopup({
      isPopupOpen: true,
      selectedOrder: orderData,
    });

  const fetchOrdersList = async () => {
    try {
      setOrders({
        ...orders,
        loading: true,
      });
      const query = generateQueryParamsFromObject({
        customerId: currentUser?._id,
        orderStatus,
        deliveryStatus,
        searchTerm,
      });
      const { ordersList } = await OrderServices.getOrdersList(query);
      setOrders({
        list: ordersList,
        loading: false,
      });
    } catch (err) {
      console.error(`Error while fetching orders lisit`, err);
      setOrders({
        ...orders,
        loading: false,
      });
    }
  };

  const downloadOrderInvoice = async (orderId) =>
    (window.location.href = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ORDERS_API}/invoice/${orderId}`);

  const myOrdersConfig = getGridDisplayConfig("Orders", {
    onViewDetail: (record) => openOrderDrawer(record, "OrderDetail"),
    onViewTicketDetail: (record) => openOrderDrawer(record, "ViewOrderRefund"),
    onSubmitOrderFeedback: (record) => openOrderDrawer(record, "OrderFeedback"),
    onCancelOrder: (record) => handleCancelOrder(record),
    onRaiseTicket: (record) => openOrderDrawer(record, "OrderRefund"),
    onDownloadInvoice: ({ _id }) => downloadOrderInvoice(_id),
    onEmptyListButtonClick: () => null,
  });

  const confirmCancelOrder = async (orderData) => {
    try {
      setOrders({
        ...orders,
        loading: true,
      });
      const availableForCancel =
        calculateOrderCancellationDeadlineInSeconds(orderData?.paymentTime) > 0;
      const payload = {
        _id: orderData?._id,
        cancelledBeforeDispatch: availableForCancel,
        payAmount: orderData?.amount * (availableForCancel ? 1 : 0.5),
      };
      const { msg } = await OrderServices.cancelOrderBeforeDelivery(payload);
      setOrders({
        list: orders?.list.filter(({ _id }) => _id !== orderData?._id),
        loading: false,
      });
      snackbar.showMessage({
        content: msg,
      });
      onDataChange && onDataChange();
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
      setOrders({
        ...orders,
        loading: false,
      });
    }
  };

  const handleConfirmAction = async () => {
    setOrderPopup({
      isPopupOpen: false,
    });
    await confirmCancelOrder(selectedOrder);
  };

  const handleSearchTermChange = ({ target }) => {
    delayedFetchOrdersList.cancel();
    setSearchTerm(target.value);
    delayedFetchOrdersList();
  };

  const delayedFetchOrdersList = _.debounce(fetchOrdersList, 500);

  const { list, loading } = orders;
  const { isDrawerOpen, content } = orderDrawer;
  const { isPopupOpen, selectedOrder } = orderPopup;

  const confirmationMessage = useMemo(() => {
    const refundMsg =
      calculateOrderCancellationDeadlineInSeconds(selectedOrder?.paymentTime) >
      0
        ? `You are covered under free cancellation policy and will be refunded full amount`
        : `Your free cancellation period is over. This will be a charged refund.`;
    return (
      <div className="myOrdersList__popupContent">
        <p className="myOrdersList__popupContent-confirmQuestion">
          Are you sure you want to cancel this order?
        </p>
        <p className="myOrdersList__popupContent-confirmMessage">{refundMsg}</p>
      </div>
    );
  }, [selectedOrder]);

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        handleOk={handleConfirmAction}
        handleClose={() =>
          setOrderPopup({
            isPopupOpen: false,
            selectedOrder: {},
          })
        }
      >
        {confirmationMessage}
      </ConfirmationPopup>
      <div className="myOrdersList__container">
        <TextField
          className="myOrdersList__searchInput"
          type="text"
          value={searchTerm}
          placeholder="Search using product name"
          onChange={handleSearchTermChange}
        />
        <GridDisplay
          className="myOrdersList__overview"
          config={myOrdersConfig}
          dataList={list}
        />
        <CustomDrawer
          direction="bottom"
          className="myOrdersList__orderDrawer--view"
          isOpen={isDrawerOpen}
          onCloseDrawer={() => setOrderDrawer({ isDrawerOpen: false })}
        >
          {content}
        </CustomDrawer>
      </div>
    </>
  );
};

export default MyOrdersList;
