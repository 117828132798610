import React from "react";
import _ from "lodash";
import { ShortImagePreview } from "components";
import { RatingIcon } from "../icons";
import "./index.css";

const FeedbackContent = ({ feedback, isSelected, onSelect, hasDivider }) => {
  const { rating, images, title, comment, customerName, createdAt } =
    feedback || {};
  return (
    <>
      <div
        onClick={onSelect}
        className={`div__feedbackDetail ${
          isSelected && "div__feedbackDetailSelected"
        }`}
      >
        <div className="div__feedbackDetail-userRating">
          <p>
            {rating} <RatingIcon className="iconIndividualRating" />
          </p>
        </div>
        <div className="div__feedbackDetail-userFeedback">
          <p>{title}</p>
          {!_.isEmpty(images) && (
            <div className="div__productDetail-ratingFeedback-imagesList">
              {images.map((imageURL) => (
                <ShortImagePreview key={imageURL} imageURL={imageURL} />
              ))}
            </div>
          )}
          <p className="feedbackDetail">{comment}</p>
          <p className="feedbackAuthor">
            {customerName} | {new Date(createdAt)?.toDateString()}
          </p>
        </div>
      </div>
      {hasDivider && <div className="horizontalDivider" />}
    </>
  );
};

export default FeedbackContent;
