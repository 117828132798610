import React from "react";
import { displayCurrencyAmount } from "utils";
import "./index.css";

const OrderBilling = ({ cart }) => {
  const totalPrice = cart?.reduce(
    (totalVal, { price, quantity }) => totalVal + price * quantity,
    0
  );

  const totalDiscount = cart?.reduce(
    (totalVal, { inventoryId, price, quantity }) =>
      totalVal + (inventoryId?.discount * price * quantity) / 100,
    0
  );

  return (
    <div className="div__userCart-priceSummary">
      <div className="div__userCart-priceSummary-header">
        <p>PRICE SUMMARY</p>
      </div>
      <table className="tblPricingSummary">
        <tbody>
          <tr>
            <td>Total MRP (Incl. of taxes)</td>
            <td>{displayCurrencyAmount(totalPrice)}</td>
          </tr>
          <tr>
            <td>Delivery Fee</td>
            <td>{displayCurrencyAmount(40)}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td>{displayCurrencyAmount(totalDiscount)}</td>
          </tr>
          <div className="horizontalDivider" />
          <tr>
            <td className="">SUBTOTAL</td>
            <td>{displayCurrencyAmount(totalPrice + 40 - totalDiscount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderBilling;
