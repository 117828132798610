import React, { useState, createContext } from "react";
import { deleteSelectedDriveImages } from "utils";
import _ from "lodash";

export const InventoryContext = createContext({});

const InventoryContextProvider = ({ children }) => {
  const [selectedInventoryItem, setSelectedInventoryItem] = useState({});
  const [newInventoryList, setNewInventoryList] = useState([]);

  const resetInventoryList = () => setNewInventoryList([]);

  const clearNewInventoryList = () => {
    const newInventoryImages = _.map(newInventoryList, ({images}) => images);
    deleteSelectedDriveImages(newInventoryImages);
    setNewInventoryList([]);
  }

  const assignSelectedInventoryItem = (inventoryData) =>
    setSelectedInventoryItem({ ...inventoryData });

  const addSelectedInventoryItem = (newInventory) =>
    setNewInventoryList((prevList) => [...prevList, newInventory]);

  const modifySelectedInventoryItem = (id, modifiedInventory) =>
    setNewInventoryList((prevList) =>
      prevList.map((listItem) =>
        listItem.id === id ? modifiedInventory : listItem
      )
    );

  const deleteSelectedInventoryItem = (id) => {
    setNewInventoryList((prevList) => {
      deleteSelectedDriveImages(
        prevList.find((listItem) => listItem.id === id)?.images || []
      );
      return prevList.filter((listItem) => listItem.id !== id);
    });
  };

  return (
    <InventoryContext.Provider
      value={{
        selectedInventoryItem,
        newInventoryList,
        modifySelectedInventoryItem,
        resetInventoryList,
        addSelectedInventoryItem,
        clearNewInventoryList,
        setNewInventoryList,
        assignSelectedInventoryItem,
        deleteSelectedInventoryItem,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryContextProvider;
