import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  IconDisplay,
  Button,
  Grid,
  DetailCard,
  Loading,
  FormDialog,
  Snackbar,
} from "components";
import { VendorServices } from "services";
import { UserContext, VendorContext } from "contexts";
import { useSnackbar } from "hooks";
import { displayDateInFormat, displayCurrencyAmount } from "utils";
import TransactionsList from "./TransactionsList";
import VendorMonthlySummary from "./MonthlySummary";
import BankAccountsList from "./BankAccounts";
import BankAccountForm from "./BankAccounts/BankAccountForm";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const VendorWalletDashboard = () => {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [bankAccountSelected, setBankAccountSelected] = useState(undefined);
  const { currentUser } = useContext(UserContext);
  const { currentVendor, setCurrentVendor } = useContext(VendorContext);

  useEffect(() => {
    if (_.isEmpty(currentVendor?.dashboardData)) {
      fetchVendorDashboard();
    }
  }, []);

  const fetchVendorDashboard = async () => {
    try {
      setLoading(true);
      const dashboardData = await VendorServices.fetchVendorDashboard(
        currentUser?._id
      );
      setCurrentVendor({ ...currentVendor, dashboardData });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateBankAccount = (bankAccountDetail) =>
    setBankAccountSelected(bankAccountDetail);

  const handleBankAccountFormSubmit = (selectedBankAccount) => {
    setBankAccountSelected(undefined);
    setCurrentVendor((prevVendor) => {
      const originalBankAccountsList =
        currentVendor?.dashboardData?.bankAccountsList || [];
      let newBankAccountsList = [];

      if (
        !_.isEmpty(
          originalBankAccountsList.filter(
            (bankAccount) => bankAccount?._id === selectedBankAccount?._id
          )
        )
      ) {
        newBankAccountsList = originalBankAccountsList.map((bankAccount) =>
          selectedBankAccount?._id === bankAccount?._id
            ? selectedBankAccount
            : {
                ...bankAccount,
                isPrimaryAccount: selectedBankAccount?.isPrimaryAccount
                  ? false
                  : bankAccount?.isPrimaryAccount,
              }
        );
      } else {
        newBankAccountsList = [
          ...originalBankAccountsList.map((bankAccount) => ({
            ...bankAccount,
            isPrimaryAccount: selectedBankAccount?.isPrimaryAccount
              ? false
              : bankAccount?.isPrimaryAccount,
          })),
          selectedBankAccount,
        ];
      }

      return {
        ...prevVendor,
        dashboardData: {
          ...prevVendor?.dashboardData,
          bankAccountsList: newBankAccountsList,
        },
      };
    });
  };

  const removeBankAccount = async (bankAccountId) => {
    try {
      setLoading(true);
      const { msg } = await VendorServices.deleteBankAccount(bankAccountId);
      setLoading(false);
      setCurrentVendor((prevVendor) => ({
        ...prevVendor,
        dashboardData: {
          ...prevVendor?.dashboardData,
          bankAccountsList: prevVendor?.dashboardData?.bankAccountsList?.filter(
            ({ _id }) => _id !== bankAccountId
          ),
        },
      }));
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const setPrimaryBankAccount = async (bankAccountId) => {
    try {
      setLoading(true);
      const { msg } = await VendorServices.setPrimaryBankAccount(bankAccountId);
      setLoading(false);
      setCurrentVendor((prevVendor) => ({
        ...prevVendor,
        dashboardData: {
          ...prevVendor?.dashboardData,
          bankAccountsList: prevVendor?.dashboardData?.bankAccountsList?.map(
            (bankAccount) => ({
              ...bankAccount,
              isPrimaryAccount: bankAccount?._id === bankAccountId,
            })
          ),
        },
      }));
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const dashboardGridDisplayList = [
    {
      gridDivision: 4,
      content: (
        <DetailCard
          className="walletBalance__card"
          cardContent={
            <>
              <div className="walletBalance__card--left">
                <div>
                  <p className="walletBalance__card--left-header">
                    Wallet Balance
                  </p>
                  <p className="walletBalance__card--left-subHeader">
                    Your Current Wallet Balance
                  </p>
                </div>
                <p className="walletBalance__card--left-content">
                  {displayCurrencyAmount(
                    currentVendor?.dashboardData?.salesAnalytics?.walletBalance
                  )}
                </p>
                <p className="walletBalance__card--left-subContent">
                  You cannot withdraw more than 60% of your current balance
                </p>
              </div>
              <p className="walletBalance__card--right">
                <IconDisplay
                  type="wallet"
                  className={`walletBalance__card--right-accountBalance`}
                />
              </p>
            </>
          }
          contentClass="walletBalance__card-content"
        />
      ),
      className: "vendorWalletDashboard__gridContainer-walletBalance",
    },
    {
      gridDivision: 8,
      content: (
        <VendorMonthlySummary
          {...currentVendor?.dashboardData?.salesAnalytics}
        />
      ),
      className: "vendorWalletDashboard__gridContainer-monthlySummary",
    },
    {
      gridDivision: 4,
      content: (
        <BankAccountsList
          bankAccountsList={currentVendor?.dashboardData?.bankAccountsList}
          removeBankAccount={removeBankAccount}
          updateBankAccount={updateBankAccount}
          onSetPrimaryAccount={setPrimaryBankAccount}
        />
      ),
      className: "vendorWalletDashboard__gridContainer-bankAccountsList",
    },
    {
      gridDivision: 8,
      content: <TransactionsList vendorId={currentUser?._id} />,
      className: "vendorWalletDashboard__gridContainer-transactionsList",
    },
  ];

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      {
        <FormDialog
          isPopupOpen={!_.isUndefined(bankAccountSelected)}
          onClose={() => setBankAccountSelected(undefined)}
        >
          <BankAccountForm
            bankAccountDetail={bankAccountSelected}
            onFormSubmit={handleBankAccountFormSubmit}
          />
        </FormDialog>
      }
      <div className="vendorWalletDashboard">
        <div className="vendorWalletDashboard__header">
          <div className="vendorWalletDashboard__header--left">
            <p className="vendorWalletDashboard__header--left-header">
              <IconDisplay
                type="wallet"
                className="vendorWalletDashboard__header--left-icon"
              />{" "}
              Wallet
            </p>
            <p className="vendorWalletDashboard__header--left-walletUpdateTime">
              Last updated on {displayDateInFormat(new Date(), "Do MMM YYYY")}
            </p>
          </div>
          <div className="vendorWalletDashboard__header--right">
            <Button
              className="vendorWalletDashboard__header--right-addBankAccount-button"
              onClick={() => setBankAccountSelected({})}
            >
              Add Bank Account
            </Button>
            <p className="vendorWalletDashboard__header--right-addBankAccount-text">
              New Bank Account? Add here
            </p>
          </div>
        </div>
        <Grid
          container
          spacing={2}
          className="vendorWalletDashboard__gridContainer"
        >
          {dashboardGridDisplayList?.map(
            ({ gridDivision, content, className }) => (
              <Grid
                item
                xs={gridDivision}
                sm={gridDivision}
                md={gridDivision}
                lg={gridDivision}
                className={className}
              >
                {content}
              </Grid>
            )
          )}
        </Grid>
      </div>
    </>
  );
};

export default VendorWalletDashboard;
