import { useState, useEffect } from "react";

const rootElement = document.querySelector("#root");

const getDeviceType = () => {
  const { width } = rootElement.getBoundingClientRect();
  if (width < 640) return `mobile`;
  else if (width >= 640 && width < 960) return `tabletPortrait`;
  else if (width >= 960 && width < 1281) return `tabletLandscape`;
  else if (width >= 1281 && width < 1500) return `laptop`;
  else return `laptopLarge`;
};

const useResponsive = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  const handleResize = () => setDeviceType(getDeviceType());

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { deviceType };
};

export default useResponsive;
