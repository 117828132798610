import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  DateTimePickerInput,
  PaginatedTable,
  TextInputField,
  Button,
  CustomDrawer,
  ActionIcon,
  Loading,
  Snackbar,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { ProductDetailPage } from "pages";
import { InventoryContext } from "contexts";
import { InventoryServices } from "services";
import { useSnackbar } from "hooks";
import "./index.css";

const InventoryListPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { assignSelectedInventoryItem } = useContext(InventoryContext);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const selectedProductId = params.productId;
  const { product } = location?.state || {};
  const [searchKey, setSearchKey] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [inventoryList, setInventoryList] = useState([]);
  const [inventoryPreview, setInventoryPreview] = useState({
    selectedInventory: {},
    isDrawerOpen: false,
  });

  const inventoryConfig = getTableDisplayConfig("Inventory", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() =>
            setInventoryPreview({
              selectedInventory: [rowVal],
              isDrawerOpen: true,
            })
          }
        />
        <ActionIcon
          type="edit"
          iconClassName="listIcon"
          onClick={() => {
            assignSelectedInventoryItem(rowVal);
            navigate(
              `/product/${rowVal?.productId}/inventory/update/${rowVal?._id}`
            );
          }}
        />
        <ActionIcon
          type="delete"
          iconClassName="listIcon"
          onClick={async () => {
            try {
              setLoading(true);
              const msg = await InventoryServices.deleteProductInventory(
                rowVal._id
              );
              setLoading(false);
              snackbar.showMessage({
                content: msg,
                handleClose: fetchInventoryList,
              });
            } catch (err) {
              setLoading(false);
              console.error(err);
            }
          }}
        />
      </>
    ),
  });

  const fetchInventoryList = async () => {
    try {
      setLoading(true);
      const resp = await inventoryConfig.fetchListData(selectedProductId, true);
      setInventoryList(resp);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventoryList();
  }, [selectedProductId]);

  return (
    <div className="div__inventoryList">
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <p className="headerInventoryList">Inventory for {product?.title}</p>
      <div className="div__inventoryList-container">
        <div className="div__inventoryList-filters">
          {/* Filters */}
          <TextInputField
            className="searchKey"
            labelText="Billing No."
            placeholder="Search via Billing No."
            inputValue={searchKey}
            handleChange={({ target }) => setSearchKey(target.value)}
          />
          <DateTimePickerInput
            className="inventorySearchDatePicker"
            inputType="Date"
            labelText="From"
            dateValue={dateRange.startDate}
            handleChange={({ _d }) =>
              setDateRange({ ...dateRange, startDate: _d })
            }
          />
          <DateTimePickerInput
            className="inventorySearchDatePicker"
            inputType="Date"
            labelText="To"
            dateValue={dateRange.endDate}
            handleChange={({ _d }) =>
              setDateRange({ ...dateRange, endDate: _d })
            }
          />
          <Button
            id="btnInput"
            size="large"
            className="btnAddInventory"
            onClick={() => {
              assignSelectedInventoryItem({});
              navigate(`/product/${selectedProductId}/inventory/add`);
            }}
          >
            Add New Item
          </Button>
        </div>
        <div className="div__inventoryList-tableContainer">
          {/* Table */}
          <PaginatedTable
            config={inventoryConfig}
            dataList={inventoryList}
          />
        </div>
        <CustomDrawer
          direction="bottom"
          className="productDetailDrawer"
          isOpen={inventoryPreview?.isDrawerOpen}
          onCloseDrawer={() =>
            setInventoryPreview({
              isDrawerOpen: false,
            })
          }
        >
          <ProductDetailPage
            isPreview={true}
            previewProduct={product}
            previewInventory={inventoryPreview?.selectedInventory}
            onCloseDrawer={() =>
              setInventoryPreview({
                selectedProduct: {},
                selectedInventory: {},
                isDrawerOpen: false,
              })
            }
          />
        </CustomDrawer>
      </div>
    </div>
  );
};

export default InventoryListPage;
