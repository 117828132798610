import CAROUSEL_DISPLAY_CONFIG from "./CarouselDisplayConfig";

const getCarouselDisplayConfig = (screenName, actions = {}) => {
  const configFound = CAROUSEL_DISPLAY_CONFIG[screenName];

  return {
    ...configFound,
    displayCarouselItem: (record) =>
      configFound.renderComponent(record, actions),
  };
};

export default getCarouselDisplayConfig;
