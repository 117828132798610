import { EmptyListPlaceholder } from "components";
import MemeSuggestionCard from "pages/MemeSuggestion/MyMemeSuggestions/MemeSuggestionCard";
import EmptyMemeSuggestionList from "assets/EmptyMemeSuggestionsList.png";

const MEME_SUGGESTION_LIST_CONFIG = {
  renderComponent: (value, { onWithdrawMeme, onViewDetail }) => (
    <MemeSuggestionCard
      memeSuggestion={value}
      onWithdrawMeme={() => onWithdrawMeme(value)}
      onViewDetail={() => onViewDetail(value)}
    />
  ),
  emptyDataContent: ({ onEmptyListButtonClick }) => (
    <EmptyListPlaceholder
      placeholderImage={EmptyMemeSuggestionList}
      imageClass="myMemeSuggestions-imgEmptyList"
      message="It seems you have not suggested any meme."
      subMessage="Help us by suggesting memes."
      onButtonClick={onEmptyListButtonClick}
      buttonLabel="Suggest Meme"
    />
  ),
};

export default MEME_SUGGESTION_LIST_CONFIG;
