export const FEEDBACK_RATINGS = {
  1: "Very Poor",
  2: "Poor",
  3: "Average",
  4: "Good",
  5: "Excellent",
};

export const FEEDBACK_CHOICE_QUESTIONS_LIST = [
  {
    field: "wearingMask",
    question: `Was the delivery partner wearing a mask ?`,
    optionsList: ["Yes", "No"],
  },
  {
    field: "timedDelivery",
    question: `Was the order delivered by the estimated date of delivery?`,
    optionsList: ["Yes", "No"],
  },
  {
    field: "noContactDelivery",
    question: `Was the delivery No - Contact?`,
    optionsList: ["Yes", "No"],
  },
];
