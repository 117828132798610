import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import "./index.css";

const CheckoutButton = () => {
  const navigate = useNavigate();
  const handleRazorpayPayment = () => navigate("/checkOut");

  return (
    <>
      <Button
        id="btnInput"
        className="btnCheckout"
        onClick={handleRazorpayPayment}
      >
        CHECKOUT
      </Button>
    </>
  );
};

export default CheckoutButton;
