import React from "react";
import { Rating, ShortImagePreview } from "components";
import { displayCurrencyAmount } from "utils";
import "./index.css";

const SizeChartSelectedItem = ({
  productData,
  selectedInventory,
  mainImage,
}) => {
  const { title, contentLanguage, rating, ratingsCount } = productData;

  const { color, hasDiscount, discountedPrice, price, discount } =
    selectedInventory;

  return (
    <div className="div__itemSelected">
      <div className="div__itemSelected-content">
        <ShortImagePreview imageURL={mainImage} />
        <div className="div__itemSelected-details">
          <p className="lblItemTitle">
            {title} ({color})
          </p>
          <p className="lblItemLanguage">{contentLanguage}</p>
          <div className="div__itemSelected-rating">
            <Rating value={rating} readOnly />
            <p className="ratingsCount">({ratingsCount})</p>
          </div>
        </div>
      </div>
      <div className="div__productDetail-priceDetails">
        <p className="discountedPrice">
          {hasDiscount
            ? displayCurrencyAmount(discountedPrice)
            : displayCurrencyAmount(price)}
        </p>
        {hasDiscount && (
          <p className="actualPrice">{displayCurrencyAmount(price)}</p>
        )}
        {hasDiscount && <p className="discount">{`(${discount}% off)`}</p>}
      </div>
    </div>
  );
};

export default SizeChartSelectedItem;
