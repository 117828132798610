import React, { useState, useContext, useRef } from "react";
import { CheckboxInput, MultiRangeSlider } from "components";
import { SearchContext } from "contexts";
import {
  GENDER_OPTIONS,
  LANGUAGE_OPTIONS,
  SIZE_OPTIONS,
  PRICE_RANGE_OPTIONS,
  DISCOUNT_RANGE_OPTIONS,
} from "constants";
import { displayCurrencyAmount, modifyList, applyDebounce } from "utils";
import "./index.css";

const SearchFilters = () => {
  const { searchFilter, modifySearchFilters, setSearchFilter } =
    useContext(SearchContext);
  const { gender, contentLanguage, sizeSymbol, sortBy } = searchFilter || {};
  const [latestArrivals, setLatestArrivals] = useState(sortBy === "createdAt");
  const [genderSelections, setGenderSelections] = useState(
    gender?.split(",") || []
  );
  const [languageSelections, setLanguageSelections] = useState(
    contentLanguage?.split(",") || []
  );
  const [sizeSelections, setSizeSelections] = useState(
    sizeSymbol?.split(",") || []
  );
  const priceRangeRef = useRef();
  const discountRangeRef = useRef();

  const handleRangeChange = applyDebounce((key, { min, max }) => {
    modifySearchFilters(key, [min, max]);
  }, 1000);

  const resetRange = (key) => {
    if (key === "price") {
      priceRangeRef.current.resetSliderRange();
    } else {
      discountRangeRef.current.resetSliderRange();
    }
    modifySearchFilters(key, []);
  };

  const modifyFiltersList = (filterKey, prevList, val) => {
    const modifiedList = val.length ? modifyList(prevList, val) : [];
    modifySearchFilters(filterKey, modifiedList.join(","));
    return modifiedList;
  };

  const generateTwoColumnList = (filterType, inputArray, filterList) => {
    const gridCols = [[], []];

    inputArray.forEach((itemVal, index) => {
      const comp = (
        <CheckboxInput
          key={itemVal}
          isChecked={filterList.includes(itemVal)}
          handleChange={() => modifyCheckboxFilters(filterType, itemVal)}
          labelText={itemVal}
        />
      );
      const colNumber = index % 2;
      gridCols[colNumber].push(comp);
    });

    return (
      <div className="div__checklist-container">
        <div>{gridCols[0]}</div>
        <div>{gridCols[1]}</div>
      </div>
    );
  };

  const modifyCheckboxFilters = (filterType, value) => {
    switch (filterType) {
      case "gender":
        setGenderSelections((prevList) =>
          modifyFiltersList("gender", prevList, value)
        );
        break;
      case "contentLanguage":
        setLanguageSelections((prevList) =>
          modifyFiltersList("contentLanguage", prevList, value)
        );
        break;
      case "sizeSymbol":
        setSizeSelections((prevList) =>
          modifyFiltersList("sizeSymbol", prevList, value)
        );
        break;
      default:
        break;
    }
  };

  const toggleLatestArrivals = () =>
    setLatestArrivals((prevVal) => {
      setSearchFilter(
        !prevVal
          ? {
              sortBy: "createdAt",
              order: "desc",
              limit: 50,
            }
          : {}
      );
      return !prevVal;
    });

  return (
    <div className="div__searchPage-filters">
      <CheckboxInput
        isChecked={latestArrivals}
        handleChange={toggleLatestArrivals}
        labelText="Latest Additions"
      />

      <div className="div__searchPage-filters_gender">
        <div className="div__searchPage-filters-label">
          <h3>Gender</h3>
          <p
            className="filterReset"
            onClick={() => modifyCheckboxFilters("gender", [])}
          >
            Reset
          </p>
        </div>
        <div className="div__searchPage-filters_gender-options">
          {GENDER_OPTIONS.filter(({ value }) => value !== "Select").map(
            ({ label, value }) => (
              <CheckboxInput
                key={value}
                isChecked={genderSelections.includes(value)}
                handleChange={() => modifyCheckboxFilters("gender", value)}
                labelText={label}
              />
            )
          )}
        </div>
      </div>

      <div className="div__searchPage-filters_language">
        <div className="div__searchPage-filters-label">
          <h3>Language</h3>
          <p
            className="filterReset"
            onClick={() => modifyCheckboxFilters("contentLanguage", [])}
          >
            Reset
          </p>
        </div>
        {generateTwoColumnList(
          "contentLanguage",
          LANGUAGE_OPTIONS,
          languageSelections
        )}
      </div>

      <div className="div__searchPage-filters_sizeSymbol">
        <div className="div__searchPage-filters-label">
          <h3>Size</h3>
          <p
            className="filterReset"
            onClick={() => modifyCheckboxFilters("sizeSymbol", [])}
          >
            Reset
          </p>
        </div>
        <div className="div__searchPage-filters_sizeSymbol-options">
          {SIZE_OPTIONS.filter((value) => value !== "Select").map((value) => (
            <CheckboxInput
              key={value}
              isChecked={sizeSelections.includes(value)}
              handleChange={() => modifyCheckboxFilters("sizeSymbol", value)}
              labelText={value}
            />
          ))}
        </div>
      </div>

      <div className="div__searchPage-filters_price">
        <div className="div__searchPage-filters-label">
          <h3>Price</h3>
          <p className="filterReset" onClick={() => resetRange("price")}>
            Reset
          </p>
        </div>
        <div className="div__inputSlider">
          <MultiRangeSlider
            ref={priceRangeRef}
            dataRange={PRICE_RANGE_OPTIONS}
            onChange={(outputRange) => handleRangeChange("price", outputRange)}
            displayFormat={displayCurrencyAmount}
          />
        </div>
      </div>

      <div className="div__searchPage-filters_discount">
        <div className="div__searchPage-filters-label">
          <h3>Discount</h3>
          <p className="filterReset" onClick={() => resetRange("discount")}>
            Reset
          </p>
        </div>
        <div className="div__inputSlider">
          <MultiRangeSlider
            ref={discountRangeRef}
            dataRange={DISCOUNT_RANGE_OPTIONS}
            onChange={(outputRange) =>
              handleRangeChange("discount", outputRange)
            }
            displayFormat={(val) => `${val}%`}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
