import { Axios } from "utils";

const OrderServices = {};
const BASE_URL = process.env.REACT_APP_ORDERS_API

OrderServices.createUpdateOrder = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

OrderServices.cancelOrderBeforeDelivery = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/cancel`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

OrderServices.updateOrderStatus = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/status`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

OrderServices.getOrdersList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default OrderServices;
