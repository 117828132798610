import GRID_DISPLAY_CONFIG from "./GridDisplayConfig";

const getGridDisplayConfig = (screenName, actions = {}) => {
  const configFound = GRID_DISPLAY_CONFIG[screenName];
  const { renderComponent, emptyDataContent } = configFound;

  return {
    ...configFound,
    displayGridElement: (record) => renderComponent(record, actions),
    generateEmptyDataContent: emptyDataContent(actions),
  };
};

export default getGridDisplayConfig;
