import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Rating, Image, ActionIcon } from "components";
import ProductImage from "tests/assets/1.png";
import { displayCurrencyAmount } from "utils";
import "./mobile.css";
import "./tablet.css";

const ProductCard = ({ product, handleAddToWishlist }) => {
  const navigate = useNavigate();
  const {
    _id,
    title,
    contentLanguage,
    price,
    discount,
    discountedPrice,
    rating,
    ratingsCount,
    inWishlist,
  } = product;
  return (
    <div
      className="div__productCard"
      onClick={() => navigate(`/product/${_id}`)}
    >
      <Image imageURL={ProductImage} className="productImage" />
      <ActionIcon
        type={"favorite"}
        iconClassName={inWishlist ? "iconWishlistSelected" : "iconWishlist"}
        onClick={handleAddToWishlist}
      />
      <div className="div__productCard__details">
        <div className="div__productCard-title">
          <div>
            <p className="productTitle">{title}</p>
            <p className="lblLanguage">{contentLanguage}</p>
          </div>
          <div className="div__productCard-price">
            <p className="productPrice">
              {displayCurrencyAmount(discount ? discountedPrice : price)}
            </p>
            {discount > 0 && (
              <p className="productDiscountedPrice">
                {displayCurrencyAmount(price)}
                <span className="productDiscount">{`(${discount}%)`}</span>
              </p>
            )}
          </div>
        </div>

        <div className="div__productCard-footer">
          <div className="div__productCard-rating">
            <Rating value={rating} readOnly />
            <p className="ratingsCount">({ratingsCount})</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
