import { Axios } from "utils";

const VendorServices = {};
const BASE_URL = process.env.REACT_APP_VENDORS_API;

VendorServices.fetchVendorDashboard = async (vendorId) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/dashboard/${vendorId}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.fetchTicketsList = async ({ vendorId, pageLimit }) => {
  try {
    const { data } = await Axios.get(
      `${BASE_URL}/tickets/?vendorId=${vendorId}&pageLimit=${pageLimit}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.fetchTransactionsList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/transactions/?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.fetchDispatchOrdersList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/dispatchOrders/?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.createUpdateVendor = async (mode, formDataPayload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/${mode}`, formDataPayload, {
      header: { "content-type": "multipart/form-data" },
    });
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.createUpdateBankAccount = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/bankAccount`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.deleteBankAccount = async (bankAccountId) => {
  try {
    const { data } = await Axios.delete(
      `${BASE_URL}/bankAccount/${bankAccountId}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

VendorServices.setPrimaryBankAccount = async (bankAccountId) => {
  try {
    const { data } = await Axios.put(
      `${BASE_URL}/bankAccount/primary/${bankAccountId}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default VendorServices;
