import React from "react";
import { ErrorMessage } from "components";
import { SIZE_OPTIONS } from "constants";
import "./index.css";

const SizeSelector = ({
  labelText,
  sizeOptions,
  sizeSelected,
  onSelectOption,
  errorText,
}) => {
  return (
    <div className="div__sizeSelector-container">
      <p className="lblSelectSize">{labelText}</p>
      <div className="div__sizeSelector-sizes">
        {SIZE_OPTIONS.map((sizeVal) => (
          <div
            key={sizeVal}
            className={`sizeOption${
              sizeVal === sizeSelected ? " sizeOptionSelected" : ""
            } ${!sizeOptions.includes(sizeVal) ? "sizeDisabled" : ""}`}
            onClick={() =>
              sizeOptions.includes(sizeVal) ? onSelectOption(sizeVal) : null
            }
          >
            {sizeVal}
          </div>
        ))}
      </div>
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default SizeSelector;
