import React from "react";
import _ from "lodash";
import { DetailCard, Image } from "components";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const TicketCard = ({ ticket, onDeleteTicket, onViewDetail }) => {
  const {
    description,
    attachments,
    statusList,
    assistantReply,
    createdAt,
  } = ticket;

  return (
    <DetailCard
      className="myTickets__ticketCard"
      headerContent={
        <p>Ticket Raised On : {displayDateInFormat(createdAt, "Do MMMM YYYY")}</p>
      }
      cardContent={
        <div className="myTickets__ticketCard-content">
          <Image
            imageURL={
              !_.isEmpty(attachments)
                ? displayDriveImage(attachments[0])
                : require("../../../../../tests/assets/nonresident-dwJw6KB_MjA-unsplash.png")
            }
            className="myTickets__ticketCard-content-image"
          />
          <div className="myTickets__ticketCard-content-details">
            <p className="myTickets__ticketCard-content-details-titleLabel">
              Ticket Description
            </p>
            <p className="myTickets__ticketCard-content-details-titleDescription">
              {description}
            </p>
            <p className="myTickets__ticketCard-content-details-replyLabel">
              Assistant Reply
            </p>
            <p className="myTickets__ticketCard-content-details-replyDescription">
              {assistantReply}
              {_.isEmpty(assistantReply) && (
                <span className="myTickets__ticketCard-content-details-replyPlaceholder">
                  The assistant will get back to you shortly
                </span>
              )}
            </p>
          </div>
        </div>
      }
      actionButtons={
        <>
          <div onClick={onDeleteTicket}>Close Ticket</div>
          <div className="verticalDivider" />
          <div onClick={onViewDetail}>Ticket Info</div>
        </>
      }
    />
  );
};

export default TicketCard;
