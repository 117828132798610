import React from "react";
import { CircularProgress } from "components";
import "./index.css";

const SlidingCarousel = ({ config, loading, itemsList = [] }) => {
  const { displayCarouselItem } = config || {};

  return (
    <div className="div__carousel-container">
      {loading ? (
        <CircularProgress className="loadingProgress" />
      ) : (
        <div className="div__carousel-items">
          {itemsList?.map((item) => displayCarouselItem(item))}
        </div>
      )}
    </div>
  );
};

export default SlidingCarousel;
