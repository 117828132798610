import React from "react";

const FileUpload = ({
  isSingle,
  htmlFor,
  acceptFileTypes,
  onFileSelect,
  children,
}) => {
  const onFileChosen = async ({ target: { files } }) => {
    if (files.length) {
      onFileSelect(isSingle ? files[0] : files);
    }
  };

  return (
    <label htmlFor={htmlFor} className="lblUpload">
      <input
        accept={acceptFileTypes}
        id={htmlFor}
        multiple={!isSingle}
        type="file"
        onChange={onFileChosen}
        hidden
      />
      {children}
    </label>
  );
};

export default FileUpload;
