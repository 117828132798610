import { EmptyListPlaceholder } from "components";
import TicketCard from "../../pages/Tickets/MyTickets/MyTicketsList/TicketCard";
import EmptyTicketList from "assets/EmptyTicketsList.png";

const TICKET_LIST_CONFIG = {
  renderComponent: (value, { onDeleteTicket, onViewDetail }) => (
    <TicketCard
      ticket={value}
      onDeleteTicket={() => onDeleteTicket(value)}
      onViewDetail={() => onViewDetail(value)}
    />
  ),
  emptyDataContent: ({ onTabEmpty, onEmptyListButtonClick }) => {
    const {message, subMessage} = onTabEmpty();
    return (
      <EmptyListPlaceholder
        placeholderImage={EmptyTicketList}
        imageClass="myTickets-imgEmptyList"
        message={message}
        subMessage={subMessage}
        onButtonClick={onEmptyListButtonClick}
        buttonLabel="Raise Ticket"
      />
    );
  },
};

export default TICKET_LIST_CONFIG;
