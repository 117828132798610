import React from "react";
import { ColorPicker } from "material-ui-color";
import ErrorMessage from "../ErrorMessage";
import "./index.css";

const ColorPickerInput = ({
  className,
  labelText,
  selectedColor,
  onSelectColor,
  errorText,
}) => {
  return (
    <div className="div__colorPicker">
      <p className="lblInput">{labelText}</p>
      <ColorPicker
        className={className}
        value={selectedColor}
        onChange={onSelectColor}
      />
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default ColorPickerInput;
