import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import IconDisplay from "components/IconDisplay";

const VideoPreview = ({
  videoSource,
  className,
  frameClassname,
  height,
  width,
}) => {
  const [videoURL, setVideoURL] = useState(videoSource);

  const onVisibilityChange = (isVisible) =>
    isVisible && setVideoURL(`${videoSource}?autoplay=1&mute=1`);

  return (
    <div className={className}>
      {!videoSource ? (
        <IconDisplay type="playCircle" className="playPreviewIcon" />
      ) : (
        <VisibilitySensor onChange={onVisibilityChange}>
          <iframe
            src={videoURL}
            width={width}
            height={height}
            className={frameClassname}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VisibilitySensor>
      )}
    </div>
  );
};

export default VideoPreview;
