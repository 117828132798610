import { Axios } from "utils";

const InventoryServices = {};
const BASE_URL = process.env.REACT_APP_INVENTORY_API

InventoryServices.getInventoryListForProduct = async (productId, isAdmin) => {
  try {
    const { data } = await Axios.get(
      `${BASE_URL}?productId=${productId}&isAdmin=${isAdmin}`
    );
    if (data?.success) {
      return(data?.inventoryList);
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

InventoryServices.createUpdateProductInventory = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/`, payload);
    if (data?.success) {
      return data?.msg;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

InventoryServices.deleteProductInventory = async (inventoryId) => {
  try {
    const { data } = await Axios.delete(`${BASE_URL}/${inventoryId}`);

    if (data?.success) {
      return data.msg;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default InventoryServices;
