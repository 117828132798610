import React, { useContext, useEffect, useState } from "react";
import { TabDisplay, Loading } from "components";
import { getTabDisplayConfig } from "displayConfigs";
import { OrderServices } from "services";
import { UserContext } from "contexts";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MyOrdersPage = () => {
  const { currentUser } = useContext(UserContext);
  const [tabConfig, setTabConfig] = useState({
    renderTabsList: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTicketsList();
  }, []);

  const fetchTicketsList = async () => {
    try {
      setLoading(true);
      const { ordersList } = await OrderServices.getOrdersList(
        `customerId=${currentUser?._id}`
      );
      setTabConfig(getTabDisplayConfig("Orders", ordersList));
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="myOrders__container">
        <div className="myOrders__header">{tabConfig.screenName}</div>
        <TabDisplay config={tabConfig} />
      </div>
    </>
  );
};

export default MyOrdersPage;
