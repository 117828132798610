import React, { useState, useEffect } from "react";
import _ from "lodash";
import VisibilitySensor from "react-visibility-sensor";
import { RatingsIcon, RatingIcon } from "../icons";
import { ShortImagePreview } from "components";
import { FeedbackServices } from "services";
import FeedbackContent from "./FeedbackContent";
import FeedbackDetail from "./FeedbackDetail";
import { CircularProgress } from "components/index";

const ProductRatings = ({ productId, rating, ratingsCount }) => {
  const [feedbackObj, setFeedbackObj] = useState({
    list: [],
    images: [],
    pageNum: 0,
    loading: false,
    totalCount: 0,
    idSelected: "",
    composition: [],
  });

  const fetchFeedbacksList = async () => {
    setFeedbackObj({
      ...feedbackObj,
      loading: true,
    });

    try {
      const {
        feedbacksList,
        feedbackImages,
        feedbacksCount,
        feedbackComposition,
      } = await FeedbackServices.getProductFeedbacksList(
        `productId=${productId}&pageNum=${feedbackObj?.pageNum}&pageLimit=2`
      );
      setFeedbackObj({
        ...feedbackObj,
        list: [...list, ...feedbacksList],
        images: [...images, ...feedbackImages],
        pageNum: feedbackObj?.pageNum + 1,
        totalCount: feedbacksCount,
        composition: feedbackComposition,
        loading: false,
      });
    } catch (err) {
      setFeedbackObj({
        ...feedbackObj,
        loading: true,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFeedbacksList();
  }, [productId]);

  const displayFeedbackCard = (feedback, index) => (
    <FeedbackContent
      key={feedback?._id}
      isSelected={feedback?._id === idSelected}
      onSelect={() =>
        setFeedbackObj({ ...feedbackObj, idSelected: feedback?._id })
      }
      feedback={feedback}
      index={index}
      hasDivider={index < list?.length - 1}
    />
  );

  const onVisibilityChange = (isVisible) =>
    isVisible && list?.length < totalCount && fetchFeedbacksList();

  const { list, images, loading, totalCount, idSelected, composition } =
    feedbackObj;

  const ratingsComposition = Array.from(
    { length: 5 },
    (ev, iter) => 5 - iter
  ).map((ratingVal) => ({
    rating: ratingVal,
    percent: Math.round(
      ((composition?.find(({ _id }) => _id === ratingVal)?.totalRating || 0) /
        ratingsCount) *
        100
    ),
  }));

  return (
    <div className="div__productDetail-ratings">
      <div className="div__productDetail-ratings-list">
        <p className="ratingHeader">
          Ratings <RatingsIcon className="iconRatingsHeader" />
        </p>
        <div className="div__productDetail-ratings-list-details">
          <div>
            <p className="averageRating">
              {rating?.toFixed(1)} <RatingIcon className="iconAverageRating" />
            </p>
            <p>{totalCount} Ratings</p>
          </div>
          <div className="verticalDivider" />
          <div className="div__productDetail-ratings-list-composition">
            {!_.isEmpty(composition) &&
              ratingsComposition?.map((feedback) => (
                <div
                  key={feedback?.rating}
                  className="div__ratings-composition"
                >
                  <p>
                    {feedback?.rating}
                    <RatingIcon className="iconComposition" />
                  </p>
                  <div className="div__ratings-percentageContainer">
                    <div
                      className="div__ratings-percentageFiller"
                      style={{
                        width: `${feedback?.percent}%`,
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="horizontalDivider" />

        {!_.isEmpty(images) && (
          <>
            <div className="div__productDetail-ratings-images">
              <p>Customer Photos ({images?.length})</p>
              <div className="div__productDetail-ratings-imagesList">
                {images.map((imageURL) => (
                  <ShortImagePreview key={imageURL} imageURL={imageURL} />
                ))}
              </div>
            </div>
            <div className="horizontalDivider" />
          </>
        )}

        <div className="div__productDetail-ratings-reviews">
          <p>Customer Reviews ({totalCount})</p>
          <div className="div__productDetail-ratings-reviewsList">
            {list?.map((feedback, index) =>
              index === list?.length - 1 ? (
                <VisibilitySensor onChange={onVisibilityChange}>
                  {displayFeedbackCard(feedback, index)}
                </VisibilitySensor>
              ) : (
                displayFeedbackCard(feedback, index)
              )
            )}
            {loading && <CircularProgress className="loadingProgress" />}
          </div>
        </div>
      </div>
      {!_.isEmpty(idSelected) && (
        <FeedbackDetail
          feedback={list?.find(({ _id }) => _id === idSelected)}
        />
      )}
    </div>
  );
};

export default ProductRatings;
