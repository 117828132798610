export const INVENTORY_LIST_ADMIN_VIEW = [
  {
    _id: "620",
    productId: "120",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
  {
    _id: "621",
    productId: "120",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
  {
    _id: "622",
    productId: "122",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
  {
    _id: "623",
    productId: "123",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
  {
    _id: "624",
    productId: "122",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
  {
    _id: "625",
    productId: "121",
    billingNo: "44521879ABC112",
    quantity: 250,
    sizeSymbol: "S",
    gender: "Male",
    color: "Red",
    price: 850,
    orderDate: new Date("09/11/2021"),
    images: [
      require("./assets/taylor-dG4Eb_oC5iM-unsplash.png"),
      require("./assets/ryan-hoffman-6Nub980bI3I-unsplash.png"),
      require("./assets/ian-dooley-wzRKn-AjKf4-unsplash.png"),
      require("./assets/ian-dooley-TT-ROxWj9nA-unsplash.png"),
    ],
    invoiceLink:
      "https://drive.google.com/file/d/1jbNbdK69dsQ_DRjS0D25iJHWHeJ6EFlr/view?usp=sharing",
    discount: 41,
  },
];
