import React, { useContext } from "react";
import { Image, CustomStepper } from "components";
import MemeTRegister from "assets/Meme-T-Register.png";
import { VendorContext } from "contexts";
import { VENDOR_ONBOARDING_STEPS } from "./steps";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const VendorOnboardingLayout = () => {
  const { currentVendor } = useContext(VendorContext);
  const vendorOnboardingSteps = VENDOR_ONBOARDING_STEPS.map(
    (onboardingStep, index) => ({
      ...onboardingStep,
      icon: <p className="vendorOnboarding-icon">{index + 1}</p>,
    })
  );

  return (
    <div className="vendorOnboarding">
      <Image imageURL={MemeTRegister} className="vendorOnboarding__left" />
      <div className="vendorOnboarding__right">
        <p className="vendorOnboarding__right-header">
          Meme - T Vendor Onboarding
        </p>
        <CustomStepper
          activeStep={currentVendor?.onboardingStep}
          steps={vendorOnboardingSteps}
          className="vendorOnboarding__right-steps"
          labelClass="vendorOnboarding__right-stepLabel"
        />
        <div className="vendorOnboarding__right-content">
          {VENDOR_ONBOARDING_STEPS[currentVendor?.onboardingStep].content}
        </div>
      </div>
    </div>
  );
};

export default VendorOnboardingLayout;
