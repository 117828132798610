import React, { useState, useEffect } from "react";
import {
  GridDisplay,
  Loading,
  Snackbar,
  CustomDrawer,
  DropdownInput,
  ConfirmationPopup,
  TextField,
} from "components";
import { useSnackbar } from "hooks";
import { getGridDisplayConfig } from "displayConfigs";
import { TicketServices } from "services";
import { AddTicketForm } from "../../index";
import TicketDetail from "./TicketDetail";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import { generateQueryParamsFromObject, generateDropdownOptions } from "utils";
import { TICKET_CATEGORY_OPTIONS } from "constants/TicketInputs";

const MyTicketsList = ({ ticketStatus, dataList }) => {
  const snackbar = useSnackbar();
  const [tickets, setTickets] = useState({
    list: [],
    loading: false,
  });
  const [ticketFilters, setTicketFilters] = useState({
    searchTerm: ``,
    category: TICKET_CATEGORY_OPTIONS[0],
  });
  const [ticketPopup, setTicketPopup] = useState({
    selectedTicketId: ``,
    isPopupOpen: false,
  });
  const [ticketDrawer, setTicketDrawer] = useState({
    isDrawerOpen: false,
    content: null,
    contentClass: ``,
  });

  useEffect(() => {
    setTickets({
      ...tickets,
      list: dataList?.filter(
        ({ activeStatus }) => activeStatus === ticketStatus
      ),
    });
  }, [ticketStatus, dataList, ticketFilters]);

  const fetchTicketsList = async (filters) => {
    try {
      setTickets({
        ...tickets,
        loading: true,
      });
      const query = generateQueryParamsFromObject({ ...filters });
      const { ticketsList } = await TicketServices.getTicketsList(query);
      setTickets({
        list: ticketsList,
        loading: false,
      });
    } catch (err) {
      console.error(err)
      setTickets({
        ...tickets,
        loading: false
      });
    }
  };

  const myTicketsConfig = getGridDisplayConfig("Tickets", {
    onViewDetail: (record) => openTicketDrawer("VIEW", record),
    onDeleteTicket: (record) =>
      setTicketPopup({
        isPopupOpen: true,
        selectedTicketId: record?._id,
      }),
    onTabEmpty: () => ({
      message:
        ticketStatus === "Created"
          ? "It seems you have no active tickets"
          : "Your tickets are under review",
      subMessage:
        ticketStatus === "Created"
          ? "Raise a ticket if you have faced any issue."
          : "Resolved tickets will be displayed here.",
    }),
    onEmptyListButtonClick: () => openTicketDrawer("ADD"),
  });

  const openTicketDrawer = (contentType, record) =>
    setTicketDrawer({
      isDrawerOpen: true,
      content:
        contentType === "ADD" ? (
          <AddTicketForm
            onCloseDrawer={() => {
              setTicketDrawer({ isDrawerOpen: false });
            }}
          />
        ) : (
          <TicketDetail
            onCloseDrawer={() => setTicketDrawer({ isDrawerOpen: false })}
            onDeleteTicket={() => confirmResolveTicket(record?._id)}
            ticketDetail={record}
          />
        ),
      contentClass:
        contentType === "ADD"
          ? `myTickets-ticketDrawer--add`
          : `myTickets-ticketDrawer--view`,
    });

  const confirmResolveTicket = (ticketId) =>
    setTicketPopup({
      isPopupOpen: true,
      selectedTicketId: ticketId,
    });

  const handleResolveTicket = async (ticketId) => {
    try {
      setTickets({
        ...tickets,
        loading: true,
      });
      const payload = {
        _id: ticketId,
        ticketStatus: {
          description: "Resolved",
          timestamp: new Date(),
        },
      };
      const { msg, ticketData } = await TicketServices.createUpdateTicket(
        payload
      );
      setTickets({
        list: tickets?.list.map((ticket) =>
          ticket?._id === ticketData?._id ? ticketData : ticket
        ),
        loading: false,
      });
      setTicketDrawer({ isDrawerOpen: false });
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
      setTickets({
        ...tickets,
        loading: false,
      });
    }
  };

  const handleConfirmAction = async () => {
    setTicketPopup({
      isPopupOpen: false,
    });
    await handleResolveTicket(ticketPopup?.selectedTicketId);
  };

  const handleSearchTermChange = ({ target }) => {
    const filterObj = {
      ...ticketFilters,
      searchTerm: target.value,
    };
    setTicketFilters(filterObj);
    fetchTicketsList(filterObj);
  };

  const handleCategorySelect = ({ target }) => {
    const filterObj = { ...ticketFilters, category: target.value };
    setTicketFilters(filterObj);
    fetchTicketsList(filterObj);
  };

  const confirmationMessage = (
    <div className="myTickets-popupContent">
      <p className="myTickets-popupContent-confirmQuestion">
        Are you sure you want to close your ticket?
      </p>
      <p className="myTickets-popupContent-confirmMessage">
        Your ticket is under review.
      </p>
    </div>
  );

  const { list, loading } = tickets;
  const { isDrawerOpen, content, contentClass } = ticketDrawer;
  const { isPopupOpen } = ticketPopup;
  const { searchTerm, category } = ticketFilters || {};

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        handleOk={handleConfirmAction}
        handleClose={() =>
          setTicketPopup({
            isPopupOpen: false,
            selectedTicketId: ``,
          })
        }
      >
        {confirmationMessage}
      </ConfirmationPopup>
      <div className="myTickets">
        <div className="myTickets__filters">
          <DropdownInput
            className="myTickets__filters-selectCategory"
            optionsList={generateDropdownOptions(TICKET_CATEGORY_OPTIONS)}
            selectedOption={category}
            onSelect={handleCategorySelect}
          />
          <TextField
            className="myTickets__filters-searchInput"
            type="text"
            value={searchTerm}
            placeholder="Search using title/description"
            onChange={handleSearchTermChange}
          />
        </div>
        <GridDisplay
          className="myTickets-overview"
          config={myTicketsConfig}
          dataList={list}
        />
        <CustomDrawer
          direction="bottom"
          className={contentClass}
          isOpen={isDrawerOpen}
          onCloseDrawer={() => setTicketDrawer({ isDrawerOpen: false })}
        >
          {content}
        </CustomDrawer>
      </div>
    </>
  );
};

export default MyTicketsList;
