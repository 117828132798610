import React, { memo, useCallback } from "react";
import { Grid } from "@mui/material";
import { DetailCard, IconDisplay } from "components";
import { displayCurrencyAmount } from "utils";
import "./index.css";
import "./mobile.css";

const VendorAnalytics = ({
  totalSales,
  walletBalance,
  yesterdaySales,
  salesIncrementPercent,
  revenueStats,
}) => {
  const percentageGrowthType = useCallback(
    (incrementPercent) => (incrementPercent > 0 ? "increment" : "decrement"),
    []
  );

  const analyticsList = [
    {
      header: "Total Sales",
      content: totalSales,
      subContent: "Lists totals sales starting from the day of registration",
      icon: (
        <IconDisplay type="" className={`analytics__card--right-totalSales`} />
      ),
    },
    {
      header: "Account Balance",
      content: displayCurrencyAmount(walletBalance),
      subContent: "Your Current Account Balance",
      icon: (
        <IconDisplay
          type="wallet"
          className={`analytics__card--right-accountBalance`}
        />
      ),
    },
    {
      header: "Yesterday's Sales",
      content: yesterdaySales,
      subContent: "Lists totals sales starting from the last 24 hours",
      icon: (
        <IconDisplay
          type={percentageGrowthType(salesIncrementPercent)}
          className={`analytics__card--right-${percentageGrowthType(
            salesIncrementPercent
          )}`}
        />
      ),
      incrementPercent: `${Math.abs(salesIncrementPercent)}%`,
    },
    {
      header: "Yesterday's Revenue",
      content: revenueStats?.yesterdayRevenue,
      subContent: "Adds total revenue of yesterday's sales",
      icon: (
        <IconDisplay
          type={percentageGrowthType(revenueStats?.incrementPercentRevenue)}
          className={`analytics__card--right-${percentageGrowthType(
            revenueStats?.incrementPercentRevenue
          )}`}
        />
      ),
      incrementPercent: `${Math.abs(revenueStats?.incrementPercentRevenue)}%`,
    },
  ];

  return (
    <Grid container spacing={2}>
      {analyticsList.map(
        ({ header, content, subContent, icon, incrementPercent }) => (
          <Grid item xs={6}>
            <DetailCard
              className="analytics__card"
              cardContent={
                <>
                  <div className="analytics__card--left">
                    <p className="analytics__card--left-header">{header}</p>
                    <p className="analytics__card--left-content">{content}</p>
                    <p className="analytics__card--left-subContent">
                      {subContent}
                    </p>
                  </div>
                  <p className="analytics__card--right">
                    {icon}
                    {incrementPercent}
                  </p>
                </>
              }
              contentClass="analytics__card-content"
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default memo(VendorAnalytics);
