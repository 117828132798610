import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import { styled } from "@mui/material/styles";
import { ErrorMessage } from "components";
import "./index.css";
import "./mobile.css";

const CustomAutocomplete = styled(Autocomplete)({
  tag: {
    backgroundColor: "#707070",
    height: 24,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "white",
    },
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "#707070",
      zIndex: -1,
    },
  },
});

const AutoCompleteTagsInput = ({
  dataList = [],
  name,
  errorText,
  handleChange,
  handleBlur,
  defaultValue,
  className,
  placeholder,
  inputLabel,
}) => (
  <div className="div__txtAutocomplete_tags">
    <p className="lblInput">{inputLabel}</p>
    <CustomAutocomplete
      multiple
      name={name}
      options={dataList}
      getOptionLabel={(option) => option}
      defaultValue={defaultValue}
      onChange={handleChange}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={placeholder}
          onBlur={handleBlur}
          className={className}
          margin="normal"
          fullWidth
        />
      )}
    />
    <ErrorMessage errorText={errorText} />
  </div>
);

export default AutoCompleteTagsInput;
