import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import VendorEmailOTP from "assets/Vendor/VendorEmailOTP.svg";
import {
  Loading,
  Snackbar,
  Button,
  TextInputField,
  Timer,
  Image,
} from "components";
import { VendorContext } from "contexts";
import { UserServices } from "services";
import { useSnackbar } from "hooks";
import "./index.css";
import "./mobile.css";

const VendorEmailVerification = () => {
  const snackbar = useSnackbar();
  const [showResendTimer, setShowResendTimer] = useState(true);
  const { currentVendor, setVendorOnboardingStep } = useContext(VendorContext);

  const handleResendOTP = async (setSubmitting) => {
    try {
      setSubmitting(true);
      const { msg } = await UserServices.sendEmailOTP({ ...currentVendor });
      setSubmitting(false);
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      setSubmitting(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const handleChangeEnteredEmail = () => setVendorOnboardingStep(0);

  return (
    <Formik
      initialValues={{
        enteredOTP: "",
      }}
      validationSchema={yup.object({
        enteredOTP: yup
          .string()
          .min(6, "Should be exactly 6 digits")
          .max(6, "Should be exactly 6 digits")
          .required("OTP is mandatory"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async ({ enteredOTP }, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await UserServices.verifyEmailOTP({
            email: currentVendor?.email,
            enteredOTP,
          });
          setSubmitting(false);
          setVendorOnboardingStep(2);
          // snackbar.showMessage({
          //   content: msg,
          //   handleClose: () => setVendorOnboardingStep(2),
          // });
        } catch (err) {
          setSubmitting(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        setSubmitting,
      }) => {
        return (
          <>
            {isSubmitting && <Loading />}
            <Snackbar {...snackbar} />
            <div className="vendorEmailOTP__formContainer">
              <div className="vendorEmailOTP__formContainer--left">
                <span className="vendorEmailOTP__formContainer--left-header">
                  OTP Verification
                </span>
                <div className="vendorEmailOTP__formContainer--left-OTP">
                  <p className="vendorEmailOTP__formContainer--left-OTPMessage">
                    To verify we have sent an OTP to your email.
                  </p>
                  <p className="vendorEmailOTP__formContainer--left-enteredEmail">
                    {currentVendor?.email}
                    <span
                      onClick={handleChangeEnteredEmail}
                      className="vendorEmailOTP__formContainer--left-changeEmail"
                    >
                      Change
                    </span>
                  </p>
                </div>
                <TextInputField
                  name="enteredOTP"
                  inputType="number"
                  className="vendorEmailOTP__formContainer--left-enteredOTP"
                  labelText="Please enter it below to complete verification."
                  placeholder="Enter 6 digit OTP Code"
                  inputValue={values.enteredOTP}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={touched.enteredOTP && errors.enteredOTP}
                />
                <Button
                  id="btnInput"
                  className="vendorEmailOTP__formContainer--left-verifyOTP"
                  onClick={handleSubmit}
                >
                  VERIFY
                </Button>
                <div className="vendorEmailOTP__formContainer--left-resendOTP">
                  {showResendTimer && (
                    <>
                      <span className="vendorEmailOTP__formContainer--left-resendOTP-link">
                        Didn't receive OTP? Resend OTP in
                      </span>
                      <Timer
                        timeout={60}
                        className="vendorEmailOTP__formContainer--left-resendOTP-timeout"
                        onTimerClear={() => setShowResendTimer(false)}
                      />
                    </>
                  )}
                  {!showResendTimer && (
                    <p
                      className="vendorEmailOTP__formContainer--left-resendOTP-action"
                      onClick={() => handleResendOTP(setSubmitting)}
                    >
                      Resend OTP
                    </p>
                  )}
                </div>
              </div>
              <Image
                imageURL={VendorEmailOTP}
                className="vendorEmailOTP__formContainer--right"
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default VendorEmailVerification;
