import { Axios } from "utils";

const UserServices = {};
const BASE_URL = process.env.REACT_APP_USERS_API;

UserServices.loginUser = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/login`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.verifyUserByPhone = async (phoneNumber) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/verifyPhone`, {
      headers: { phoneNumber }, // Sending via headers as encoded data
    });
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.changeUserPassword = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/changePassword`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.createUpdateUser = async (url, payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/${url}`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.authenticateUser = async () => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/auth`);
    if (data?.success) {
      return data?.userData;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.sendEmailOTP = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/sendOTP`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.verifyEmailOTP = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/verifyOTP`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.createUpdateAddress = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/address`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.fetchAddressList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/address?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.deleteSelectedAddress = async (addressId) => {
  try {
    const { data } = await Axios.delete(`${BASE_URL}/address/${addressId}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.logoutUser = async () => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/logout/`);
    if (data?.success) {
      return data?.msg;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.fetchUserWishlist = async () => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/wishlist/`);
    const { success, wishlist } = data || {};
    return success ? wishlist : [];
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.modifyUserWishlist = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/wishlist/`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.modifyUserCart = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/cart/`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

UserServices.getProfileData = async (id) => {
  try {
      const {data} =  await Axios.get(`/api/users/profile/${id}`);
      return data
  } catch (err) {
    return err?.response?.data?.msg;
  }
};

export default UserServices;
