export const displayRazorpay = (onSuccess, onError) => {
  try {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_RAZORPAY_URL;
    script.onload = onSuccess;
    script.onerror = onError;
    document.body.appendChild(script);
  } catch (err) {
    console.error(err);
    alert(`Error encountered during opening Razorpay payment window`);
  }
};

export const initiateRazorpayPayment = ({
  receipt,
  currency,
  amount,
  razorpayOrderId,
  name,
  email,
  phoneNumber,
  items,
  onPaymentComplete,
}) => {
  const products = items
    ?.map(({ inventoryId, quantity }) => `${inventoryId}&${quantity}`)
    .join(";");
  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    currency,
    amount: amount * 100,
    order_id: razorpayOrderId,
    name,
    description: `${name}'s Order`,
    //image: 'http://localhost:1337/logo.svg',
    handler: onPaymentComplete,
    prefill: {
      name,
      email,
      phoneNumber,
    },
    notes: {
      products,
      receipt,
    },
  };
  const razorpayWindow = new window.Razorpay(options);
  razorpayWindow.open();
};

export const generateOrderDataFromCart = (cart) => {
  const totalPrice = cart?.reduce(
    (totalVal, { price, quantity }) => totalVal + price * quantity,
    0
  );

  const totalDiscount = cart?.reduce(
    (totalVal, { inventoryId, price, quantity }) =>
      totalVal + (inventoryId?.discount * price * quantity) / 100,
    0
  );

  const products = cart?.map(
    ({ productId, inventoryId, quantity, price, vendorId }) => ({
      productId: productId?._id,
      inventoryId: inventoryId?._id,
      quantity,
      price,
      discount: inventoryId?.discount,
      vendorId,
    })
  );

  return { totalPrice, totalDiscount, products };
};

export const applyCardFormatter = ({
  formElem,
  cardNumElem,
  cardTypeElem,
  cardExpiryElem,
  cardCVVElem,
}) => {
  const formatter = window.Razorpay.setFormatter(formElem);

  formatter
    .add("card", cardNumElem)
    .on("network", function (_o) {
      const type = this.type; // e.g. 'visa'

      // set length of cvv element based on amex card
      const cvvlen = type === "amex" ? 4 : 3;
      cardCVVElem.maxLength = cvvlen;
      cardCVVElem.pattern = "^[0-9]{" + cvvlen + "}$";

      cardTypeElem.innerHTML = type;
    })
    .on("change", function () {
      const isValid = this.isValid();

      // automatically focus next field if card number is valid and filled
      if (isValid && this.el.value.length === this.caretPosition) {
        cardExpiryElem.focus();
      }
    });

  formatter.add("expiry", cardExpiryElem).on("change", function () {
    const isValid = this.isValid();

    // automatically focus next field if card number is valid and filled
    if (isValid && this.el.value.length === this.caretPosition) {
      cardCVVElem.focus();
    }
  });
};

export const calculateOrderCancellationDeadlineInSeconds = (dateVal) => {
  const currentTimestamp = Date.now();
  const estimatedDateTimestamp = new Date(dateVal);
  const cancelDeadlineTimestamp = estimatedDateTimestamp.setHours(
    estimatedDateTimestamp.getHours() + 12
  );
  return (cancelDeadlineTimestamp - currentTimestamp) / 1000;
};
