import IconDisplay from "components/IconDisplay";
import MyOrdersList from "pages/Orders/MyOrders/MyOrdersList";
import { ORDER_TABS_LIST } from "constants/OrderCategories";

const ORDER_TABS_CONFIG = {
  screenName: (
    <p className="myOrders__header-text">
      <IconDisplay type="shoppingBag" id="myOrders__header-iconShoppingBag" />{" "}
      My Orders
    </p>
  ),
  screenKey: "orders",
  tabsHeaderList: (dataList, onDataChange) =>
    ORDER_TABS_LIST.map(({ label, orderStatus, deliveryStatus }) => ({
      label,
      content: (
        <MyOrdersList
          orderStatus={orderStatus}
          deliveryStatus={deliveryStatus}
          dataList={dataList}
          onDataChange={onDataChange}
        />
      ),
    })),
  renderTabsList: [],
};

export default ORDER_TABS_CONFIG;
