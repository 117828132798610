import SEARCH_PRODUCT_GRID_CONFIG from "./SearchProductConfig";
import LATEST_ADDITIONS_GRID_CONFIG from "./LatestAdditionsConfig";
import LANGUAGE_DISPLAY_CONFIG from "./LanguageConfig";
import ORDERS_GRID_CONFIG from "./OrderDisplayConfig";
import CART_LIST_CONFIG from "./CartListConfig";
import WISHLIST_GRID_CONFIG from "./WishlistConfig";
import TOP_LANGUAGES_DISPLAY_CONFIG from "./TopLanguagesConfig";
import MEME_SUGGESTION_LIST_CONFIG from "./MemeSuggestionListConfig";
import TICKET_LIST_CONFIG from "./TicketListConfig";

const GRID_DISPLAY_CONFIG = {
  SearchProducts: SEARCH_PRODUCT_GRID_CONFIG,
  LatestAdditions: LATEST_ADDITIONS_GRID_CONFIG,
  Language: LANGUAGE_DISPLAY_CONFIG,
  TopLanguages: TOP_LANGUAGES_DISPLAY_CONFIG,
  Orders: ORDERS_GRID_CONFIG,
  Cart: CART_LIST_CONFIG,
  MemeSuggestion: MEME_SUGGESTION_LIST_CONFIG,
  Tickets: TICKET_LIST_CONFIG,
  Wishlist: WISHLIST_GRID_CONFIG
};

export default GRID_DISPLAY_CONFIG;
