import React, { useState, useContext } from "react";
import _ from "lodash";
import {
  DetailCard,
  Button,
  RowDetail,
  Image,
  Loading,
  Snackbar,
} from "components";
import { UserContext } from "contexts";
import { MemeSuggestionServices } from "services";
import { useSnackbar } from "hooks";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MemeSuggestionDetail = ({ memeSuggestionDetail, onCloseDrawer }) => {
  const { currentUser } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    _id,
    consumerName,
    createdAt,
    images,
    activeStatus,
    description,
    title,
    language,
  } = memeSuggestionDetail;

  const rowDetails = [
    { label: "Meme ID", detail: _id },
    { label: "Suggested By", detail: consumerName },
    { label: "Status", detail: activeStatus },
    { label: "Language", detail: language },
    { label: "Meme Title", detail: title },
    { label: "Meme Description", detail: description },
  ];

  const handleMemeDecision = async (isAccepted) => {
    try {
      setLoading(true);
      const payload = {
        _id,
        superAdminId: currentUser?._id,
        isAccepted,
      };
      const { msg } = await MemeSuggestionServices.createUpdateMemeSuggestion(payload);
      setLoading(false);
      snackbar.showMessage({
        content: msg,
        handleClose: onCloseDrawer
      });
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  return (
    <>
      <Snackbar {...snackbar} />
      {loading && <Loading />}
      <div className="memeSuggestionDetail-container">
        <div className="memeSuggestionDetail-container__heading">
          Meme Suggestions Details
        </div>
        <div className="memeSuggestionDetail-container__view">
          <div className="memeSuggestionDetail-container__leftGallery">
            <DetailCard
              className="memeSuggestionDetail-container__leftGallery-memeSuggestionImages"
              headerContent="Meme Suggestion Images"
              headerClass="memeSuggestionDetail-container__leftGallery-memeSuggestionImages-header"
              cardContent={
                <>
                  {!_.isEmpty(images) ? (
                    images.map((img) => (
                      <Image
                        className="memeSuggestionDetail-container__leftGallery-memeSuggestionImage"
                        imageURL={displayDriveImage(img)}
                      />
                    ))
                  ) : (
                    <p className="memeSuggestionDetail-container__leftGallery-noAttachments">
                      No attachments available
                    </p>
                  )}
                </>
              }
              contentClass="memeSuggestionDetail-container__leftGallery-memeSuggestionImages-content"
            />
          </div>
          <div className="memeSuggestionDetail-container__rightGallery">
            <DetailCard
              className="memeSuggestionDetail-container__rightGallery-memeSuggestionDetails-header"
              headerContent={
                <>
                  <p>Meme Description</p>
                  <p>
                    Created At: {displayDateInFormat(createdAt, "Do MMM YYYY")}
                  </p>
                </>
              }
              headerClass="memeSuggestionDetail-container__rightGallery-memeSuggestionDetails-header"
              cardContent={
                <>
                  {rowDetails.map(({ label, detail }) => (
                    <RowDetail label={label} description={detail} />
                  ))}
                </>
              }
              contentClass="memeSuggestionDetail-container__rightGallery-memeSuggestionDetails-content"
            />
            <div className="memeSuggestionDetail-container__actionButtons">
              <Button
                className="memeSuggestionDetail-container__actionButtons-reject"
                onClick={() => handleMemeDecision(false)}
              >
                Reject Meme
              </Button>
              <Button
                className="memeSuggestionDetail-container__actionButtons-approve"
                onClick={() => handleMemeDecision(true)}
              >
                Approve Meme
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemeSuggestionDetail;
