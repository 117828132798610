import React, { useState } from "react";
import { Button, CustomDrawer } from "components";
import "./index.css";
import AddressManagement from "../AddressManagement";

const SelectAddress = ({ address, onChangeAddress }) => {
  const {
    recipientName,
    contact,
    firstLine,
    secondLine,
    city,
    state,
    zipCode,
  } = address || {};

  const [showAddressDrawer, setShowAddressDrawer] = useState(false);

  const handleSelectAddress = (addressItem) => {
    setShowAddressDrawer(false);
    onChangeAddress(addressItem);
  };

  return (
    <>
      <div className="userCart__addressSelected">
        <p className="userCart__addressSelected-recipientName">
          {recipientName}
        </p>
        <p className="userCart__addressSelected-recipientAddress">
          {`${firstLine || ""}, ${secondLine || ""}, ${
            city?.toUpperCase() || ""
          }, ${state?.toUpperCase() || ""}, ${zipCode || ""}`}
        </p>
        <p className="userCart__addressSelected-recipientContact">
          Phone Number: {contact}
        </p>
        <Button
          id="btnInput"
          className="userCart__addressSelected-changeAddress"
          disableRipple
          onClick={() => setShowAddressDrawer(true)}
        >
          CHANGE OR ADD ADDRESS
        </Button>
      </div>
      <CustomDrawer
        direction="bottom"
        className="addressManagement__drawer"
        isOpen={showAddressDrawer}
        onCloseDrawer={() => setShowAddressDrawer(false)}
      >
        <AddressManagement
          onChangeAddress={(addressItem) => handleSelectAddress(addressItem)}
        />
      </CustomDrawer>
    </>
  );
};

export default SelectAddress;
