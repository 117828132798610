import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import "./index.css";

const RadioInput = ({
  name,
  inputLabel,
  className,
  labelClass,
  inputClass,
  selectedOption,
  optionsList,
  handleChange,
}) => {
  return (
    <FormControl component="fieldset" className={className}>
      <FormLabel component="legend" className={labelClass}>
        {inputLabel}
      </FormLabel>
      <RadioGroup value={selectedOption} name={name} onChange={handleChange}>
        {optionsList?.map(({ label, value }) => (
          <FormControlLabel
            key={value?.toString()}
            value={value}
            control={<Radio />}
            label={label}
            className={inputClass}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInput;
