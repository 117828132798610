import React, { useContext } from "react";
import { Table, ActionIcon } from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { InventoryContext } from "contexts";
import "./index.css";

const InitialInventoryList = ({ onSelectInventory }) => {
  const { newInventoryList, deleteSelectedInventoryItem } =
    useContext(InventoryContext);

  const inventoryConfig = getTableDisplayConfig("Inventory", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => console.log(`Preview`, rowVal)}
        />
        <ActionIcon
          type="edit"
          iconClassName="listIcon"
          onClick={() => onSelectInventory(rowVal)}
        />
        <ActionIcon
          type="delete"
          iconClassName="listIcon"
          onClick={async () => deleteSelectedInventoryItem(rowVal.id)}
        />
      </>
    ),
  });

  return (
    <Table config={inventoryConfig} dataList={newInventoryList} />
  );
};

export default InitialInventoryList;
