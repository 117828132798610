import IconDisplay from "components/IconDisplay";
import { OrderServices } from "services";

const ORDER_CONFIG = {
  screenName: "Order",
  header: (
    <p className="admin__ordersList-header">
      <IconDisplay type="inventory" className="" /> All Orders
    </p>
  ),
  columnHeaders: [
    { label: "Order Receipt", key: "receipt", type: "string" },
    {
      label: "Customer Name",
      key: "customerName",
      type: "string",
    },
    { label: "Ordered Items", key: "orderedItems", type: "number" },
    { label: "Total Pricing", key: "amount", type: "currency" },
    { label: "Order Date", key: "createdAt", type: "date" },
    { label: "Order Status", key: "activeOrderStatus", type: "string" },
    { label: "Delivery Status", key: "activeDeliveryStatus", type: "string" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  fetchListData: OrderServices.getOrdersList,
  dataListKey: "ordersList",
  dataUnavailableMessage: `No data to display`,
};

export default ORDER_CONFIG;
