import { Axios } from "utils";

const FeedbackServices = {};
const BASE_URL = process.env.REACT_APP_PRODUCT_FEEDBACKS_API

FeedbackServices.getProductFeedbacksList = async (query) => {
  try {
    const { data } = await Axios.get(
      `${BASE_URL}?${query}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

FeedbackServices.createUpdateFeedback = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/`, payload);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

FeedbackServices.deleteProductFeedback = async (feedbackId) => {
  try {
    const { data } = await Axios.delete(
      `${BASE_URL}/${feedbackId}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default FeedbackServices;
