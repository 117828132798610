import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import "./index.css";
import ErrorMessage from "../ErrorMessage";

const AutoCompleteInput = ({
  name,
  className,
  optionsList,
  value,
  handleChange,
  handleBlur,
  labelText,
  placeholder,
  errorText,
}) => {
  return (
    <div className="div__autoComplete-input">
      <p className="lblInput">{labelText}</p>
      <Autocomplete
        name={name}
        className={`txtAutoComplete ${className}`}
        blurOnSelect
        options={optionsList}
        autoHighlight
        getOptionLabel={(option) => option}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
        )}
        value={value}
        onChange={handleChange}
        onClose={handleBlur}
      />
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default AutoCompleteInput;
