import React from "react";
import { IconButton } from "@mui/material";
import { IconDisplay } from "components";

const ActionIcon = ({
  type,
  className,
  iconClassName,
  onClick,
  id,
  ...props
}) => {
  return (
    <IconButton onClick={onClick} className={className} {...props}>
      <IconDisplay type={type} id={id} className={iconClassName} />
    </IconButton>
  );
};

export default ActionIcon;
