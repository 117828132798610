import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";
import { MainLayout, ErrorPage } from "./components";
import {
  UserContext,
  SearchContextProvider,
  InventoryContextProvider,
  VendorContextProvider,
} from "contexts";
import { USER_ROLES } from "constants";
import { useResponsive } from "hooks";
import {
  AboutUs,
  LoginPage,
  VendorLoginPage,
  RegisterPage,
  OTPAuthPage,
  AddProductPage,
  CartPage,
  WishlistPage,
  ProductDetailPage,
  InventoryListPage,
  AddInventoryPage,
  ProductListPage,
  SearchProductsPage,
  MyMemeSuggestionsPage,
  TicketsListPage,
  MemeSuggestionsListPage,
  VendorMainDashboard,
  VendorWalletDashboard,
  VendorOnboardingLayout,
  // LandingPage,
  MyOrdersPage,
  OrdersListPage,
  MyTicketsPage,
  ProfilePage,
  CheckOut,
} from "./pages";
import RouteAuth from "auth";
import "./App.css";

const { VENDOR, SUPER_ADMIN } = USER_ROLES;

const ContextWrapper = ({
  Component,
  ContextProvider,
  pageTitle,
  ...props
}) => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return ContextProvider ? (
    <ContextProvider>
      <Component {...props} />
    </ContextProvider>
  ) : (
    <Component {...props} />
  );
};

const App = () => {
  const { deviceType } = useResponsive();
  const { currentUser } = useContext(UserContext);
  const homePageComponent =
    _.isEmpty(currentUser?._id) || _.isEqual(currentUser?._id, "GuestId")
      ? AboutUs
      : VendorMainDashboard;
  return !currentUser?._id ? null : (
    <BrowserRouter>
      <div className={classnames(`appContainer`, deviceType)}>
        <MainLayout>
          <div id="routesContainer">
            <Routes>
              <Route
                path="/"
                element={
                  <ContextWrapper
                    Component={homePageComponent}
                    ContextProvider={VendorContextProvider}
                    pageTitle="Meme-T | Home"
                  />
                }
              />
              {/* TODO: Comment out the routes during production */}
              <Route
                path="*"
                element={
                  <ContextWrapper
                    Component={() => ErrorPage({ statusCode: 404 })}
                    pageTitle="Meme-T | Page Not Found"
                  />
                }
              />
              <Route
                path="/unauthorized"
                element={
                  <ContextWrapper
                    Component={() => ErrorPage({ statusCode: 403 })}
                    pageTitle="Meme-T | Unauthorized"
                  />
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  <ContextWrapper
                    Component={OTPAuthPage}
                    pageTitle="Meme-T | OTP Auth"
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <ContextWrapper
                    Component={VendorLoginPage}
                    pageTitle="Meme-T | Login"
                  />
                }
              />
              <Route
                path="/register"
                element={
                  <ContextWrapper
                    Component={RegisterPage}
                    pageTitle="Meme-T | Register"
                  />
                }
              />
              <Route
                path="/profile/edit"
                element={
                  <ContextWrapper
                    Component={RegisterPage}
                    pageTitle="Meme-T | Edit Profile"
                  />
                }
              />
              <Route
                path="/vendor/onboarding"
                element={
                  <ContextWrapper
                    Component={VendorOnboardingLayout}
                    ContextProvider={VendorContextProvider}
                    pageTitle="Meme-T | Vendor | Onboarding"
                  />
                }
              />
              <Route
                path="/products/search"
                element={
                  <ContextWrapper
                    Component={SearchProductsPage}
                    ContextProvider={SearchContextProvider}
                    pageTitle="Meme-T | Search Products"
                  />
                }
              />
              <Route
                path="/product/:id"
                element={
                  <ContextWrapper
                    Component={ProductDetailPage}
                    pageTitle="Meme-T | Product Detail"
                  />
                }
              />
              <Route
                path="/cart"
                element={
                  <ContextWrapper
                    Component={CartPage}
                    pageTitle="Meme-T | My Cart"
                  />
                }
              />
              <Route
                path="/checkout"
                element={
                  <ContextWrapper
                    Component={CheckOut}
                    pageTitle="Meme-T | Custom Checkout"
                  />
                }
              />
              <Route
                path="/myOrders"
                element={
                  <ContextWrapper
                    Component={MyOrdersPage}
                    pageTitle="Meme-T | My Orders"
                  />
                }
              />
              <Route
                path="/myTickets"
                element={
                  <ContextWrapper
                    Component={MyTicketsPage}
                    pageTitle="Meme-T | My Tickets"
                  />
                }
              />
              <Route
                path="/myMemeSuggestions"
                element={
                  <ContextWrapper
                    Component={MyMemeSuggestionsPage}
                    pageTitle="Meme-T | My Meme Suggestions"
                  />
                }
              />
              <Route
                path="/wishlist"
                element={
                  <ContextWrapper
                    Component={WishlistPage}
                    pageTitle="Meme-T | My Wishlist"
                  />
                }
              />
              <Route
                path="/profile/:id"
                element={
                  <ContextWrapper
                    Component={ProfilePage}
                    pageTitle="Meme-T | My Profile"
                  />
                }
              />
              <Route
                path="/aboutUs"
                element={
                  <ContextWrapper
                    Component={AboutUs}
                    pageTitle="Meme-T | About Us"
                  />
                }
              />
              <Route
                path="/checkOut"
                element={
                  <ContextWrapper
                    Component={CheckOut}
                    pageTitle="Meme-T | Checkout"
                  />
                }
              />
              <Route
                element={<RouteAuth allowedRoles={[VENDOR, SUPER_ADMIN]} />}
              >
                <Route
                  path="/products/"
                  element={
                    <ContextWrapper
                      Component={ProductListPage}
                      pageTitle="Meme-T | Admin | Products"
                    />
                  }
                />
                <Route
                  path="/product/add"
                  element={
                    <ContextWrapper
                      Component={AddProductPage}
                      ContextProvider={InventoryContextProvider}
                      pageTitle="Meme-T | Admin | AddProduct"
                    />
                  }
                />
                <Route
                  path="/vendor/dashboard"
                  element={
                    <ContextWrapper
                      Component={VendorMainDashboard}
                      ContextProvider={VendorContextProvider}
                      pageTitle="Meme-T | Admin | Dashboard"
                    />
                  }
                />
                <Route
                  path="/vendor/wallet"
                  element={
                    <ContextWrapper
                      Component={VendorWalletDashboard}
                      ContextProvider={VendorContextProvider}
                      pageTitle="Meme-T | Admin | Wallet"
                    />
                  }
                />
                <Route
                  path="/product/update/:id"
                  element={
                    <ContextWrapper
                      Component={AddProductPage}
                      ContextProvider={InventoryContextProvider}
                      pageTitle="Meme-T | Admin | Update Product"
                    />
                  }
                />
                <Route
                  path="/product/:productId/inventory"
                  element={
                    <ContextWrapper
                      Component={InventoryListPage}
                      pageTitle="Meme-T | Admin | Inventories"
                    />
                  }
                />
                <Route
                  path="/product/:productId/inventory/add"
                  element={
                    <ContextWrapper
                      Component={AddInventoryPage}
                      ContextProvider={InventoryContextProvider}
                      pageTitle="Meme-T | Admin | Add Inventory"
                    />
                  }
                />
                <Route
                  path="/product/:productId/inventory/update/:id"
                  element={
                    <ContextWrapper
                      Component={AddInventoryPage}
                      ContextProvider={InventoryContextProvider}
                      pageTitle="Meme-T | Admin | Update Inventory"
                    />
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <ContextWrapper
                      Component={OrdersListPage}
                      pageTitle="Meme-T | Admin | Orders"
                    />
                  }
                />
                <Route
                  path="/tickets/"
                  element={
                    <ContextWrapper
                      Component={TicketsListPage}
                      pageTitle="Meme-T | Admin | Tickets"
                    />
                  }
                />
                <Route
                  path="/memeSuggestions/"
                  element={
                    <ContextWrapper
                      Component={MemeSuggestionsListPage}
                      pageTitle="Meme-T | Admin | MemeSuggestions"
                    />
                  }
                />
              </Route>
            </Routes>
          </div>
        </MainLayout>
      </div>
    </BrowserRouter>
  );
};

export default App;
