import { ProductCard } from "components";
import { ProductServices } from "services";

const RECOMMENDED_PRODUCTS_CONFIG = {
  screenName: "Your Cart",
  renderComponent: (value, { onAddToWishlist }) => (
    <ProductCard
      product={value}
      handleAddToWishlist={(ev) => {
        ev.stopPropagation();
        onAddToWishlist(value);
      }}
    />
  ),
  fetchList: ProductServices.getProductsList,
};

export default RECOMMENDED_PRODUCTS_CONFIG;
