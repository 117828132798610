import React from "react";
import { Image } from "components/Display";

const ImagePreview = ({ imageSource, labelText, className }) => {
  return (
    <div className="div_displayImage">
      {imageSource !== "" ? (
        <Image
          className={className}
          imageURL={imageSource}
          alt="productPreview"
        />
      ) : (
        <div className={className}>
          <span>No Image Chosen</span>
        </div>
      )}
      {labelText && <p>{labelText}</p>}
    </div>
  );
};

export default ImagePreview;
