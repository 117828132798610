import {
  VendorInitiate,
  VendorEmailVerification,
  VendorBasicDetails,
  VendorPhoneVerification,
  VendorAgreement,
} from "../pages";

export const VENDOR_ONBOARDING_STEPS = [
  {
    label: "Initialize",
    content: <VendorInitiate />,
  },
  {
    label: "Email Verification",
    content: <VendorEmailVerification />,
  },
  {
    label: "Basic Details",
    content: <VendorBasicDetails />,
  },
  {
    label: "Phone Number Verification",
    content: <VendorPhoneVerification />,
  },
  {
    label: "User Agreement",
    content: <VendorAgreement />,
  },
];
