import React, { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@mui/material";
import { ActionIcon } from "components";
import "./index.css";

const TransitionSlide = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const FormDialog = ({
  isPopupOpen,
  children,
  onClose,
}) => {

  return (
    <Dialog
      open={isPopupOpen}
      TransitionComponent={TransitionSlide}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <ActionIcon
          type="clear"
          className="closeButton"
          iconClassName="iconClose"
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
