import React, { useContext, useEffect, useState } from "react";
import { IconDisplay, Button, Grid, Loading, Image } from "components";
import ProfilePageBanner from "./ProfilePageBanner";
import { UserServices } from "services";
import { useParams } from "react-router-dom";
import { UserContext } from "contexts";
import { USER_ROLES } from "constants";
import "./index.css";
import "./tablet.css";
import "./mobile.css";
import { displayDateInFormat, displayDriveImage } from "utils/index";

const ProfilePage = () => {
  const [productsList, setProductsList] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const { currentUser } = useContext(UserContext);
  const { id } = useParams();
  const isCurrentUser = id === currentUser._id;
  const isCustomerView = currentUser?.role === USER_ROLES.CUSTOMER;

  const getProfileDataForId = async (userId) => {
    const { products, user: userFound } = await UserServices.getProfileData(
      userId
    );
    setProductsList(products);
    setUser(userFound);
    setLoading(false);
  };

  useEffect(() => {
    getProfileDataForId(id);
  }, [id]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          {user && (
            <ProfilePageBanner user={user} isCurrentUser={isCurrentUser} />
          )}
          <div className="profile-page__contentContainer">
            {isCustomerView && (
              <Button
                className="suggest-meme__btn"
                variant="contained"
                color="success"
                endIcon={<IconDisplay type="send" className="suggest-meme__btn-icon" />}
              >
                <span className="suggest-meme__btn-txt">
                  Suggest Meme
                </span>
              </Button>
            )}
            <Grid className="profile-page__gallery" container spacing={0}>
              {productsList?.map((product, index) => {
                console.log(product);
                return (
                  <Grid
                    className={`profile-page__gallery-card__container ${
                      index % 3 === 1 ? "displaced" : ""
                    }`}
                    key={index}
                    item
                  >
                    <Image
                      imageURL={displayDriveImage(product.images[0])}
                      className="profile-page__card-img"
                    />
                    <div className="profile-page__card-description">
                      <span className="profile-page__card-description-heading">
                        Uploaded On:&nbsp;&nbsp;
                        <span className="profile-page__card-description-content">
                          {displayDateInFormat(
                            new Date(product?.createdAt || Date.now()),
                            "DD MMM, yyy"
                          )}
                        </span>
                      </span>
                      <span className="profile-page__card-description-heading">
                        Meme description: &nbsp;
                      </span>
                      <span className="profile-page__card-description-content">
                        {product.punDescription}
                      </span>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default ProfilePage;
