const INVENTORY_CONFIG = {
  screenName: "Inventory",
  columnHeaders: [
    { label: "Billing No.", key: "billingNo", type: "string" },
    { label: "Quantity", key: "quantity", type: "number" },
    { label: "Size", key: "sizeSymbol", type: "string" },
    { label: "Gender", key: "gender", type: "string" },
    { label: "Color", key: "color", type: "string" },
    { label: "Price", key: "price", type: "currency" },
    { label: "Discount", key: "discount", type: "percent" },
    { label: "Order Date", key: "orderDate", type: "date" },
    { label: "Invoice", key: "invoiceLink", type: "downloadLink" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  dataUnavailableMessage: (
    <div className="div__noData-message">
      <p className="messageTitle">No Inventory Item added for this product.</p>
      <p className="messageDescription">
        You can only add the product when you have added at least one inventory
        product.
      </p>
    </div>
  ),
};

export default INVENTORY_CONFIG;
