import { EmptyListPlaceholder } from "components";
import EmptyCartImage from "assets/EmptyCart.png";
import CartItem from "pages/CartPage/CartItem";

const CART_LIST_CONFIG = {
  containerSpacing: 2,
  rowDivisions: 12,
  renderComponent: (
    value,
    { onQuantityChange, onAddToWishlist, onDeleteItem }
  ) => (
    <CartItem
      cartItem={value}
      onQuantityChange={onQuantityChange}
      onAddToWishlist={() => onAddToWishlist(value)}
      onDeleteItem={() => onDeleteItem(value)}
    />
  ),
  emptyDataContent: ({ onEmptyCartButtonClick }) => (
    <EmptyListPlaceholder
      placeholderImage={EmptyCartImage}
      imageClass="imgEmptyCart"
      message="There is nothing in your cart."
      subMessage="Why don't you go shopping?"
      onButtonClick={onEmptyCartButtonClick}
      buttonLabel="Continue Shopping"
    />
  ),
};

export default CART_LIST_CONFIG;
