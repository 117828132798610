import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  TextInputField,
  DropdownInput,
  SizeSelector,
  ColorPickerInput,
  AddImagesWithPreview,
  DateTimePickerInput,
  FileUpload,
  CircularProgress,
  ActionIcon,
  Button,
  IconDisplay,
  Loading,
  Snackbar,
} from "components";
import { GENDER_OPTIONS, SIZE_OPTIONS, FILE_TYPES } from "constants";
import { deleteSelectedDriveImages } from "utils";
import { InventoryContext } from "contexts";
import { InventoryServices } from "services";
import { useSnackbar } from "hooks";
import "./index.css";

const { FILE_PDF } = FILE_TYPES;

const AddInventoryPage = ({ inventoryItem, onCloseDrawer }) => {
  const {
    selectedInventoryItem,
    modifySelectedInventoryItem,
    addSelectedInventoryItem,
  } = useContext(InventoryContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const {
    _id,
    id,
    billingNo,
    quantity,
    price,
    discount,
    color,
    sizeSymbol,
    orderDate,
    gender,
    images,
    invoiceLink,
  } = inventoryItem || selectedInventoryItem || {};
  const productId = params?.productId;
  const [dateSelected, setDateSelected] = useState(new Date());
  const [invoiceURL, setInvoiceURL] = useState("");
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [size, setSize] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [imagesList, setImagesList] = useState([]);

  const buttonText = `${_id || id ? "Update" : "Add"} Inventory`;

  useEffect(() => {
    setDateSelected(orderDate || new Date());
    setSize(sizeSymbol || SIZE_OPTIONS[0]);
    setInvoiceURL(invoiceLink || "");
    setImagesList(images || []);
    return () => {
      if (_.isEmpty(_id || id)) deleteSelectedDriveImages(imagesList);
    };
  }, [orderDate, images, invoiceLink, sizeSymbol]);

  const invoicePreview = invoiceURL ? (
    <a
      className="invoiceLink"
      href={invoiceURL}
      target="_blank"
      rel="noreferrer"
    >
      Invoice
    </a>
  ) : (
    <p className="noInvoice">No file chosen</p>
  );

  return (
    <Formik
      initialValues={{
        quantity: quantity || "",
        price: price || "",
        discount: discount || "",
        color: color || "#fff",
        gender: gender || GENDER_OPTIONS[0].value,
        billingNo: billingNo || "",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        quantity: Yup.number()
          .required("Quantity is mandatory")
          .positive("Quantity should be positive"),
        price: Yup.number()
          .positive("Amount should be greater than zero")
          .required("Price is mandatory"),
        discount: Yup.number(),
        color: Yup.string(),
        gender: Yup.string()
          .required("Gender is mandatory")
          .test("gender", "Gender is Mandatory", (value) => {
            return value !== "Select";
          }),
        billingNo: Yup.string().required("Billing No. is mandatory"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values) => {
        if (!size) {
          setSizeError("Size is mandatory");
          return;
        }
        setLoading(true);
        let dataPayload = {
          productId,
          quantity: values.quantity,
          price: parseInt(values.price),
          discount: parseInt(values.discount),
          sizeSymbol: size,
          gender: values.gender,
          color: values.color,
          billingNo: values.billingNo,
          orderDate: dateSelected,
          images: imagesList,
          invoiceLink: invoiceURL,
        };

        if (onCloseDrawer) {
          dataPayload = {
            ...dataPayload,
            newInventory: true,
            id: id || Date.now(),
          };
        }

        if (_id) {
          dataPayload = {
            ...dataPayload,
            _id,
          };
        }

        try {
          if (_.isEmpty(_id)) {
            if (id) {
              modifySelectedInventoryItem(id, dataPayload);
            } else {
              addSelectedInventoryItem(dataPayload);
            }
            onCloseDrawer && onCloseDrawer(dataPayload);
          } else {
            const msg = await InventoryServices.createUpdateProductInventory(
              dataPayload
            );
            setLoading(false);
            snackbar.showMessage({
              content: msg,
              handleClose: () => onCloseDrawer(dataPayload),
            });
          }
        } catch (err) {
          snackbar.showMessage({
            content: err,
          });
          setLoading(false);
        }
      }}
    >
      {(formikProps) => {
        return (
          <div className="div__addInventoryForm">
            {loading && <Loading />}
            <Snackbar {...snackbar} />
            <p className="headerAddInventory">
              {`${_id ? "Update" : "Add"} Inventory Item`}
            </p>
            <div className="div__addInventoryForm-container">
              <div className="div__addInventoryForm-primary">
                <div className="div__addInventoryForm-primary-finance">
                  <TextInputField
                    name="quantity"
                    inputType="text"
                    labelText="Quantity"
                    inputValue={formikProps.values.quantity}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.quantity &&
                      formikProps.errors.quantity
                    }
                  />
                  <TextInputField
                    name="price"
                    inputType="number"
                    labelText={`Price (\u20A8).`}
                    inputValue={formikProps.values.price}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.price && formikProps.errors.price
                    }
                  />
                  <TextInputField
                    name="discount"
                    inputType="number"
                    labelText="Discount %"
                    inputValue={formikProps.values.discount}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.discount &&
                      formikProps.errors.discount
                    }
                  />
                </div>
                <div className="div__addInventoryForm-primary-dropdown">
                  <SizeSelector
                    labelText="Size"
                    sizeOptions={SIZE_OPTIONS}
                    sizeSelected={size}
                    onSelectOption={(sizeVal) => setSize(sizeVal)}
                    errorText={sizeError}
                  />
                  <DropdownInput
                    name="gender"
                    className="selectGender"
                    selectedOption={formikProps.values.gender}
                    labelText="Gender"
                    optionsList={GENDER_OPTIONS}
                    onSelect={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.gender && formikProps.errors.gender
                    }
                  />
                </div>
                <div className="div__addInventoryForm-secondary-dropdown">
                  <TextInputField
                    name="billingNo"
                    inputType="text"
                    labelText="Billing No"
                    inputValue={formikProps.values.billingNo}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.billingNo &&
                      formikProps.errors.billingNo
                    }
                  />
                  <ColorPickerInput
                    name="color"
                    className="txtColorPicker"
                    labelText="Color"
                    selectedColor={formikProps.values.color}
                    onSelectColor={(colorVal) =>
                      formikProps.setFieldValue("color", `#${colorVal.hex}`)
                    }
                    errorText={
                      formikProps.touched.color && formikProps.errors.color
                    }
                  />
                </div>
                <div className="div__addInventoryForm-orderDetails">
                  <DateTimePickerInput
                    labelText="Order Date"
                    className="inventoryOrderDatePicker"
                    dateValue={dateSelected}
                    handleChange={(dateVal) => setDateSelected(dateVal?._d)}
                  />

                  <div className="div__addInventoryForm-invoice">
                    {invoiceLoading ? (
                      <CircularProgress
                        className="invoiceUploading"
                        color="warning"
                      />
                    ) : (
                      <>
                        <FileUpload
                          isSingle={true}
                          htmlFor="uploadInvoice"
                          acceptFileTypes={FILE_PDF}
                          onLoading={(loadVal) => setInvoiceLoading(loadVal)}
                          onFileSelect={(resURL) => setInvoiceURL(resURL)}
                        >
                          <div id="uploadInvoice" className="btnUploadInvoice">
                            <IconDisplay type="upload" />
                            <p>Upload Invoice</p>
                          </div>
                        </FileUpload>
                        {invoicePreview}
                        {invoiceURL && (
                          <ActionIcon
                            type="delete"
                            iconClassName="deleteInvoice"
                            onClick={() => setInvoiceURL("")}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Button
                  size="large"
                  className="btnSubmitInventory"
                  onClick={formikProps.handleSubmit}
                >
                  {buttonText}
                </Button>
              </div>
              <div className="div__addInventoryForm-secondary">
                <AddImagesWithPreview
                  labelInput="Add Inventory Images"
                  htmlFor="addInventoryImage"
                  imagesList={imagesList}
                  onImageUpload={(imgList) => setImagesList(imgList)}
                  destinationFolderId={
                    process.env.REACT_APP_DRIVE_PRODUCTS_FOLDER_ID
                  }
                  handleRemoveImage={async (imageURL) => {
                    try {
                      const imagesDeleted = await deleteSelectedDriveImages([
                        imageURL,
                      ]);
                      if (imagesDeleted) {
                        setImagesList((prevList) =>
                          prevList.filter((imgURL) => imgURL !== imageURL)
                        );
                      }
                    } catch (err) {
                      snackbar.showMessage({
                        content: err,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddInventoryPage;
