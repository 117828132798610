import React, { useState, useEffect, useContext } from "react";
import { SlidingCarousel } from "components";
import { UserContext } from "contexts";
import { generateQueryParamsFromObject, encodeDecodeData } from "utils";
import { getCarouselDisplayConfig } from "displayConfigs";
import _ from "lodash";

const RecommendedProducts = ({ headerTitle, className, setLoading }) => {
  const { currentUser, modifyCurrentUserWishlist } = useContext(UserContext);
  const [productsList, setProductsList] = useState([]);

  const recommendedProductsConfig = getCarouselDisplayConfig(
    "RecommendedProducts",
    {
      onAddToWishlist: ({ _id, inWishlist }) => {
        setProductsList((prevList) => {
          const isSuccess = modifyCurrentUserWishlist(_id, inWishlist);
          return prevList.map((prod) => {
            if (prod._id === _id) {
              return {
                ...prod,
                inWishlist: isSuccess && !inWishlist,
              };
            }
            return prod;
          });
        });
      },
    }
  );

  useEffect(() => {
    fetchRecommendedProducts();
  }, []);

  const fetchRecommendedProducts = async () => {
    setLoading(true);
    const wishlist = (currentUser?.wishlist || []).join(";");
    try {
      const query = generateQueryParamsFromObject({
        _w: !_.isEmpty(wishlist) && encodeDecodeData(wishlist, "encode"),
      });
      const res = await recommendedProductsConfig.fetchList(query);
      setProductsList(res);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className={className}>
      <p className="lblRecommendedProducts">{headerTitle}</p>
      <SlidingCarousel
        config={recommendedProductsConfig}
        itemsList={productsList}
      />
    </div>
  );
};

export default RecommendedProducts;
