import React from "react";
import _ from "lodash";
import { DetailCard, Image, RowDetail } from "components";
import "./index.css";
import OrderItems from "pages/Orders/MyOrders/MyOrdersList/OrderDetail/OrderItems";

const TicketDetail = ({ ticketDetail }) => {
  const {
    description,
    category,
    attachments,
    assistantReply,
    activeStatus,
    statusList,
    orderItems,
  } = ticketDetail;

  return (
    <div className="ticket__detail-container">
      <p className="ticket__detail-container-header">TICKET DETAILS</p>
      <div className="ticket__detail-container-content">
        {orderItems && (
          <div className="ticket__detail--left">
            <OrderItems products={orderItems} />
          </div>
        )}
        <div className="ticket__detail--right">
          <RowDetail
            className="ticket__detail--right-category"
            label="Category"
            description={`${category} Ticket`}
          />
          <RowDetail
            className="ticket__detail--right-category"
            label="Status"
            description={activeStatus || _.last(statusList)?.description}
          />
          <DetailCard
            className="ticket__detail--right-images"
            headerContent="Ticket Images"
            headerClass="ticket__detail--right-images-header"
            cardContent={
              <div className="ticket__detail--right-imagesList">
                {(!_.isEmpty(attachments)
                  ? attachments
                  : [
                      require("../../../../../tests/assets/louisse-lemuel-enad-Ba8JB_A7www-unsplash.png"),
                      require("../../../../../tests/assets/louisse-lemuel-enad-Ba8JB_A7www-unsplash.png"),
                    ]
                )?.map((imageURL) => (
                  <Image
                    key={imageURL}
                    imageURL={imageURL}
                    className={`ticket__detail--right-imagesList_image`}
                  />
                ))}
              </div>
            }
          />
          <div className="ticket__detail--right-details">
            <p className="ticket__detail--right-details-titleLabel">
              Ticket Description:
            </p>
            <p className="ticket__detail--right-details-titleDescription">
              {description}
            </p>
            <p className="ticket__detail--right-details-replyLabel">
              Admin Reply:
            </p>
            <p className="ticket__detail--right-details-replyDescription">
              {assistantReply}
              {_.isEmpty(assistantReply) && (
                <span className="ticket__detail--right-details-replyPlaceholder">
                  The admin will get back to you shortly
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
