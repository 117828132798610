import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { GridDisplay, IconDisplay, Loading, Snackbar } from "components";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { getGridDisplayConfig } from "displayConfigs";
import RecommendedProducts from "./RecommendedProducts";
import OrderCheckout from "./OrderCheckout";
import "./index.css";
import "./tablet.css";
import "./mobile.css";
const CartPage = () => {
  const { currentUser, modifyCurrentUserCart, modifyCurrentUserWishlist } =
    useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [cartList, setCartList] = useState([]);
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setCartList(currentUser?.cart || []);
  }, [currentUser?.cart]);

  const cartSnackbar = (iconType, msg) => (
    <div className="div__snackbar-cart">
      <p>{msg}</p>
      <IconDisplay type={iconType} className="iconCart" />
    </div>
  );

  const cartItemConfig = getGridDisplayConfig("Cart", {
    onQuantityChange: async (inventoryId, quantity) => {
      setLoading(true);
      const { msg } = await modifyCurrentUserCart({
        inventoryId,
        quantity,
        method: "UPDATE",
      });
      setLoading(false);
      snackbar.showMessage({
        content: cartSnackbar("shoppingCart", msg),
      });
    },
    onAddToWishlist: async ({ productId }) => {
      setLoading(true);
      const isSuccess = await modifyCurrentUserWishlist(
        productId?._id,
        productId?.inWishlist
      );
      setLoading(false);
      if (isSuccess) {
        setCartList((prevList) =>
          prevList.map((prod) => {
            if (prod.productId?._id === productId?._id) {
              return {
                ...prod,
                productId: {
                  ...prod?.productId,
                  inWishlist: !productId?.inWishlist,
                },
              };
            }
            return prod;
          })
        );
      }
      snackbar.showMessage({
        content: cartSnackbar(
          "favorite",
          `${productId?.title} ${
            productId?.inWishlist ? "removed from" : "added to"
          } your wishlist`
        ),
      });
    },
    onDeleteItem: async ({ inventoryId }) => {
      setLoading(true);
      const { msg } = await modifyCurrentUserCart({
        inventoryId: inventoryId?._id,
        method: "REMOVE",
      });
      setLoading(false);
      snackbar.showMessage({
        content: cartSnackbar("shoppingCart", msg),
      });
    },
    onEmptyCartButtonClick: () => navigate(`/products/search`),
  });

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="div__userCart">
        <div className="div__userCart-container">
          <p className="div__userCart-container-header">
            <IconDisplay type="shoppingCart" id="iconCart" /> My Cart
          </p>
          <div className="div__userCart-overview">
            <GridDisplay
              className="div__userCart-grid"
              config={cartItemConfig}
              dataList={cartList}
            />
            {!_.isEmpty(currentUser?.cart) && (
              <OrderCheckout
                currentUser={currentUser}
              />
            )}
          </div>
        </div>
        <div className="horizontalDivider" />
        <RecommendedProducts
          headerTitle="Recommendations for you"
          className="div__userCart-recommendedItems"
          setLoading={setLoading}
        />
      </div>
    </>
  );
};

export default CartPage;
