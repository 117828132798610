import React from "react";
import { Axios } from "utils";

const FileUploader = ({
  isSingle,
  htmlFor,
  acceptFileTypes,
  onLoading,
  destinationFolderId,
  onUploadSuccess,
  onUploadError,
  children,
}) => {
  const onFileChosen = async ({ target }) => {
    const formData = new FormData();
    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    const files = target.files;
    if (files.length) {
      onLoading(true);
      for (let iter = 0; iter < files.length; iter++) {
        formData.append("images", files.item(iter));
      }
      formData.append("destinationFolderId", destinationFolderId);
      try {
        const {
          data: { savedImages },
        } = await Axios.post(`/images`, formData, config);
        onLoading(false);
        target.value = "";
        onUploadSuccess(isSingle ? savedImages[0] : savedImages);
      } catch (err) {
        onLoading(false);
        target.value = "";
        console.error(err);
        onUploadError();
      }
    }
  };

  return (
    <label htmlFor={htmlFor} className="lblUpload">
      <input
        accept={acceptFileTypes}
        id={htmlFor}
        multiple={!isSingle}
        type="file"
        onChange={onFileChosen}
        hidden
      />
      {children}
    </label>
  );
};

export default FileUploader;
