import { useState } from "react";
import { Image, DropdownInput, Button } from "components";
import { NET_BANKING_PAYMENTS } from "constants";
import "./index.css";

const NetBankingForm = ({ paymentAmount, onPayment }) => {
  const [selectedBank, setSelectedBank] = useState(`Select from other banks`);
  const handleNetBankingPayment = () =>
    onPayment({
      method: "netbanking",
      bank: selectedBank,
    });

  return (
    <div className="netBanking__form">
      <div className="netBanking__form--topBanks">
        {NET_BANKING_PAYMENTS.slice(0, 6).map(({ value, image }) => (
          <div
            key={value}
            className="netBanking__form--bank"
            onClick={() => setSelectedBank(value)}
          >
            <Image imageURL={image} className="netBanking__form--bank-image" />
          </div>
        ))}
      </div>
      <DropdownInput
        className="netBanking__form--otherBanks"
        optionsList={NET_BANKING_PAYMENTS.map(({ value, label }) => ({
          label,
          value,
        }))}
        selectedOption={selectedBank}
        placeholder="Select from other banks"
        onSelect={({ target: { value } }) => setSelectedBank(value)}
      />
      <Button onClick={handleNetBankingPayment} id="button_checkoutPayment">
        Pay {paymentAmount}
      </Button>
    </div>
  );
};

export default NetBankingForm;
