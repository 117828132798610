import React, { useState, useContext } from "react";
import { Collapse } from "@mui/material";
import { UserContext } from "contexts";
import OrderFeedbackForm from "./OrderFeedbackForm";
import ProductFeedbackForm from "./ProductFeedbackForm";
import "./index.css";

const OrderFeedback = ({
  orderId,
  orderItems,
  feedbackDetail,
  isPreview,
  onCloseDrawer,
}) => {
  const { currentUser } = useContext(UserContext);
  const [orderFeedbackSubmitted, setOrderFeedbackSubmitted] = useState(false);

  return (
    <>
      {!orderFeedbackSubmitted && (
        <OrderFeedbackForm
          customerId={currentUser?._id}
          orderId={orderId}
          feedbackDetail={feedbackDetail}
          isPreview={isPreview}
          onSubmitForm={() => setOrderFeedbackSubmitted(true)}
        />
      )}
      <Collapse in={orderFeedbackSubmitted} timeout="auto" unmountOnExit>
        <ProductFeedbackForm
          customerId={currentUser?._id}
          orderItems={orderItems}
          feedbackDetail={feedbackDetail}
          onSubmitForm={onCloseDrawer}
        />
      </Collapse>
    </>
  );
};

export default OrderFeedback;
