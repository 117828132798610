import React, { useState } from "react";
import { Button, Image } from "components";
import { TextField } from "@material-ui/core";
import UPILogo from "assets/UPILogo.png";
import "./index.css";

const PayUsingUPI = ({ paymentAmount, onPayment }) => {
  const [UPI, setUPI] = useState(``);

  const handleUPIPayment = () =>
    onPayment({
      method: "upi",
      upi: {
        vpa: UPI,
        flow: "collect",
      },
    });

  return (
    <div className="PaymentUPI__container">
      <div className="PaymentUPI__header">PAY USING UPI ID</div>

      <div className="PaymentUPI__Card">
        <Image imageURL={UPILogo} className="img__UPIlogo" />
        <div className="PaymentUPI__subheader">
          Google Pay, BHIM, PhonePe and more
        </div>
        <div className="label__inputID">Enter your UPI ID</div>
        <div className="PaymentUPI__inputID">
          <TextField
            value={UPI}
            onChange={({ target: { value } }) => setUPI(value)}
            placeholder="johnsmith@okbankname"
          ></TextField>
        </div>
      </div>

      <Button onClick={handleUPIPayment} id="button_checkoutPayment">
        Pay {paymentAmount}
      </Button>
    </div>
  );
};

export default PayUsingUPI;
