import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const RowDetail = ({ label, description, className }) => {
  return (
    <div className={`rowDetail__container ${className}`}>
      {label && <p className="rowDetail__label">{label}</p>}
      {description && <p className="rowDetail__description">{description}</p>}
    </div>
  );
};

export default RowDetail;

RowDetail.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

RowDetail.defaultProps = {
  label: "",
  description: "",
  className: "",
};
