import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  DetailCard,
  IconDisplay,
  ActionIcon,
  PaginatedTable,
  CustomDrawer,
  EmptyListPlaceholder,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import EmptyLatestTicketsListImage from "assets/Vendor/EmptyVendorTickets.png";
import ReplyTicket from "pages/Admin/Tickets/TicketsList/ReplyTicket";
import "./index.css";

const LatestTickets = ({ ticketsList }) => {
  const [selectedTicket, setSelectedTicket] = useState();
  const ticketConfig = getTableDisplayConfig("Ticket", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedTicket(rowVal)}
        />
      </>
    ),
  });

  return (
    <>
      <DetailCard
        className="vendorDashboard__gridContainer--latestTickets"
        headerClass="vendorDashboard__gridContainer--latestTickets-header"
        headerContent={
          <>
            <span>
              <IconDisplay
                type="ticket"
                className="vendorDashboard__gridContainer--latestTickets-icon"
              />{" "}
              New Tickets
            </span>
            {!_.isEmpty(ticketsList) && (
              <Link
                to={`/tickets`}
                className="vendorDashboard__gridContainer--latestTickets-header-viewAllTickets"
              >
                View All Tickets
              </Link>
            )}
          </>
        }
        contentClass="vendorDashboard__gridContainer--latestTickets-content"
        cardContent={
          <>
            {_.isEmpty(ticketsList) && (
              <EmptyListPlaceholder
                placeholderImage={EmptyLatestTicketsListImage}
                imageClass="vendorDashboard__gridContainer--latestTickets-imgEmptyList"
                message={`Any tickets raised by our consumers will be displayed here.`}
              />
            )}
            {!_.isEmpty(ticketsList) && (
              <PaginatedTable config={ticketConfig} dataList={ticketsList} />
            )}
          </>
        }
      />
      <CustomDrawer
        direction="bottom"
        className="admin__ticketsList__ticketDrawer--view"
        isOpen={!_.isUndefined(selectedTicket)}
        onCloseDrawer={() => setSelectedTicket(undefined)}
      >
        {selectedTicket && (
          <ReplyTicket
            ticketDetail={selectedTicket}
            onCloseDrawer={() => setSelectedTicket(undefined)}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default LatestTickets;
