import React from "react";
import { ActionIcon, Image } from "components";
import "./index.css";

const ShortImagePreview = ({
  imageURL,
  isMainImage,
  setAsMainImage,
  onRemoveImage,
}) => {
  return (
    <div
      className={`div-uploadedImage ${
        isMainImage ? "div-uploadedImage-selected" : ""
      }`}
      onClick={setAsMainImage}
    >
      {onRemoveImage && (
        <ActionIcon
          type="delete"
          className="removeIcon"
          onClick={(ev) => {
            ev.stopPropagation();
            onRemoveImage();
          }}
        />
      )}
      <Image className="imageItem" imageURL={imageURL} />
    </div>
  );
};

export default ShortImagePreview;
