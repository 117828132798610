import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, Button } from "components";
import UnauthorizedPageImage from "assets/UnauthorizedPage.png";
import PageNotFoundImage from "assets/PageNotFound.png";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const ErrorPage = ({ statusCode = 403 }) => {
  const isUnauthorized = statusCode === 403;
  const imageDisplay = isUnauthorized
    ? UnauthorizedPageImage
    : PageNotFoundImage;

  const messageHeader = "We are Sorry";
  const messageContent = isUnauthorized
    ? "You have wandered beyond boundaries,"
    : "The page you are looking for was not found,";

  const imgClassName = isUnauthorized ? "imgUnauthorizedPage" : "imgPageNotFound";

  const navigate = useNavigate();
  return (
    <div className="div__unauthorizedPage">
      <Image imageURL={imageDisplay} className={imgClassName} />
      <p className="lblUnauthorizedHeader">{messageHeader}</p>
      <p className="lblUnauthorizedMessage">{messageContent}</p>
      <p className="lblUnauthorizedMessage2">
        if you think you are seeing this by our mistake please reach out to
        <span className="lblHelpEmail">help@memet.com</span>
      </p>
      <Button
        id="btnInput"
        className="btnGoBack"
        disableRipple
        onClick={() => navigate(-1)}
      >
        GO BACK
      </Button>
    </div>
  );
};

export default ErrorPage;
