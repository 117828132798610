import React from "react";

const Image = ({ imageURL, className, backgroundSize = "cover", ...props }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${imageURL})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: backgroundSize,
        backgroundPosition: "center",
      }}
      className={className}
      {...props}
    />
  );
};

export default Image;
