import React from "react";
import { DetailCard, Image } from "components";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";
import _ from "lodash";

const MemeSuggestionCard = ({
  memeSuggestion,
  onWithdrawMeme,
  onViewDetail,
}) => {
  const { title, description, images, createdAt } = memeSuggestion;

  return (
    <DetailCard
      className="myMemeSuggestions-card"
      headerContent={
        <>
          <p>Suggested On</p>
          <p>{displayDateInFormat(createdAt, "Do MMMM YYYY")}</p>
        </>
      }
      cardContent={
        <div className="myMemeSuggestions-card-content">
          <Image
            imageURL={!_.isEmpty(images) && displayDriveImage(images[0])}
            className="myMemeSuggestions-card-content-image"
          />
          <div className="myMemeSuggestions-card-content-details">
            <p className="myMemeSuggestions-card-content-details-title">
              {title}
            </p>
            <p className="myMemeSuggestions-card-content-details-description">
              <span className="myMemeSuggestions-card-content-details-descHeader">
                Pun Description:
              </span>{" "}
              {description ||
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua...`}
            </p>
          </div>
        </div>
      }
      actionButtons={
        <>
          <div onClick={onWithdrawMeme}>Withdraw Meme</div>
          <div className="verticalDivider" />
          <div onClick={onViewDetail}>View Details</div>
        </>
      }
    />
  );
};

export default MemeSuggestionCard;
