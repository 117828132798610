export const COUNTRY_CODES_LIST = [
  { countryCode: "IN", label: "India", countryCodeVal: "91" },
  { countryCode: "AD", label: "Andorra", countryCodeVal: "376" },
  {
    countryCode: "AE",
    label: "United Arab Emirates",
    countryCodeVal: "971",
  },
  { countryCode: "AF", label: "Afghanistan", countryCodeVal: "93" },
  {
    countryCode: "AG",
    label: "Antigua and Barbuda",
    countryCodeVal: "1-268",
  },
  { countryCode: "AI", label: "Anguilla", countryCodeVal: "1-264" },
  { countryCode: "AL", label: "Albania", countryCodeVal: "355" },
  { countryCode: "AM", label: "Armenia", countryCodeVal: "374" },
  { countryCode: "AO", label: "Angola", countryCodeVal: "244" },
  { countryCode: "AQ", label: "Antarctica", countryCodeVal: "672" },
  { countryCode: "AR", label: "Argentina", countryCodeVal: "54" },
  { countryCode: "AS", label: "American Samoa", countryCodeVal: "1-684" },
  { countryCode: "AT", label: "Austria", countryCodeVal: "43" },
  {
    countryCode: "AU",
    label: "Australia",
    countryCodeVal: "61",
    suggested: true,
  },
  { countryCode: "AW", label: "Aruba", countryCodeVal: "297" },
  { countryCode: "AX", label: "Alland Islands", countryCodeVal: "358" },
  { countryCode: "AZ", label: "Azerbaijan", countryCodeVal: "994" },
  {
    countryCode: "BA",
    label: "Bosnia and Herzegovina",
    countryCodeVal: "387",
  },
  { countryCode: "BB", label: "Barbados", countryCodeVal: "1-246" },
  { countryCode: "BD", label: "Bangladesh", countryCodeVal: "880" },
  { countryCode: "BE", label: "Belgium", countryCodeVal: "32" },
  { countryCode: "BF", label: "Burkina Faso", countryCodeVal: "226" },
  { countryCode: "BG", label: "Bulgaria", countryCodeVal: "359" },
  { countryCode: "BH", label: "Bahrain", countryCodeVal: "973" },
  { countryCode: "BI", label: "Burundi", countryCodeVal: "257" },
  { countryCode: "BJ", label: "Benin", countryCodeVal: "229" },
  { countryCode: "BL", label: "Saint Barthelemy", countryCodeVal: "590" },
  { countryCode: "BM", label: "Bermuda", countryCodeVal: "1-441" },
  { countryCode: "BN", label: "Brunei Darussalam", countryCodeVal: "673" },
  { countryCode: "BO", label: "Bolivia", countryCodeVal: "591" },
  { countryCode: "BR", label: "Brazil", countryCodeVal: "55" },
  { countryCode: "BS", label: "Bahamas", countryCodeVal: "1-242" },
  { countryCode: "BT", label: "Bhutan", countryCodeVal: "975" },
  { countryCode: "BV", label: "Bouvet Island", countryCodeVal: "47" },
  { countryCode: "BW", label: "Botswana", countryCodeVal: "267" },
  { countryCode: "BY", label: "Belarus", countryCodeVal: "375" },
  { countryCode: "BZ", label: "Belize", countryCodeVal: "501" },
  {
    countryCode: "CA",
    label: "Canada",
    countryCodeVal: "1",
    suggested: true,
  },
  {
    countryCode: "CC",
    label: "Cocos (Keeling) Islands",
    countryCodeVal: "61",
  },
  {
    countryCode: "CD",
    label: "Congo, Democratic Republic of the",
    countryCodeVal: "243",
  },
  {
    countryCode: "CF",
    label: "Central African Republic",
    countryCodeVal: "236",
  },
  {
    countryCode: "CG",
    label: "Congo, Republic of the",
    countryCodeVal: "242",
  },
  { countryCode: "CH", label: "Switzerland", countryCodeVal: "41" },
  { countryCode: "CI", label: "Cote d'Ivoire", countryCodeVal: "225" },
  { countryCode: "CK", label: "Cook Islands", countryCodeVal: "682" },
  { countryCode: "CL", label: "Chile", countryCodeVal: "56" },
  { countryCode: "CM", label: "Cameroon", countryCodeVal: "237" },
  { countryCode: "CN", label: "China", countryCodeVal: "86" },
  { countryCode: "CO", label: "Colombia", countryCodeVal: "57" },
  { countryCode: "CR", label: "Costa Rica", countryCodeVal: "506" },
  { countryCode: "CU", label: "Cuba", countryCodeVal: "53" },
  { countryCode: "CV", label: "Cape Verde", countryCodeVal: "238" },
  { countryCode: "CW", label: "Curacao", countryCodeVal: "599" },
  { countryCode: "CX", label: "Christmas Island", countryCodeVal: "61" },
  { countryCode: "CY", label: "Cyprus", countryCodeVal: "357" },
  { countryCode: "CZ", label: "Czech Republic", countryCodeVal: "420" },
  {
    countryCode: "DE",
    label: "Germany",
    countryCodeVal: "49",
    suggested: true,
  },
  { countryCode: "DJ", label: "Djibouti", countryCodeVal: "253" },
  { countryCode: "DK", label: "Denmark", countryCodeVal: "45" },
  { countryCode: "DM", label: "Dominica", countryCodeVal: "1-767" },
  {
    countryCode: "DO",
    label: "Dominican Republic",
    countryCodeVal: "1-809",
  },
  { countryCode: "DZ", label: "Algeria", countryCodeVal: "213" },
  { countryCode: "EC", label: "Ecuador", countryCodeVal: "593" },
  { countryCode: "EE", label: "Estonia", countryCodeVal: "372" },
  { countryCode: "EG", label: "Egypt", countryCodeVal: "20" },
  { countryCode: "EH", label: "Western Sahara", countryCodeVal: "212" },
  { countryCode: "ER", label: "Eritrea", countryCodeVal: "291" },
  { countryCode: "ES", label: "Spain", countryCodeVal: "34" },
  { countryCode: "ET", label: "Ethiopia", countryCodeVal: "251" },
  { countryCode: "FI", label: "Finland", countryCodeVal: "358" },
  { countryCode: "FJ", label: "Fiji", countryCodeVal: "679" },
  {
    countryCode: "FK",
    label: "Falkland Islands (Malvinas)",
    countryCodeVal: "500",
  },
  {
    countryCode: "FM",
    label: "Micronesia, Federated States of",
    countryCodeVal: "691",
  },
  { countryCode: "FO", label: "Faroe Islands", countryCodeVal: "298" },
  {
    countryCode: "FR",
    label: "France",
    countryCodeVal: "33",
    suggested: true,
  },
  { countryCode: "GA", label: "Gabon", countryCodeVal: "241" },
  { countryCode: "GB", label: "United Kingdom", countryCodeVal: "44" },
  { countryCode: "GD", label: "Grenada", countryCodeVal: "1-473" },
  { countryCode: "GE", label: "Georgia", countryCodeVal: "995" },
  { countryCode: "GF", label: "French Guiana", countryCodeVal: "594" },
  { countryCode: "GG", label: "Guernsey", countryCodeVal: "44" },
  { countryCode: "GH", label: "Ghana", countryCodeVal: "233" },
  { countryCode: "GI", label: "Gibraltar", countryCodeVal: "350" },
  { countryCode: "GL", label: "Greenland", countryCodeVal: "299" },
  { countryCode: "GM", label: "Gambia", countryCodeVal: "220" },
  { countryCode: "GN", label: "Guinea", countryCodeVal: "224" },
  { countryCode: "GP", label: "Guadeloupe", countryCodeVal: "590" },
  { countryCode: "GQ", label: "Equatorial Guinea", countryCodeVal: "240" },
  { countryCode: "GR", label: "Greece", countryCodeVal: "30" },
  {
    countryCode: "GS",
    label: "South Georgia and the South Sandwich Islands",
    countryCodeVal: "500",
  },
  { countryCode: "GT", label: "Guatemala", countryCodeVal: "502" },
  { countryCode: "GU", label: "Guam", countryCodeVal: "1-671" },
  { countryCode: "GW", label: "Guinea-Bissau", countryCodeVal: "245" },
  { countryCode: "GY", label: "Guyana", countryCodeVal: "592" },
  { countryCode: "HK", label: "Hong Kong", countryCodeVal: "852" },
  {
    countryCode: "HM",
    label: "Heard Island and McDonald Islands",
    countryCodeVal: "672",
  },
  { countryCode: "HN", label: "Honduras", countryCodeVal: "504" },
  { countryCode: "HR", label: "Croatia", countryCodeVal: "385" },
  { countryCode: "HT", label: "Haiti", countryCodeVal: "509" },
  { countryCode: "HU", label: "Hungary", countryCodeVal: "36" },
  { countryCode: "ID", label: "Indonesia", countryCodeVal: "62" },
  { countryCode: "IE", label: "Ireland", countryCodeVal: "353" },
  { countryCode: "IL", label: "Israel", countryCodeVal: "972" },
  { countryCode: "IM", label: "Isle of Man", countryCodeVal: "44" },
  {
    countryCode: "IO",
    label: "British Indian Ocean Territory",
    countryCodeVal: "246",
  },
  { countryCode: "IQ", label: "Iraq", countryCodeVal: "964" },
  {
    countryCode: "IR",
    label: "Iran, Islamic Republic of",
    countryCodeVal: "98",
  },
  { countryCode: "IS", label: "Iceland", countryCodeVal: "354" },
  { countryCode: "IT", label: "Italy", countryCodeVal: "39" },
  { countryCode: "JE", label: "Jersey", countryCodeVal: "44" },
  { countryCode: "JM", label: "Jamaica", countryCodeVal: "1-876" },
  { countryCode: "JO", label: "Jordan", countryCodeVal: "962" },
  {
    countryCode: "JP",
    label: "Japan",
    countryCodeVal: "81",
    suggested: true,
  },
  { countryCode: "KE", label: "Kenya", countryCodeVal: "254" },
  { countryCode: "KG", label: "Kyrgyzstan", countryCodeVal: "996" },
  { countryCode: "KH", label: "Cambodia", countryCodeVal: "855" },
  { countryCode: "KI", label: "Kiribati", countryCodeVal: "686" },
  { countryCode: "KM", label: "Comoros", countryCodeVal: "269" },
  {
    countryCode: "KN",
    label: "Saint Kitts and Nevis",
    countryCodeVal: "1-869",
  },
  {
    countryCode: "KP",
    label: "Korea, Democratic People's Republic of",
    countryCodeVal: "850",
  },
  { countryCode: "KR", label: "Korea, Republic of", countryCodeVal: "82" },
  { countryCode: "KW", label: "Kuwait", countryCodeVal: "965" },
  { countryCode: "KY", label: "Cayman Islands", countryCodeVal: "1-345" },
  { countryCode: "KZ", label: "Kazakhstan", countryCodeVal: "7" },
  {
    countryCode: "LA",
    label: "Lao People's Democratic Republic",
    countryCodeVal: "856",
  },
  { countryCode: "LB", label: "Lebanon", countryCodeVal: "961" },
  { countryCode: "LC", label: "Saint Lucia", countryCodeVal: "1-758" },
  { countryCode: "LI", label: "Liechtenstein", countryCodeVal: "423" },
  { countryCode: "LK", label: "Sri Lanka", countryCodeVal: "94" },
  { countryCode: "LR", label: "Liberia", countryCodeVal: "231" },
  { countryCode: "LS", label: "Lesotho", countryCodeVal: "266" },
  { countryCode: "LT", label: "Lithuania", countryCodeVal: "370" },
  { countryCode: "LU", label: "Luxembourg", countryCodeVal: "352" },
  { countryCode: "LV", label: "Latvia", countryCodeVal: "371" },
  { countryCode: "LY", label: "Libya", countryCodeVal: "218" },
  { countryCode: "MA", label: "Morocco", countryCodeVal: "212" },
  { countryCode: "MC", label: "Monaco", countryCodeVal: "377" },
  {
    countryCode: "MD",
    label: "Moldova, Republic of",
    countryCodeVal: "373",
  },
  { countryCode: "ME", label: "Montenegro", countryCodeVal: "382" },
  {
    countryCode: "MF",
    label: "Saint Martin (French part)",
    countryCodeVal: "590",
  },
  { countryCode: "MG", label: "Madagascar", countryCodeVal: "261" },
  { countryCode: "MH", label: "Marshall Islands", countryCodeVal: "692" },
  {
    countryCode: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    countryCodeVal: "389",
  },
  { countryCode: "ML", label: "Mali", countryCodeVal: "223" },
  { countryCode: "MM", label: "Myanmar", countryCodeVal: "95" },
  { countryCode: "MN", label: "Mongolia", countryCodeVal: "976" },
  { countryCode: "MO", label: "Macao", countryCodeVal: "853" },
  {
    countryCode: "MP",
    label: "Northern Mariana Islands",
    countryCodeVal: "1-670",
  },
  { countryCode: "MQ", label: "Martinique", countryCodeVal: "596" },
  { countryCode: "MR", label: "Mauritania", countryCodeVal: "222" },
  { countryCode: "MS", label: "Montserrat", countryCodeVal: "1-664" },
  { countryCode: "MT", label: "Malta", countryCodeVal: "356" },
  { countryCode: "MU", label: "Mauritius", countryCodeVal: "230" },
  { countryCode: "MV", label: "Maldives", countryCodeVal: "960" },
  { countryCode: "MW", label: "Malawi", countryCodeVal: "265" },
  { countryCode: "MX", label: "Mexico", countryCodeVal: "52" },
  { countryCode: "MY", label: "Malaysia", countryCodeVal: "60" },
  { countryCode: "MZ", label: "Mozambique", countryCodeVal: "258" },
  { countryCode: "NA", label: "Namibia", countryCodeVal: "264" },
  { countryCode: "NC", label: "New Caledonia", countryCodeVal: "687" },
  { countryCode: "NE", label: "Niger", countryCodeVal: "227" },
  { countryCode: "NF", label: "Norfolk Island", countryCodeVal: "672" },
  { countryCode: "NG", label: "Nigeria", countryCodeVal: "234" },
  { countryCode: "NI", label: "Nicaragua", countryCodeVal: "505" },
  { countryCode: "NL", label: "Netherlands", countryCodeVal: "31" },
  { countryCode: "NO", label: "Norway", countryCodeVal: "47" },
  { countryCode: "NP", label: "Nepal", countryCodeVal: "977" },
  { countryCode: "NR", label: "Nauru", countryCodeVal: "674" },
  { countryCode: "NU", label: "Niue", countryCodeVal: "683" },
  { countryCode: "NZ", label: "New Zealand", countryCodeVal: "64" },
  { countryCode: "OM", label: "Oman", countryCodeVal: "968" },
  { countryCode: "PA", label: "Panama", countryCodeVal: "507" },
  { countryCode: "PE", label: "Peru", countryCodeVal: "51" },
  { countryCode: "PF", label: "French Polynesia", countryCodeVal: "689" },
  { countryCode: "PG", label: "Papua New Guinea", countryCodeVal: "675" },
  { countryCode: "PH", label: "Philippines", countryCodeVal: "63" },
  { countryCode: "PK", label: "Pakistan", countryCodeVal: "92" },
  { countryCode: "PL", label: "Poland", countryCodeVal: "48" },
  {
    countryCode: "PM",
    label: "Saint Pierre and Miquelon",
    countryCodeVal: "508",
  },
  { countryCode: "PN", label: "Pitcairn", countryCodeVal: "870" },
  { countryCode: "PR", label: "Puerto Rico", countryCodeVal: "1" },
  {
    countryCode: "PS",
    label: "Palestine, State of",
    countryCodeVal: "970",
  },
  { countryCode: "PT", label: "Portugal", countryCodeVal: "351" },
  { countryCode: "PW", label: "Palau", countryCodeVal: "680" },
  { countryCode: "PY", label: "Paraguay", countryCodeVal: "595" },
  { countryCode: "QA", label: "Qatar", countryCodeVal: "974" },
  { countryCode: "RE", label: "Reunion", countryCodeVal: "262" },
  { countryCode: "RO", label: "Romania", countryCodeVal: "40" },
  { countryCode: "RS", label: "Serbia", countryCodeVal: "381" },
  { countryCode: "RU", label: "Russian Federation", countryCodeVal: "7" },
  { countryCode: "RW", label: "Rwanda", countryCodeVal: "250" },
  { countryCode: "SA", label: "Saudi Arabia", countryCodeVal: "966" },
  { countryCode: "SB", label: "Solomon Islands", countryCodeVal: "677" },
  { countryCode: "SC", label: "Seychelles", countryCodeVal: "248" },
  { countryCode: "SD", label: "Sudan", countryCodeVal: "249" },
  { countryCode: "SE", label: "Sweden", countryCodeVal: "46" },
  { countryCode: "SG", label: "Singapore", countryCodeVal: "65" },
  { countryCode: "SH", label: "Saint Helena", countryCodeVal: "290" },
  { countryCode: "SI", label: "Slovenia", countryCodeVal: "386" },
  {
    countryCode: "SJ",
    label: "Svalbard and Jan Mayen",
    countryCodeVal: "47",
  },
  { countryCode: "SK", label: "Slovakia", countryCodeVal: "421" },
  { countryCode: "SL", label: "Sierra Leone", countryCodeVal: "232" },
  { countryCode: "SM", label: "San Marino", countryCodeVal: "378" },
  { countryCode: "SN", label: "Senegal", countryCodeVal: "221" },
  { countryCode: "SO", label: "Somalia", countryCodeVal: "252" },
  { countryCode: "SR", label: "Suriname", countryCodeVal: "597" },
  { countryCode: "SS", label: "South Sudan", countryCodeVal: "211" },
  {
    countryCode: "ST",
    label: "Sao Tome and Principe",
    countryCodeVal: "239",
  },
  { countryCode: "SV", label: "El Salvador", countryCodeVal: "503" },
  {
    countryCode: "SX",
    label: "Sint Maarten (Dutch part)",
    countryCodeVal: "1-721",
  },
  {
    countryCode: "SY",
    label: "Syrian Arab Republic",
    countryCodeVal: "963",
  },
  { countryCode: "SZ", label: "Swaziland", countryCodeVal: "268" },
  {
    countryCode: "TC",
    label: "Turks and Caicos Islands",
    countryCodeVal: "1-649",
  },
  { countryCode: "TD", label: "Chad", countryCodeVal: "235" },
  {
    countryCode: "TF",
    label: "French Southern Territories",
    countryCodeVal: "262",
  },
  { countryCode: "TG", label: "Togo", countryCodeVal: "228" },
  { countryCode: "TH", label: "Thailand", countryCodeVal: "66" },
  { countryCode: "TJ", label: "Tajikistan", countryCodeVal: "992" },
  { countryCode: "TK", label: "Tokelau", countryCodeVal: "690" },
  { countryCode: "TL", label: "Timor-Leste", countryCodeVal: "670" },
  { countryCode: "TM", label: "Turkmenistan", countryCodeVal: "993" },
  { countryCode: "TN", label: "Tunisia", countryCodeVal: "216" },
  { countryCode: "TO", label: "Tonga", countryCodeVal: "676" },
  { countryCode: "TR", label: "Turkey", countryCodeVal: "90" },
  {
    countryCode: "TT",
    label: "Trinidad and Tobago",
    countryCodeVal: "1-868",
  },
  { countryCode: "TV", label: "Tuvalu", countryCodeVal: "688" },
  {
    countryCode: "TW",
    label: "Taiwan, Province of China",
    countryCodeVal: "886",
  },
  {
    countryCode: "TZ",
    label: "United Republic of Tanzania",
    countryCodeVal: "255",
  },
  { countryCode: "UA", label: "Ukraine", countryCodeVal: "380" },
  { countryCode: "UG", label: "Uganda", countryCodeVal: "256" },
  {
    countryCode: "US",
    label: "United States",
    countryCodeVal: "1",
    suggested: true,
  },
  { countryCode: "UY", label: "Uruguay", countryCodeVal: "598" },
  { countryCode: "UZ", label: "Uzbekistan", countryCodeVal: "998" },
  {
    countryCode: "VA",
    label: "Holy See (Vatican City State)",
    countryCodeVal: "379",
  },
  {
    countryCode: "VC",
    label: "Saint Vincent and the Grenadines",
    countryCodeVal: "1-784",
  },
  { countryCode: "VE", label: "Venezuela", countryCodeVal: "58" },
  {
    countryCode: "VG",
    label: "British Virgin Islands",
    countryCodeVal: "1-284",
  },
  {
    countryCode: "VI",
    label: "US Virgin Islands",
    countryCodeVal: "1-340",
  },
  { countryCode: "VN", label: "Vietnam", countryCodeVal: "84" },
  { countryCode: "VU", label: "Vanuatu", countryCodeVal: "678" },
  { countryCode: "WF", label: "Wallis and Futuna", countryCodeVal: "681" },
  { countryCode: "WS", label: "Samoa", countryCodeVal: "685" },
  { countryCode: "XK", label: "Kosovo", countryCodeVal: "383" },
  { countryCode: "YE", label: "Yemen", countryCodeVal: "967" },
  { countryCode: "YT", label: "Mayotte", countryCodeVal: "262" },
  { countryCode: "ZA", label: "South Africa", countryCodeVal: "27" },
  { countryCode: "ZM", label: "Zambia", countryCodeVal: "260" },
  { countryCode: "ZW", label: "Zimbabwe", countryCodeVal: "263" },
];
