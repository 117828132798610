import React from "react";
import { Image } from "components";
import LoadingLogo from "assets/LoadingLogo.gif";
import "./index.css";

const Loading = () => {
  return (
    <div className="overlay">
      <Image imageURL={LoadingLogo} className="loadingLogo" alt="loadingLogo" />
    </div>
  );
};

export default Loading;
