import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { ActionIcon } from "components";
import { displayDriveImage } from "utils";
import "./index.css";
import "./mobile.css";

const ProfilePageBanner = ({ user, isCurrentUser }) => {
  const { name, knownLanguages, motherTongue, profilePic } = user;
  return (
    <div className="profile-page__banner-container">
      <div className="profile-page__banner-overlay">
        <Avatar
          alt="Remy Sharp"
          src={displayDriveImage(profilePic)}
          sx={{ width: "183px", height: "182px" }}
        >
          {name}
        </Avatar>
        <div className="profile-page__banner-name">{name}</div>
        <div className="profile-page__banner-avatar"></div>
        {/* <div className="profile-page__banner-edit-icon"></div> */}
      </div>
      <div className="profile-page__banner-footer">
        <div className="profile-page__banner-footer__left">
          <div className="profile-page__banner-footer__content-big">
            <div className="profile-page__banner-count">
              {knownLanguages?.length > 10
                ? knownLanguages?.length || 0
                : `0${knownLanguages?.length || 0}`}
            </div>
            <div className="profile-page__banner-title">Languages Known</div>
          </div>
          <div className="profile-page__banner-footer__content-big">
            {/* TODO: This metric makes no sense here lets put another metric here  
            <div className="profile-page__banner-count">21</div>
            <div className="profile-page__banner-title">Meme's suggested</div> */}
          </div>
        </div>
        <div className="profile-page__banner-footer__right">
          <div className="profile-page__banner-footer__content-small">
            <div className="profile-page__banner-prompt">Kota, Rajasthan</div>
            <div className="profile-page__banner-title">Location</div>
          </div>
          <div className="profile-page__banner-footer__content-small">
            <div className="profile-page__banner-prompt">{motherTongue}</div>
            <div className="profile-page__banner-title">Mother Tongue</div>
          </div>
          {isCurrentUser && (
            <div className="profile-page__banner-footer__content-small">
              <Link to={`/profile/edit`}>
                <ActionIcon
                  iconClassName="profile-page__banner-edit-icon"
                  className="profile-page__banner-edit-icon"
                  type="edit"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePageBanner;
