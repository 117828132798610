import React, { useState } from "react";
import CheckoutButton from "../CheckoutButton";
import SelectAddress from "./SelectAddress";
import "./index.css";
import OrderBilling from "./OrderBilling";
import _ from "lodash";

const OrderCheckout = ({ currentUser }) => {
  const [address, setAddress] = useState(
    _.find(currentUser?.addresses, ({ isDefaultAddress }) => isDefaultAddress)
  );

  return (
    <div className="div__userCart-orderCheckout">
      <SelectAddress
        address={address}
        onChangeAddress={(addressVal) => setAddress(addressVal)}
      />
      <OrderBilling cart={currentUser?.cart} />
      <CheckoutButton address={address?._id} userDetails={currentUser} />
    </div>
  );
};

export default OrderCheckout;
