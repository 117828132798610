import React from "react";
import _ from "lodash";
import { DetailCard, Image, RowDetail } from "components";
import { displayCurrencyAmount, displayDriveImage } from "utils";
import "./index.css";

const OrderItems = ({
  products,
  isSelectMode,
  selectedItems,
  onSelectItem,
  headerText,
}) => (
  <DetailCard
    className="orderDetail__content--left-items"
    headerClass="orderDetail__content--left-item-header"
    headerContent={headerText || `Items Ordered: ${products?.length}`}
    contentClass="orderDetail__content--left-item"
    cardContent={_.map(products, (item) => {
      const { productItem, color, images, sizeSymbol, itemDetail } = item;

      const detailsList = [
        `${productItem?.title} (${color})`,
        `Price: ${displayCurrencyAmount(itemDetail?.price)}`,
        `Size: ${sizeSymbol}    Qty: ${itemDetail?.quantity}`,
        `Color: ${color}`,
        `Language: ${productItem?.contentLanguage}`,
      ];
      return (
        <div
          className={`orderDetail__content--left-item-details ${
            _.some(selectedItems, item)
              ? "orderDetail__content--left-item-selected"
              : ""
          }`}
          onClick={() => isSelectMode && onSelectItem(item)}
        >
          <div className="orderDetail__content--left-item-content">
            {_.map(detailsList, (detailItem) => (
              <RowDetail
                description={detailItem}
                className="orderDetail__content--left-item-content-value"
              />
            ))}
          </div>
          <Image
            imageURL={displayDriveImage(images[0])}
            className="orderDetail__content--left-item-image"
          />
        </div>
      );
    })}
  />
);

export default OrderItems;
