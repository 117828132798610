import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconDisplay, Button, Loading, Grid } from "components";
import { VendorServices } from "services";
import { UserContext, VendorContext } from "contexts";
import VendorSalesComposition from "./SalesComposition";
import DispatchedOrders from "./DispatchedOrders";
import LatestTransactions from "./LatestTransactions";
import LatestTickets from "./LatestTickets";
import VendorAnalytics from "./Analytics";
import BankAccountsList from "../Wallet/BankAccounts";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const VendorMainDashboard = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { currentVendor, setCurrentVendor } = useContext(VendorContext);

  useEffect(() => {
    fetchVendorDashboard();
  }, []);

  const fetchVendorDashboard = async () => {
    try {
      setLoading(true);
      const dashboardData = await VendorServices.fetchVendorDashboard(
        currentUser?._id
      );
      setCurrentVendor({ ...currentVendor, dashboardData });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { bankAccountsList, ticketsList, salesAnalytics, salesByState } =
    currentVendor?.dashboardData || {};

  const dashboardGridDisplayList = [
    {
      content: <DispatchedOrders vendorId={currentUser?._id} />,
      className: "vendorDashboard__gridContainer-dispatchOrders",
    },
    {
      content: <VendorAnalytics {...salesAnalytics} />,
      className: "vendorDashboard__gridContainer-analytics",
    },
    {
      content: <LatestTransactions vendorId={currentUser?._id} />,
      className: "vendorDashboard__gridContainer-latestTransactions",
    },
    {
      content: <BankAccountsList bankAccountsList={bankAccountsList} />,
      className: "vendorDashboard__gridContainer-vendorBankAccounts",
    },
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="vendorDashboard">
        <div className="vendorDashboard__header">
          <p className="vendorDashboard__header--left">
            <IconDisplay
              type="dashboard"
              className="vendorDashboard__header--left-icon"
            />{" "}
            Dashboard
          </p>
          <div className="vendorDashboard__header--right">
            <Link
              to="/product/add"
              className="vendorDashboard__header--right-addProduct-link"
            >
              <Button
                className="vendorDashboard__header--right-addProduct-button"
                startIcon={<IconDisplay type="add" className="vendorDashboard__header--right-addProduct-buttonIcon" />}
              >
                Add Product
              </Button>
            </Link>
            <p className="vendorDashboard__header--right-addProduct-text">
              Have a new design? Add here
            </p>
          </div>
        </div>
        <Grid container spacing={2} className="vendorDashboard__gridContainer">
          {dashboardGridDisplayList?.map(({ content, className }) => (
            <Grid item xs={6} sm={6} md={6} lg={6} className={className}>
              {content}
            </Grid>
          ))}
        </Grid>
        <LatestTickets ticketsList={ticketsList} />
        <div className="vendorDashboard__stateSaleProjections">
          <p className="vendorDashboard__stateSaleProjections-header">
            <IconDisplay
              type="infographic"
              className="vendorDashboard__stateSaleProjections-header-icon"
            />{" "}
            Regional Sales Inforgraphics
          </p>
          <VendorSalesComposition
            salesData={salesByState?.salePercentagesByState}
          />
        </div>
      </div>
    </>
  );
};

export default VendorMainDashboard;
