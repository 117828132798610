import React from 'react'
import "./index.css";

const ColorSelector = ({
    labelText,
    colorOptions,
    colorSelected,
    onSelectOption,
  }) => {
    return (
      <div className="div__colorSelector-container">
        <p className="lblSelectColor">{labelText}</p>
        <div className="div__colorSelector-sizes">
          {colorOptions.map((colorVal) => (
            <div
              key={colorVal}
              style={{ background: colorVal.toLowerCase() }}
              className={`colorOption ${
                colorVal === colorSelected ? "colorOptionSelected" : ""
              }`}
              onClick={() => onSelectOption(colorVal)}
            />
          ))}
        </div>
      </div>
    );
  };
  
  export default ColorSelector;