import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import { ErrorMessage } from "components";
import "./index.css";

const DropdownInput = ({
  name,
  labelText,
  className,
  optionsList,
  disabled,
  selectedOption,
  placeholder,
  onSelect,
  onBlur,
  errorText,
}) => {
  const dropdownOptions = !_.isEmpty(placeholder)
    ? [{ label: placeholder, value: placeholder }, ...optionsList]
    : optionsList;
  return (
    <div className="div__dropdown-input">
      {labelText && <p className="lblInput">{labelText}</p>}
      <Select
        name={name}
        id="dropdownInput"
        className={className}
        value={selectedOption}
        onChange={onSelect}
        onBlur={onBlur}
        disabled={disabled}
      >
        {dropdownOptions?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {errorText && <ErrorMessage errorText={errorText} />}
    </div>
  );
};

export default DropdownInput;

DropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  className: PropTypes.string.isRequired,
  optionsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  selectedOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorText: PropTypes.string,
};

DropdownInput.defaultProps = {
  name: "",
  labelText: "",
  className: "",
  optionsList: [],
  disabled: false,
  selectedOption: "",
  onSelect: null,
  onBlur: null,
  errorText: "",
};
