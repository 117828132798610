// import { Link } from "react-router-dom";

const LANGUAGE_GRID_CONFIG = {
  screenName: "Footer",
  containerSpacing: 1,
  rowDivisions: 3,
  renderComponent: (value) => (
    // <Link
    //   className="div__footer-languagesList-container-languageText"
    //   to={`/products/search?contentLanguage=${value}`}
    // >
    //   {value}
    // </Link>
    <p className="div__footer-languagesList-container-languageText">{value}</p>
  ),
  emptyDataContent: () => null,
};

export default LANGUAGE_GRID_CONFIG;
