import React, { useContext, useMemo, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { Collapse } from "@mui/material";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  Loading,
  Snackbar,
  TextInputField,
  Button,
  Timer,
  Image,
} from "components";
import VendorPhoneOTP from "assets/Vendor/VendorPhoneOTP.svg";
import { COUNTRY_CODES_LIST } from "constants";
import firebaseConfig from "../../../../../firebase.json";
import { VendorContext } from "contexts";
import { useSnackbar } from "hooks";
import AutocompleteCountryCode from "pages/Authentication/OTPAuth/CountryCodeInput";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const VendorPhoneVerification = () => {
  const snackbar = useSnackbar();
  const { currentVendor, setCurrentVendor } = useContext(VendorContext);

  const [OTPObject, setOTPObject] = useState({
    isOTPSent: false,
    OTPConfirmation: {},
  });
  const [firebaseToken, setFirebaseToken] = useState(false);

  const isFirebaseTokenGenerated = useMemo(
    () => !_.isEmpty(firebaseToken),
    [firebaseToken]
  );

  const handleSendOTP = async (phoneNumber) => {
    try {
      auth.languageCode = "en";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptchaContainer",
        {
          size: "invisible",
        },
        auth
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setOTPObject({
        OTPConfirmation: confirmationResult,
        isOTPSent: true,
        timeoutOTP: 60,
      });
      return `An OTP has been sent at ${phoneNumber}`;
    } catch (err) {
      return `Error occurred during sending OTP to ${phoneNumber}`;
    }
  };

  const verifyOTPCode = async (phoneNumber, otpCode, setSubmitting) => {
    try {
      setSubmitting(true);
      const verificationResponse = await OTPConfirmation.confirm(otpCode);
      setSubmitting(false);

      if (_.isEmpty(verificationResponse)) {
        snackbar.showMessage({
          content: `Invalid OTP`,
        });
        return;
      }
      setFirebaseToken(verificationResponse?._tokenResponse?.idToken);
      snackbar.showMessage({
        content: `OTP verified successfully`,
      });
      setCurrentVendor({
        ...currentVendor,
        phoneNumber,
        onboardingStep: currentVendor?.onboardingStep + 1,
      });
    } catch (err) {
      snackbar.showMessage({
        content: `Error ocurred during Firebase OTP verification`,
      });
    }
  };

  const sendOTPToPhone = async (phoneNumber, setSubmitting) => {
    setSubmitting(true);
    try {
      const msg = await handleSendOTP(phoneNumber);
      setSubmitting(false);
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const handleResendOTP = async (phoneNumber, setSubmitting) => {
    await window.recaptchaVerifier.clear();
    await window.recaptchaVerifier.recaptcha.reset();
    const msg = await handleSendOTP(phoneNumber);
    setSubmitting(false);
    snackbar.showMessage({
      content: msg,
    });
  };

  const { OTPConfirmation, isOTPSent, timeoutOTP } = OTPObject;
  const buttonText = isOTPSent ? "Verify OTP" : "Send OTP";

  const handleChangeEnteredPhone = () =>
    setOTPObject({ ...OTPObject, isOTPSent: false });

  return (
    <Formik
      initialValues={{
        countryCode: COUNTRY_CODES_LIST[0],
        phoneNumber: "",
        otpCode: "",
      }}
      validationSchema={yup.object({
        countryCode: yup.object().nullable(),
        phoneNumber: yup
          .string()
          .min(10, "Should be exactly 10 digits")
          .max(10, "Should be exactly 10 digits")
          .required("Phone number is required"),
        otpCode: isOTPSent
          ? yup
              .string()
              .min(6, "Should be exactly 6 digits")
              .max(6, "Should be exactly 6 digits")
              .required("OTP is mandatory")
          : yup.string(),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (
        { countryCode, phoneNumber, otpCode },
        { setSubmitting }
      ) => {
        if (!isFirebaseTokenGenerated) {
          if (!isOTPSent) {
            await sendOTPToPhone(
              `+${countryCode?.countryCodeVal}${phoneNumber}`,
              setSubmitting
            );
          } else {
            await verifyOTPCode(phoneNumber, otpCode, setSubmitting);
          }
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        isSubmitting,
        setSubmitting,
      }) => {
        return (
          <>
            {isSubmitting && <Loading />}
            <Snackbar {...snackbar} />
            <div className="vendorPhoneOTP__formContainer">
              <div className="vendorPhoneOTP__formContainer--left">
                {!isFirebaseTokenGenerated && (
                  <div className="vendorPhoneOTP__formContainer--left-verifyPhone">
                    {!isOTPSent && (
                      <>
                        <p className="vendorPhoneOTP__formContainer--left-verifyPhone-header">
                          Phone Number
                        </p>
                        <p className="vendorPhoneOTP__formContainer--left-verifyPhone-subHeader">
                          Enter the phone number that will be linked to your
                          account.
                        </p>
                        <div className="vendorPhoneOTP__formContainer--left-verifyPhone-container">
                          <AutocompleteCountryCode
                            className="vendorPhoneOTP__formContainer--left-verifyPhone-countryCode"
                            inputLabel="Code"
                            inputValue={values.countryCode}
                            handleChange={(ev, val) =>
                              setFieldValue("countryCode", val || {})
                            }
                            handleBlur
                            errorText={
                              touched.countryCode && errors.countryCode
                            }
                          />
                          <TextInputField
                            name="phoneNumber"
                            inputType="number"
                            className="vendorPhoneOTP__formContainer--left-verifyPhone-phoneNumber"
                            labelText="Phone Number"
                            placeholder="Enter your 10-digit phone number"
                            inputValue={values.phoneNumber}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                          />
                        </div>
                      </>
                    )}
                    <Collapse in={isOTPSent} timeout="auto" unmountOnExit>
                      <div className="vendorPhoneOTP__formContainer--left-verifyOTP">
                        <p className="vendorPhoneOTP__formContainer--left-verifyOTP-header">
                          OTP Verification
                        </p>
                        <p className="vendorPhoneOTP__formContainer--left-OTPMessage">
                          To verify, we have sent an OTP to your mobile number.
                        </p>
                        <p className="vendorPhoneOTP__formContainer--left-phoneNumber">
                          +{values.countryCode?.countryCodeVal}-
                          {values.phoneNumber}
                          <span
                            onClick={handleChangeEnteredPhone}
                            className="vendorPhoneOTP__formContainer--left-changePhone"
                          >
                            Change
                          </span>
                        </p>
                        <TextInputField
                          name="otpCode"
                          inputType="number"
                          labelText="Please enter it below to complete verification"
                          className="vendorPhoneOTP__formContainer--left-enteredOTP"
                          placeholder="Enter 6 digit OTP Code"
                          inputValue={values.otpCode}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errorText={touched.otpCode && errors.otpCode}
                        />
                      </div>
                    </Collapse>
                  </div>
                )}
                <Button
                  id="btnInput"
                  className="vendorPhoneOTP__formContainer--left-verifyOTP"
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
                {isOTPSent && (
                  <div className="vendorPhoneOTP__formContainer--left-resendOTP">
                    {!_.isUndefined(timeoutOTP) && (
                      <>
                        <span className="vendorPhoneOTP__formContainer--left-resendOTP-link">
                          Didn't receive OTP? Resend OTP in
                        </span>
                        <Timer
                          timeout={timeoutOTP}
                          className="vendorPhoneOTP__formContainer--left-resendOTP-timeout"
                          onTimerClear={() =>
                            setOTPObject({
                              ...OTPObject,
                              timeoutOTP: undefined,
                            })
                          }
                        />
                      </>
                    )}
                    {_.isUndefined(timeoutOTP) && (
                      <p
                        className="vendorPhoneOTP__formContainer--left-resendOTP-action"
                        onClick={() => handleResendOTP(setSubmitting)}
                      >
                        Resend OTP
                      </p>
                    )}
                  </div>
                )}
                <div id="recaptchaContainer" />
              </div>
              <Image
                imageURL={VendorPhoneOTP}
                className="vendorPhoneOTP__formContainer--right"
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default VendorPhoneVerification;
