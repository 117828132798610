export const ORDER_TABS_LIST = [
  { label: "Active Orders", orderStatus: "PAID", deliveryStatus: "" },
  { label: "Delivered", orderStatus: "", deliveryStatus: "DELIVERED" },
  { label: "Refunds", orderStatus: "REFUNDED", deliveryStatus: "" },
  { label: "Cancelled", orderStatus: "CANCELLED", deliveryStatus: "" },
];

export const ORDER_STATUS_LIST = [
  "PAID",
  "CANCELLED",
  "REFUND_REQUEST",
  "REFUND_DECLINED",
  "REFUND_APPROVED",
  "REFUNDED",
];

export const DELIVERY_STATUS_LIST = [
  "DISPATCHED",
  "DELIVERED",
  "PICKUP_REQUEST",
  "PICKUP_CONFIRMED",
  "RETURNED",
];
