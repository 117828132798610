import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Image, Table, Radio, Loading } from "components";
import { getTableDisplayConfig } from "displayConfigs";
import SizeChartImage from "assets/SizeChartTshirt.png";
import { SIZE_CHART_INCH_DATA, SIZE_CHART_CM_DATA } from "tests";
import SizeChartSwitch from "./SizeChartSwitch";
import "./index.css";

const SizeChart = ({
  defaultSize,
  loading,
  actionButtons,
  sizesAvailable,
  onSelectSize,
  selectedItemContent,
}) => {
  const [inchSelected, setInchSelected] = useState(true);
  const [sizeSelected, setSizeSelected] = useState(defaultSize);

  const handleSelectSize = (sizeVal) => {
    setSizeSelected(sizeVal);
    onSelectSize(sizeVal);
  };

  const sizeChartConfig = useMemo(
    () =>
      getTableDisplayConfig(inchSelected ? "SizeChartInch" : "SizeChartCm", {
        actions: ({ sizeSymbol }) => (
          <Radio
            checked={sizeSelected === sizeSymbol}
            onChange={() => handleSelectSize(sizeSymbol)}
            value={sizeSymbol}
          />
        ),
      }),
    [inchSelected, sizeSelected]
  );

  const sizeChartData = useMemo(
    () => (inchSelected ? SIZE_CHART_INCH_DATA : SIZE_CHART_CM_DATA),
    [inchSelected, sizeSelected]
  );

  return (
    <>
      {loading && <Loading />}
      <div className="div__sizeChart-container">
        <Image imageURL={SizeChartImage} className="imgSizeChart" />
        <div className="div__sizeChart-content">
          {selectedItemContent}
          <div className="div__sizeChart-content-header">
            <p className="lblSizeChart">Size Chart</p>
            <SizeChartSwitch
              isChecked={inchSelected}
              onToggle={() => setInchSelected(!inchSelected)}
              activeLabel="in"
              inActiveLabel="cm"
            />
          </div>
          <Table
            config={sizeChartConfig}
            dataList={_.filter(
              sizeChartData,
              (data) => sizesAvailable.indexOf(data.sizeSymbol) >= 0
            )}
          />
          <div className="div__sizeChart-cartActions">{actionButtons}</div>
        </div>
      </div>
    </>
  );
};

export default SizeChart;
