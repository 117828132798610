
import EmptyWishlistImage from "assets/EmptyWishlist.png";
import { EmptyListPlaceholder, ProductCard } from "components";
import { UserServices } from "services";

const WISHLIST_GRID_CONFIG = {
  containerSpacing: 1,
  rowDivisions: 3,
  renderComponent: (value, {  onAddToWishlist }) => (
    <ProductCard
      product={value}
      handleAddToWishlist={(ev) => {
        ev.stopPropagation();
        onAddToWishlist(value);
      }}
    />
  ),
  fetchListData: UserServices.fetchUserWishlist,
  emptyDataContent: ({ onEmptyWishlistButtonClick }) => (
    <EmptyListPlaceholder
      placeholderImage={EmptyWishlistImage}
      imageClass="imgEmptyWishlist"
      message="There is nothing in your wishlist."
      subMessage="Explore more and shortlist items"
      onButtonClick={onEmptyWishlistButtonClick}
      buttonLabel="Continue Shopping"
    />
  ),
};

export default WISHLIST_GRID_CONFIG;
