import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Image, GridDisplay, IconDisplay } from "components";
import {
  LANGUAGE_OPTIONS,
  CONTACT_US_DETAILS,
  PAGE_ROUTES,
  SOCIAL_MEDIA_LINKS,
} from "constants";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import MemeTLarge from "assets/Meme-T-Footer.png";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const Footer = () => {
  const { currentUser } = useContext(UserContext);
  const currentYear = new Date().getFullYear();

  const displayContactUsDetail = (icon, label, value) => (
    <tr key={label}>
      <td>{icon}</td>
      <td>{label}</td>
      <td>{value}</td>
    </tr>
  );

  const languageGridConfig = getGridDisplayConfig("Language");

  return (
    <footer className="div__footer-container">
      <div className="div__footer-topContent">
        <div className="div__footer-languagesList">
          <p className="lblHeader lblLanguagesList">Languages Available</p>
          <GridDisplay
            className="div__footer-languagesList-container"
            config={languageGridConfig}
            dataList={LANGUAGE_OPTIONS}
          />
        </div>
        <div className="div__footer-pagesList">
          <p className="lblHeader lblPagesList">List Of Pages</p>
          <div className="div__footer-pagesList-items">
            {PAGE_ROUTES.map(({ link, label }) => (
              <Link
                key={link}
                to={link === `/profile` ? `${link}/${currentUser?._id}` : link}
                className="pageLink"
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
        <div className="div__footer-contactUs">
          <div className="div__footer-contactUs-socialMedia">
            <div className="div__footer-contactUs-socialMedia-container">
              <p className="lblHeader">Contact With Us On</p>
              <div className="div__footer-contactUs-socialMedia-links">
                {SOCIAL_MEDIA_LINKS.map(({ link, type }) => (
                  <a key={type} href={link}>
                    <IconDisplay type={type} className="lnkSocialMedia--icon" />
                  </a>
                ))}
              </div>
            </div>
            <Image
              className="imgLogoMemeT"
              imageURL={MemeTLarge}
              alt="logoMemeT"
            />
          </div>
          <div className="div__footer-contactUs-details">
            <p className="lblHeader">Contact Us</p>
            <table className="tblContactUs">
              <tbody>
                {CONTACT_US_DETAILS.map(({ icon, label, value }) =>
                  displayContactUsDetail(icon, label, value)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="div__footer-bottomContent">
        <p>Conditions of Use & Sale</p>
        <p>
          &copy; {`${currentYear}-${currentYear + 1}`} Meme-T. or its affiliates
        </p>
      </div>
    </footer>
  );
};

export default Footer;
