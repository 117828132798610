import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { IconDisplay, GridDisplay, Loading } from "components";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import RecommendedProducts from "pages/CartPage/RecommendedProducts";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const WishlistPage = () => {
  const [loading, setLoading] = useState(false);
  const { modifyCurrentUserWishlist } = useContext(UserContext);
  const [wishlist, setWishlist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserWishlist();
  }, []);

  const wishlistGridConfig = getGridDisplayConfig("Wishlist", {
    onAddToWishlist: ({ _id, inWishlist }) => {
      setWishlist((prevList) => {
        const isSuccess = modifyCurrentUserWishlist(_id, inWishlist);
        return prevList.filter((item) => isSuccess && item._id !== _id);
      });
    },
    onEmptyWishlistButtonClick: () => navigate(`/products/search`),
  });

  const fetchUserWishlist = async () => {
    try {
      setLoading(true);
      const wishlistData = await wishlistGridConfig.fetchListData();
      setWishlist(wishlistData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="div__userWishlist-container">
        <div className="div__userWishlist-header">
          <IconDisplay type="favorite" className="iconFavorite" />
          <span className="lblWishlist">My Wishlist</span>
        </div>
        <GridDisplay
          className="div__userWishlist-results"
          config={wishlistGridConfig}
          dataList={wishlist}
        />
        {_.isEmpty(wishlist) && (
          <RecommendedProducts
            headerTitle="Recommendations for you"
            className="div__userWishlist-recommendedItems"
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
};

export default WishlistPage;
