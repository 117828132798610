import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ImagePreview, ShortImagePreview, Rating } from "components";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const FeedbackDetail = ({ feedback }) => {
  const { rating, comment, images, customerName, createdAt } = feedback || {};

  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    if (images?.length) {
      setMainImage(images[0]);
    }
    return () => {
      setMainImage("");
    };
  }, [images]);

  return (
    <div className="div__productDetail-ratings-feedback">
      <p className="ratingHeader">Feedback Details</p>
      <p className="feedbackAuthor">
        {customerName}
        <span className="feedbackDate">
          {new Date(createdAt)?.toDateString()}
        </span>
      </p>
      {!_.isEmpty(mainImage) && <ImagePreview imageSource={mainImage} className="feedbackImagePreview" />}
      <div className="div__productDetail-ratingFeedback-imagesList">
        {_.isEmpty(images) &&
          _.map(images, (imageURL) => (
            <ShortImagePreview
              key={imageURL}
              imageURL={imageURL}
              isMainImage={mainImage === imageURL}
              setAsMainImage={() => setMainImage(imageURL)}
            />
          ))}
      </div>
      {rating && (
        <div className="div__ratingValue">
          <p className="lblRatingValue">Rating Given</p>
          <Rating value={rating} readOnly />
        </div>
      )}
      {comment && (
        <div className="div__ratingValue">
          <p>Feedback: {comment}</p>
        </div>
      )}
    </div>
  );
};

export default FeedbackDetail;
