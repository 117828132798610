export const TICKETS_LIST_ADMIN_VIEW = [
  {
    _id: "720",
    title: "Cannot Create Tickets",
    description: "Cannot Create Tickets",
    customerName: "Soumyadeep Pal",
    createdAt: new Date("10/21/2021"),
    priority: "High",
    status: "Created",
  },
  {
    _id: "721",
    title: "Cannot view images",
    description: "Cannot view images properly",
    customerName: "Swastik Singh",
    createdAt: new Date("10/25/2021"),
    priority: "Critical",
    status: "In Progress",
  },
];
