import React from "react";
import _ from "lodash";
import { Grid } from "@mui/material";

const GridDisplay = ({ className, itemClassName, config, dataList = [] }) => {
  const {
    containerSpacing,
    displayGridElement,
    generateEmptyDataContent,
  } = config || {};

  return _.isEmpty(dataList) ? (
    generateEmptyDataContent
  ) : (
    <Grid
      className={className}
      container
      spacing={containerSpacing}
    >
      {dataList?.map((dataVal) => (
        <Grid key={dataVal?._id || dataVal} item className={itemClassName}>
          {displayGridElement(dataVal)}
        </Grid>
      ))}
    </Grid>
  );
};

export default GridDisplay;
