import React, { useContext } from "react";
import VendorOnboardStart from "assets/Vendor/VendorOnboardStart.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { Loading, TextInputField, Button, Snackbar, Image } from "components";
import { useSnackbar } from "hooks";
import { UserServices } from "services";
import { VendorContext } from "contexts";
import "./index.css";

const VendorInitiate = () => {
  const snackbar = useSnackbar();
  const { currentVendor, setCurrentVendor } = useContext(VendorContext);
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
      }}
      validationSchema={yup.object({
        name: yup.string().required("Vendor Name is mandatory"),
        email: yup
          .string()
          .email("Vendor Email format is invalid")
          .required("Vendor email is mandatory"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          const { msg } = await UserServices.sendEmailOTP(values);
          snackbar.showMessage({
            content: msg,
          });
          setSubmitting(false);
          setCurrentVendor({
            ...currentVendor,
            ...values,
            onboardingStep: currentVendor?.onboardingStep + 1,
          });
        } catch (err) {
          snackbar.showMessage({
            content: err,
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
      }) => (
        <>
          {isSubmitting && <Loading />}
          <Snackbar {...snackbar} />
          <div className="vendorInitiate-form">
            <div className="vendorInitiate-form--left">
              <TextInputField
                name="name"
                inputType="name"
                labelText="Vendor Name"
                className="vendorInitiate-form--left-vendorName"
                inputValue={values.name}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={touched.name && errors.name}
                fullWidth
              />
              <TextInputField
                name="email"
                inputType="text"
                className="vendorInitiate-form--left-vendorEmail"
                labelText="Vendor Email"
                inputValue={values.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={touched.email && errors.email}
                fullWidth
              />
              <Button
                buttonText="Send OTP"
                id="btnInput"
                className="vendorInitiate-form--left-sendEmailOTP"
                disableRipple
                onClick={handleSubmit}
              >
                Send OTP
              </Button>
            </div>
            <Image
              imageURL={VendorOnboardStart}
              className="vendorInitiate-form--right"
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default VendorInitiate;
