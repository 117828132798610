import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { AddInventoryPage } from "pages";
import InitialInventoryList from "./InitialInventoryList";
import {
  TextInputField,
  TextAreaInputField,
  AutoCompleteInput,
  DropdownInput,
  Button,
  VideoPreview,
  CircularProgress,
  CustomDrawer,
  ActionIcon,
  Loading,
  Snackbar,
} from "components";
import { UserContext, InventoryContext } from "contexts";
import { useSnackbar } from "hooks";
import { ProductServices } from "services";
import { generateDropdownOptions } from "utils";
import { LANGUAGE_OPTIONS, FABRIC_TYPE_OPTIONS, WASH_OPTIONS } from "constants";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const AddProductPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useContext(UserContext);
  const {
    assignSelectedInventoryItem,
    setNewInventoryList,
    newInventoryList,
    clearNewInventoryList,
    resetInventoryList,
  } = useContext(InventoryContext);
  const snackbar = useSnackbar();
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    _id,
    title,
    description,
    punDescription,
    price,
    fabricType,
    fabricDescription,
    contentLanguage,
    washType,
    video,
  } = selectedProduct || {};
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      fetchProductDetails();
    }
    return () => {
      if (_.isEmpty(_id)) {
        clearNewInventoryList();
      } else {
        resetInventoryList();
      }
    };
  }, [params?.id]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const { productData, inventoryData } =
        await ProductServices.getProductById(params?.id);
      setSelectedProduct(productData);
      setNewInventoryList(inventoryData);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const productModLabel = _id ? `Update ${title}` : `Add Product`;

  return (
    <Formik
      initialValues={{
        title: title || "",
        description: description || "",
        punDescription: punDescription || "",
        price: price || "",
        video: video || "",
        fabricType: fabricType || FABRIC_TYPE_OPTIONS[0],
        fabricDescription: fabricDescription || "",
        contentLanguage: contentLanguage || LANGUAGE_OPTIONS[0],
        washType: washType || "Select",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Product Title is mandatory"),
        description: Yup.string().required("Product Description is mandatory"),
        about: Yup.string(),
        punDescription: Yup.string().required("Pun Description is mandatory"),
        fabricType: Yup.string()
          .required("Material Type is Mandatory")
          .test("fabricType", "Material Type is Mandatory", (value) => {
            return value !== "Select";
          }),
        fabricDescription: Yup.string(),
        contentLanguage: Yup.string().required("Language is mandatory"),
        washType: Yup.string().test(
          "washType",
          "Wash Type is Mandatory",
          (value) => {
            return value !== "Select";
          }
        ),
        price: Yup.number()
          .positive("Product Amount should be greater than zero")
          .required("Product Price is mandatory"),
        video: Yup.string(),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values) => {
        if (!newInventoryList.length) {
          snackbar.showMessage({
            content: `You can only add the product when you have added at least one inventory product.`,
          });
          return;
        }

        setLoading(true);

        let productPayload = {
            title: values.title,
            description: values.description,
            about: values.about,
            punDescription: values.punDescription,
            fabricType: values.fabricType,
            fabricDescription: values.fabricDescription,
            contentLanguage: values.contentLanguage,
            washType: values.washType,
            price: parseInt(values.price),
            video: values.video,
            designedBy: currentUser?._id,
          },
          dataPayload = {};

        if (_id) {
          dataPayload = {
            product: {
              ...productPayload,
              _id,
            },
          };
        } else {
          dataPayload = {
            product: productPayload,
            inventoryList: newInventoryList,
          };
        }

        try {
          const { msg, productData } =
            await ProductServices.createUpdateProduct(dataPayload);
          setSelectedProduct(productData);
          setLoading(false);
          snackbar.showMessage({
            content: msg,
            handleClose: () => navigate(-1),
          });
        } catch (err) {
          snackbar.showMessage({
            content: err,
          });
          setLoading(false);
        }
      }}
    >
      {(formikProps) => {
        return (
          <>
            {loading && <Loading />}
            <Snackbar {...snackbar} />
            <div className="div__addProductForm">
              <div className="div__addProductForm-container-header">
                <p className="headerAddProduct">{productModLabel}</p>
                {!_id && (
                  <ActionIcon
                    type="clear"
                    className="iconCancelProduct"
                    onClick={() => navigate(-1)}
                  />
                )}
              </div>
              <div className="div__addProductForm-container">
                <div className="div__addProductForm-primary">
                  <TextInputField
                    name="title"
                    inputType="text"
                    labelText="Product Name"
                    inputValue={formikProps.values.title}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.title && formikProps.errors.title
                    }
                  />
                  <div className="div__addProductForm-primary-dropdown">
                    <TextInputField
                      name="price"
                      inputType="text"
                      labelText="Default Price"
                      inputValue={formikProps.values.price}
                      handleChange={formikProps.handleChange}
                      handleBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.price && formikProps.errors.price
                      }
                    />
                    <AutoCompleteInput
                      name="contentLanguage"
                      className="autoCompleteLanguage"
                      optionsList={LANGUAGE_OPTIONS}
                      labelText="Language"
                      placeholder="Type language here"
                      value={formikProps.values.contentLanguage}
                      handleChange={(_, val) =>
                        formikProps.setFieldValue("contentLanguage", val || "")
                      }
                      handleBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.contentLanguage &&
                        formikProps.errors.contentLanguage
                      }
                    />
                  </div>
                  <TextAreaInputField
                    name="description"
                    inputType="text"
                    minRows={3}
                    labelText="General Description"
                    inputValue={formikProps.values.description}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.description &&
                      formikProps.errors.description
                    }
                  />
                  <TextAreaInputField
                    name="punDescription"
                    inputType="text"
                    minRows={3}
                    labelText="Pun Description"
                    inputValue={formikProps.values.punDescription}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.punDescription &&
                      formikProps.errors.punDescription
                    }
                  />
                  <div className="div__addProductForm-secondary-dropdown">
                    <DropdownInput
                      name="fabricType"
                      className="selectFabricType"
                      selectedOption={formikProps.values.fabricType}
                      labelText="Material Type"
                      optionsList={generateDropdownOptions(FABRIC_TYPE_OPTIONS)}
                      onSelect={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.fabricType &&
                        formikProps.errors.fabricType
                      }
                    />
                    <DropdownInput
                      name="washType"
                      className="selectWashType"
                      selectedOption={formikProps.values.washType}
                      labelText="Wash Category"
                      optionsList={generateDropdownOptions(WASH_OPTIONS)}
                      onSelect={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      errorText={
                        formikProps.touched.washType &&
                        formikProps.errors.washType
                      }
                    />
                  </div>
                  <TextAreaInputField
                    name="fabricDescription"
                    className="txtFabricDescription"
                    minRows={3}
                    inputType="text"
                    labelText="Fabric Description"
                    inputValue={formikProps.values.fabricDescription}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.fabricDescription &&
                      formikProps.errors.fabricDescription
                    }
                  />
                </div>
                <div className="div__addProductForm-secondary">
                  <TextInputField
                    name="video"
                    inputType="text"
                    labelText="Embed Video Link (Optional)"
                    inputValue={formikProps.values.video}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.video && formikProps.errors.video
                    }
                  />
                  <VideoPreview
                    videoSource={formikProps.values.video}
                    className="div__embedVideoPreview"
                    frameClassname="div__embedVideoPreview_video"
                  />
                  <p className="lblVideoPreview">Video Preview (16:9)</p>
                </div>
              </div>
              {!_id && (
                <div className="div__addProductForm-inventoryList">
                  <div className="div__addProductForm-inventoryList-header">
                    <p className="lblInventoryDetails">Inventory Details</p>
                    <Button
                      size="large"
                      className="btnAddInventory"
                      onClick={() => {
                        assignSelectedInventoryItem({});
                        setDrawerOpen(true);
                      }}
                    >
                      Add Inventory
                    </Button>
                  </div>
                  <InitialInventoryList
                    onSelectInventory={(inventoryItem) => {
                      assignSelectedInventoryItem(inventoryItem);
                      setDrawerOpen(true);
                    }}
                  />
                </div>
              )}
              {!_id && (
                <CustomDrawer
                  direction="bottom"
                  className="addInventoryDrawer"
                  isOpen={drawerOpen}
                  onCloseDrawer={() => setDrawerOpen(false)}
                >
                  <AddInventoryPage
                    onCloseDrawer={() => setDrawerOpen(false)}
                  />
                </CustomDrawer>
              )}
              <div className="div__addProductForm-actions">
                {loading ? (
                  <CircularProgress className="loadingProgress" />
                ) : (
                  <Button
                    size="large"
                    className="btnAddProduct"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                  >
                    {productModLabel}
                  </Button>
                )}
                {!_id && (
                  <Button
                    size="large"
                    className="btnCancelProduct"
                    onClick={() => navigate(-1)}
                  >
                    Cancel Product
                  </Button>
                )}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddProductPage;
