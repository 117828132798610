import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { Star } from "@mui/icons-material";
import {
  Snackbar,
  Loading,
  Button,
  Image,
  ChoiceQuestion,
  CustomRating,
} from "components";
import { FeedbackServices } from "services";
import { useSnackbar } from "hooks";
import { FEEDBACK_RATINGS, FEEDBACK_CHOICE_QUESTIONS_LIST } from "constants";
import "./index.css";

const OrderFeedbackForm = ({
  customerId,
  orderId,
  feedbackDetail,
  isPreview,
  onSubmitForm,
}) => {
  const snackbar = useSnackbar();
  const { rating, title, comment, choiceQuestions } = feedbackDetail || {};
  const [choiceQuestionsObj, setChoiceQuestionsObj] = useState(
    choiceQuestions || {
      wearingMask: "",
      timedDelivery: "",
      noContactDelivery: "",
    }
  );

  return (
    <Formik
      initialValues={{
        title: title || ``,
        comment: comment || ``,
        rating: rating || 0,
      }}
      validationSchema={Yup.object().shape({
        description: Yup.string().max(
          200,
          "Feedback Description should not exceed 200 characters"
        ),
        rating: Yup.number().test(
          "rating",
          "Rating is mandatory",
          (val) => val !== 0
        ),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        const payload = {
          ...values,
          customerId,
          orderId,
          feedbackType: "ORDER",
          choiceQuestions: choiceQuestionsObj,
        };
        setSubmitting(true);
        const { msg } = await FeedbackServices.createUpdateFeedback(payload);
        setSubmitting(false);
        snackbar.showMessage({
          content: msg,
          timeout: 5000,
          handleClose: onSubmitForm,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        isSubmitting,
        handleSubmit,
      }) => (
        <>
          <Snackbar {...snackbar} />
          {isSubmitting && <Loading />}
          <div className="orderFeedback__form">
            <div className="orderFeedback__form--leftRight">
              <Image
                imageURL={require("../../../../../../assets/EmptyCart.png")}
                className="orderFeedback__form--left-image"
              />
              <div className="orderFeedback__form--right">
                <p className="orderFeedback__form-header">ORDER FEEDBACK</p>
                <div className="orderFeedback__form-rating">
                  <p className="orderFeedback__form-rating-header">
                    Rate your Order Delivery
                  </p>
                  <CustomRating
                    rating={values.rating}
                    customIcon={({ value, ...props }) => (
                      <div className="orderFeedback__form-rating-container">
                        <Star
                          className="orderFeedback__form-rating-icon"
                          {...props}
                        />
                        <p className="orderFeedback__form-rating-label">
                          {FEEDBACK_RATINGS[value]}
                        </p>
                      </div>
                    )}
                    size="large"
                    onRatingChange={(val) =>
                      !isPreview && setFieldValue("rating", val)
                    }
                  />
                  {touched.rating && errors.rating && (
                    <p className="orderFeedback__form-rating-error">
                      {errors.rating}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="orderFeedback__form-questions">
              {FEEDBACK_CHOICE_QUESTIONS_LIST.map(
                ({ field, question, optionsList }) => (
                  <ChoiceQuestion
                    questionText={question}
                    optionsList={optionsList}
                    selectedOption={choiceQuestionsObj[field]}
                    onSelectOption={(val) =>
                      !isPreview &&
                      setChoiceQuestionsObj({
                        ...choiceQuestionsObj,
                        [field]: val,
                      })
                    }
                  />
                )
              )}
            </div>
            <div className="orderFeedback__form-description">
              <TextField
                name="comment"
                className="orderFeedback__form-descriptionInput"
                variant="standard"
                placeholder="Tell us what you liked about our delivery"
                value={values.comment}
                onChange={!isPreview && handleChange}
                onBlur={handleBlur}
              />
              <p className="orderFeedback__form-description-label">
                Your feedback helps Meme - T improve service.
              </p>
            </div>
            <div className="orderFeedback__form-buttons">
              {!isPreview && (
                <Button
                  id="btnInput"
                  className="orderFeedback__form-submit"
                  onClick={handleSubmit}
                >
                  CONTINUE
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default OrderFeedbackForm;
