import React, { useCallback } from "react";
import { IconDisplay, Grid } from "components";
import { displayCurrencyAmount } from "utils";
import "./index.css";

const VendorMonthlySummary = ({
  monthlyRevenueStats,
  monthlySalesIncrementPercent,
  currentMonthSales,
  totalWithdrawnAmount,
}) => {
  const percentageGrowthType = useCallback(
    (incrementPercent) => (incrementPercent > 0 ? "increment" : "decrement"),
    []
  );

  const monthlyStatisticsList = [
    {
      header: "Total Sales",
      content: currentMonthSales,
      percentContent: monthlySalesIncrementPercent,
      subContent: "Monthly Sales Count",
      classSuffix: "totalSales",
    },
    {
      header: "Total Revenue",
      content: displayCurrencyAmount(monthlyRevenueStats?.currentMonthRevenue),
      percentContent: monthlyRevenueStats?.monthlyIncrementPercentRevenue,
      subContent: "Monthly Revenue Generated",
      classSuffix: "totalRevenue",
    },
    {
      header: "Total Withdraw",
      content: displayCurrencyAmount(totalWithdrawnAmount),
      subContent: "Monthly Amount Wthdrawn",
      classSuffix: "totalWithdraw",
    },
  ];

  const displayMonthlyAnalyticsSection = ({
    header,
    content,
    percentContent,
    subContent,
    classSuffix,
  }) => (
    <>
      <p className="vendorWalletDashboard__monthlySummary-gridItem-header">
        {header}
      </p>
      <div
        className={`vendorWalletDashboard__monthlySummary-gridItem-content-${classSuffix}`}
      >
        <p className="vendorWalletDashboard__monthlySummary-gridItem-content--left">
          {content}
        </p>
        {percentContent && (
          <p className="vendorWalletDashboard__monthlySummary-gridItem-content--right">
            <IconDisplay
              type={percentageGrowthType(percentContent)}
              className={`analytics__card--right-${percentageGrowthType(22.5)}`}
            />
            {percentContent.toFixed(0)}%
          </p>
        )}
      </div>
      <p className="vendorWalletDashboard__monthlySummary-gridItem-footer">
        {subContent}
      </p>
    </>
  );

  return (
    <div className="vendorWalletDashboard__monthlySummary">
      <p className="vendorWalletDashboard__monthlySummary-header">
        <IconDisplay
          type="calendar"
          className="vendorWalletDashboard__monthlySummary-header-calendar"
        />
        Monthly Summary
      </p>
      <Grid
        container
        spacing={0}
        className="vendorWalletDashboard__monthlySummary-content"
      >
        {monthlyRevenueStats &&
          monthlyStatisticsList?.map((statistic, index) => (
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className="vendorWalletDashboard__monthlySummary-gridItem">
                {displayMonthlyAnalyticsSection(statistic)}
              </div>
              {index !== monthlyStatisticsList?.length - 1 && (
                <div className="verticalDivider" />
              )}
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default VendorMonthlySummary;
